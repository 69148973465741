import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import hash from 'object-hash';
import _, { isNull } from 'lodash';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getFilters, normalizeFilters } from '@/Utils/filters';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { getMidtierTable, getMidtierTableCount } from '@/services/api';
import { actions as searchActions } from '@/slices/search';
import { actions as tableActions } from '@/slices/table';
import { Filters } from '@/slices/filters';
import { FIXED_ELASTIC_SEARCH_OUTPUT } from '@/constants';
import { TError } from '@/types';

type TFetchParams = {
  fetchCount: boolean;
};

const getPageFrom = (pageSize: number, pageNumber: number) => (pageNumber === 0 ? 0 : pageSize * pageNumber + 1);

export const useQueryTable = (isCompaniesList = false) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const pageNumber = useShallowSelector((state) => state.table.pageNumber);
  const pageSize = useShallowSelector((state) => state.table.pageSize);
  const sortModel = useShallowSelector((state) => state.table.sortModel);
  const isMidtierEnabled = useShallowSelector((state) => state.table.isMidtierEnabled);
  const isMidtierCountEnabled = useShallowSelector((state) => state.table.isMidtierCountEnabled);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);
  const filters = useShallowSelector((state) => getFilters(state.filters)) as Filters;
  const bainIds = useShallowSelector((state) => state.search.bainIds.data);
  const totalBainIds = useShallowSelector((state) => state.search.bainIds.total);
  const sortKey = sortModel?.[0]?.field ?? null;
  const sortOrder = sortModel?.[0]?.sort ?? null;
  const isCompaniesListAndBainIdsEmpty = isCompaniesList && _.isEmpty(bainIds);

  const handle504Error = () => {
    enqueueSnackbar('Service Unavailable. We apologize for the inconvenience. Please try again later.', {
      variant: 'error',
      preventDuplicate: true,
    });
    dispatch(searchActions.setTotalBainIds(0));
  };

  const body = {
    sql_query: true,
    page_from: getPageFrom(pageSize, pageNumber),
    page_size: pageSize,
    ...normalizeFilters(filters),
    ...(!_.isNil(sortKey) && { sort_key: sortKey }),
    ...(!_.isNil(sortOrder) && { sort_order: sortOrder }),
    ...(!_.isEmpty(bainIds) && { self_ids_id__cdp_: bainIds }),
    ...(!_.isEmpty(includedNullList) && { include_null_list: includedNullList }),
  };

  const hashedBody = hash({ currency, body });

  const tableMidtierQuery = useQuery({
    enabled: isMidtierEnabled && isAuth,
    keepPreviousData: true,
    queryKey: ['table-midtier', hashedBody],
    queryFn: () => {
      dispatch(searchActions.setIsLoading(true));

      return getMidtierTable({ currency, body });
    },
    retry: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    select: (data) => {
      if (isMidtierEnabled && isAuth) {
        dispatch(tableActions.setEnableMidtierQuery(false));
      }

      return isCompaniesListAndBainIdsEmpty ? [] : data;
    },
    onSuccess() {
      if (!isMidtierCountEnabled) {
        dispatch(searchActions.setIsLoading(false));
      }
    },
    onError: (error: TError) => {
      dispatch(searchActions.setIsLoading(false));

      if (error?.request?.status === 504) {
        handle504Error();

        return;
      }

      enqueueSnackbar(<NotificationMessage title="Failed to fetch data. Please try again or contact support." />, {
        variant: 'error',
      });
    },
  });

  const tableMidtierCountQuery = useQuery({
    enabled: isMidtierCountEnabled && isAuth,
    keepPreviousData: true,
    queryKey: ['table-midtier-count', hashedBody, { get_count: true }],
    queryFn: () => {
      dispatch(searchActions.setIsLoading(true));

      return getMidtierTableCount({ currency, body: { ...body, get_count: true } });
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
    select: (data) => {
      if (isMidtierCountEnabled && isAuth) {
        dispatch(tableActions.setEnableMidtierCountQuery(false));
      }

      return isCompaniesListAndBainIdsEmpty ? 0 : data;
    },
    onSuccess() {
      if (!isMidtierEnabled) {
        dispatch(searchActions.setIsLoading(false));
      }
    },
    onError: (error: TError) => {
      dispatch(searchActions.setIsLoading(false));

      if (error?.request?.status === 504) {
        handle504Error();

        return;
      }

      enqueueSnackbar(
        <NotificationMessage title="Failed to fetch total record count. Please try again or contact support." />,
        { variant: 'error' },
      );
    },
  });

  const fetch = useCallback(
    ({ fetchCount }: TFetchParams = { fetchCount: false }) => {
      dispatch(tableActions.setEnableMidtierQuery(true));

      if (fetchCount && (isNull(totalBainIds) || totalBainIds < FIXED_ELASTIC_SEARCH_OUTPUT)) {
        dispatch(tableActions.setEnableMidtierCountQuery(true));
      }
    },
    [dispatch, totalBainIds],
  );

  const refetch = useCallback(() => {
    dispatch(tableActions.setEnableMidtierQuery(true));
    dispatch(tableActions.setEnableMidtierCountQuery(true));
  }, [dispatch]);

  return useMemo(
    () => ({
      tableMidtierQuery,
      tableMidtierCountQuery,
      isLoading: tableMidtierQuery.isFetching || tableMidtierCountQuery.isFetching,
      fetch,
      refetch,
    }),
    [fetch, refetch, tableMidtierCountQuery, tableMidtierQuery],
  );
};
