import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';

const KEYWORD_ROLE = 'keyword';
const KEYCHAR_ROLE = 'keychar';

const KEYWORDS = {
  AND: 'AND',
  OR: 'OR',
  NOT: 'NOT',
};

const KEYCHARS = {
  BRACKETS: '( )',
  QUESTION_MARK: '?',
  ASTERISK: '*',
  TILDE: '~',
};

const LIST = [
  {
    key: KEYWORDS.AND,
    tooltip: 'Combine multiple keywords with AND',
    role: KEYWORD_ROLE,
  },
  {
    key: KEYWORDS.OR,
    tooltip: 'Search for differing keywords with OR',
    role: KEYWORD_ROLE,
  },
  {
    key: KEYWORDS.NOT,
    tooltip: 'Exclude keywords with NOT',
    role: KEYWORD_ROLE,
  },
  {
    key: KEYCHARS.BRACKETS,
    tooltip: 'Group keywords & operators with brackets',
    role: KEYCHAR_ROLE,
  },
  {
    key: KEYCHARS.QUESTION_MARK,
    tooltip: 'Switch out a single letter with ?',
    trimmed: true,
    role: KEYCHAR_ROLE,
  },
  {
    key: KEYCHARS.ASTERISK,
    tooltip: 'Try wildcard searches with *',
    trimmed: true,
    role: KEYCHAR_ROLE,
  },
  {
    key: KEYCHARS.TILDE,
    tooltip: 'Search for keyword near matches with ~',
    trimmed: true,
    role: KEYCHAR_ROLE,
  },
];

export const Keywords = ({ editor }) => {
  const handleClick = (key, trimmed) => {
    if (key === KEYCHARS.BRACKETS) {
      editor.insertText(' () ');
      Transforms.move(editor, { distance: 2, reverse: true });
    } else {
      editor.insertText(trimmed ? key : ` ${key} `);
    }

    ReactEditor.focus(editor);
  };

  return (
    <div className="mt-4 flex gap-1">
      {LIST.map(({ key, tooltip, trimmed, role }) => {
        const tooltipContent = (
          <Typography
            variant="body1"
            className="px-1 py-0.5"
          >
            {tooltip}
          </Typography>
        );

        return (
          <Tooltip
            key={key}
            title={tooltipContent}
            placement="top"
            enterDelay={500}
            enterNextDelay={500}
          >
            <span
              role="button"
              onClick={() => handleClick(key, trimmed)}
              className={classnames(
                'w-8 h-4 text-cgray-150 inline-block rounded text-center text-[10px] cursor-pointer',
                {
                  'bg-[#eae6ff]': role === KEYWORD_ROLE,
                  'bg-[#e7cede]': role === KEYCHAR_ROLE,
                },
              )}
            >
              {key}
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
};

Keywords.propTypes = {
  editor: PropTypes.any.isRequired,
};
