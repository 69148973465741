import React from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const CardIconList = () => (
  <div className="w-7 h-7 bg-[#DCE2E8] rounded-full mr-3 opacity-50 flex items-center justify-center">
    <FormatListBulletedIcon
      fontSize="medium"
      className="fill-[#231F20] p-[4px]"
    />
  </div>
);
