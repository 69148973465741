import { rest } from 'msw';
import { times } from 'lodash';

export const companySearchController = [
  rest.post(`${process.env.REACT_APP_API_URL}/midtier/company_search`, async (req, res, ctx) => {
    const requestBody = await req.json();
    const companySearch = requestBody?.company_search;

    return res(
      ctx.json({
        self_firmo_name___: times(3, (index) => `${companySearch}${index + 1}`),
      }),
    );
  }),
];
