import React, { FC, useMemo } from 'react';

import { SkeletonLoader } from '../../common/SkeletonLoader';
import { GenericTable } from './GenericTable';
import { useQueryCompanyProfileDataset } from '@/hooks/queries/company-profile/use-query-company-profile-dataset';
import { ucFirstLetter } from '@/Utils/text';
import { useQueryColumnMapperDataset } from '@/hooks/queries/column-mapper/use-query-column-mapper-dataset';
import { getColumns } from '@/Components/Shared/ScreenerTable/columns';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getRows } from '@/Utils/table';

interface GenericTableTabProps {
  bainId: string;
  dataset: string;
}

export const GenericTableTab: FC<GenericTableTabProps> = ({ bainId, dataset }) => {
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const { data: rawData, isLoading: isDataLoading } = useQueryCompanyProfileDataset({ bainId, dataset });
  const { data: datasetColumnMapper, isLoading: isDatasetColumnMapperLoading } = useQueryColumnMapperDataset(dataset);

  const isLoading = isDataLoading || isDatasetColumnMapperLoading;

  const data = rawData?.map((value, index) => ({ id: index, ...value }));

  const tableRows = useMemo(() => data ?? [], [data]);
  const columnMapper = useMemo(() => datasetColumnMapper ?? [], [datasetColumnMapper]);

  const columns = useMemo(
    () =>
      getColumns({
        tableRows,
        columnMapper,
        currency,
        defaultColumns: columnMapper.filter((column) => column['DEFAULT_COLUMN']).map((column) => column['Backend Name']),
      }),
    [tableRows, columnMapper, currency],
  );

  const rows = useMemo(() => getRows({ rows: tableRows, isLoading }), [tableRows, isLoading]);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (!data || !datasetColumnMapper) {
    return <div>No data</div>;
  }

  if (!bainId || !dataset) {
    return null;
  }

  return (
    <GenericTable
      tableName={ucFirstLetter(dataset.replaceAll('_', ' '))}
      columns={columns}
      rows={rows}
    />
  );
};
