import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const DEFAULT_CARDS_PER_PAGE = 3;

export const LIST_TYPE = {
  allSavedSearches: 'allSavedSearches',
  userCompaniesLists: 'userCompaniesLists',
  sharedWithUserCompaniesLists: 'sharedWithUserCompaniesLists',
} as const;

type Keys = keyof typeof LIST_TYPE;
export type ListType = typeof LIST_TYPE[Keys];

export interface SearchListsTableSettings {
  cardsPerPage: number;
}

type SearchListsTablesInitialState = Record<Keys, SearchListsTableSettings>;

const initialState: SearchListsTablesInitialState = {
  allSavedSearches: {
    cardsPerPage: DEFAULT_CARDS_PER_PAGE,
  },
  userCompaniesLists: {
    cardsPerPage: DEFAULT_CARDS_PER_PAGE,
  },
  sharedWithUserCompaniesLists: {
    cardsPerPage: DEFAULT_CARDS_PER_PAGE,
  },
};

export const { reducer, actions } = createSlice({
  name: 'searchListsTables',
  initialState,
  reducers: {
    setCardsPerPage: (state, { payload }: PayloadAction<{ table: Keys; value: number }>) => {
      state[payload.table].cardsPerPage = payload.value;
    },
  },
});

export type TState = typeof initialState;
