import React from 'react';
import Button from '@mui/material/Button';
import { Download } from '@mui/icons-material';
import classNames from 'classnames';

type Props = {
  id: string;
  companiesSelected: boolean;
};

export const ExportButton = ({ id = 'export-button', companiesSelected, ...rest }: Props) => (
  <Button
    id={id}
    className={classNames('ml-[1px] py-2 px-2 pr-3 border border-solid font-bold font-graphik text-white text-xs', {
      'border-cred-900 bg-cred-900': !companiesSelected,
      'bg-bluegray-900 text-white border-bluegray-900 rounded-l-none': companiesSelected,
    })}
    {...rest}
  >
    <Download className="text-[16px] mr-2 mt-0.5" />
    Export
  </Button>
);
