import React from 'react';

import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getCurrencyFormat } from '../../../../Shared/common';
import { GenericTable } from '../GenericTableTab/GenericTable';
import { DATA_TYPES, DataTypes } from './MultiyearFinTab';
import { DataSource } from './use-data-source';
import { Currency, IAnnualBS, IAnnualIS, IAnnualOTHER, IQuarterlyBS, IQuarterlyIS, IQuarterlyOTHER } from '@/types';

const formatTableTitle = (title: string) => {
  switch (title) {
    case DATA_TYPES.ANNUAL_BS:
      return 'Annual Balance Sheet';
    case DATA_TYPES.ANNUAL_IS:
      return 'Annual Income Statement';
    case DATA_TYPES.QUARTERLY_BS:
      return 'Quarterly Balance Sheet';
    case DATA_TYPES.QUARTERLY_IS:
      return 'Quarterly Income Statement';

    default:
      return 'Other';
  }
};

const getColumnWidths = (field: string) => {
  if (field === 'metric') {
    return { flex: 1, minWidth: 200 };
  }

  if (field === 'units') {
    return { flex: 0.4, minWidth: 80 };
  }

  return { flex: 0.5, minWidth: 100 };
};

interface MultiyearFinTableProps {
  tableName: DataTypes;
  tableData: IAnnualBS | IAnnualIS | IAnnualOTHER | IQuarterlyIS | IQuarterlyBS | IQuarterlyOTHER;
  currentDataSource: DataSource | null;
  currency: string;
}

export const MultiyearFinTable = ({ tableName, tableData, currentDataSource, currency }: MultiyearFinTableProps) => {
  const columns: GridColumns = [];
  const rows: {
    [key: string]: any;
  }[] = [];

  Object.entries(tableData).forEach((dataPoint) => {
    const field = dataPoint[0];

    if (field != 'statement') {
      let headerName = dataPoint[0].toUpperCase();

      if (headerName[4] == 'Q') {
        headerName = headerName.substring(0, 4) + ' ' + headerName.substring(4, headerName.length);
      }

      const column = {
        field: field,
        headerName,
        ...getColumnWidths(field),
        hideable: field == 'data_source',
        pinnable: field == 'metric' || field == 'units' || field == 'data_source',
        hide: field == 'data_source',
        renderCell: (params: GridRenderCellParams) => {
          const isTitle = typeof params['value'] === 'string';
          const isCurrency = ['USD', 'EUR'].includes(params.row.units);

          const classes = 'truncate pl-[6px]';

          if (isTitle) {
            return <span className={classes}>{params['value']}</span>;
          }

          if (isCurrency) {
            return <span className={classes}>{getCurrencyFormat(params['value'], currency as Currency)}</span>;
          }

          return <span className={classes}>{params['value']}</span>;
        },
      };

      columns.push(column);

      const rowVals = Object.values(dataPoint[1]);
      let row = {};
      let x = 0;
      const newKey = [dataPoint[0]];

      rowVals.forEach((rv) => {
        if (rows[x] && 'id' in rows[x] && rows[x].id == x + 1) {
          //@ts-ignore
          rows[x][newKey] = rv;
          x++;
        } else {
          x++;
          row = {
            id: x,
            //@ts-ignore
            [newKey]: rv,
          };
          rows.push(row);
        }
      });
    }
  });

  columns.sort(function (a, b) {
    const compareVal1 = a['field'];
    const compareVal2 = b['field'];
    const firstColumns = ['metric', 'units', 'data_source'];
    const compareVal1Index = firstColumns.indexOf(compareVal1);
    const compareVal2Index = firstColumns.indexOf(compareVal2);

    if (compareVal1Index !== -1 && compareVal2Index !== -1) {
      // both string columns, sort base on position
      return compareVal1Index - compareVal2Index;
    } else if (compareVal1Index === -1 && compareVal2Index === -1) {
      // both year columns, lexographic ordering
      return compareVal1 < compareVal2 ? -1 : 1;
    } else {
      // one is year column, one is text column
      if (compareVal1Index === -1) return 1;
      else return -1;
    }
  });

  return (
    <GenericTable
      tableName={formatTableTitle(tableName)}
      columns={columns}
      rows={rows}
      filterModel={{
        items: [
          {
            columnField: 'data_source',
            operatorValue: 'equals',
            value: currentDataSource,
            id: 'dropdown-filter',
          },
        ],
      }}
      pinnedColumns={{ left: ['metric', 'units', 'data_source'] }}
    />
  );
};
