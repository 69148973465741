import { rest } from 'msw';
import { isEqual } from 'lodash';

export const FIRST_COMPANY_BAIN_ID = '1';
export const SECOND_COMPANY_BAIN_ID = '2';

const correctRequestBodyOverviewExport = {
  target_id: FIRST_COMPANY_BAIN_ID,
  competitor_ids: [FIRST_COMPANY_BAIN_ID],
  currency: 'USD',
  email: 'test@test.com',
  case_data: { case_code: 'test' },
};

const correctRequestBodyComparisonExport = {
  ...correctRequestBodyOverviewExport,
  competitor_ids: [FIRST_COMPANY_BAIN_ID, SECOND_COMPANY_BAIN_ID],
};

export const companyOverviewExportController = [
  rest.post(`${process.env.REACT_APP_API_URL}/company_overview_slides`, async (req, res, ctx) => {
    const requestBody = await req.json();

    if (
      isEqual(correctRequestBodyOverviewExport, requestBody) ||
      isEqual(correctRequestBodyComparisonExport, requestBody)
    ) {
      return res(ctx.json({ success: true }));
    }

    return res(ctx.status(500), ctx.json({ message: 'error' }));
  }),
];
