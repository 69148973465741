import { rest } from 'msw';
import { getUuidFromStringEnd } from '@/Utils/url-utils';

export const MOCKED_COMPANIES_LIST_TITLE = 'Test';
export const MOCKED_COMPANIES_LIST_PINNED_COMPANIES = ['23617408'];
export const MOCKED_AVAILABLE_COMPANIES_LIST_ID = 'available-test-id-123e4567-e89b-12d3-a456-426614174000';
export const MOCKED_NOT_AVAILABLE_COMPANIES_LIST_ID = 'not-available-test-id-839617d5-be13-4f09-96be-de6d4a6da802';

export const companiesListMock = {
  list_id: getUuidFromStringEnd(MOCKED_AVAILABLE_COMPANIES_LIST_ID),
  list_type: 'static',
  title: MOCKED_COMPANIES_LIST_TITLE,
  description: 'test description',
  currency: 'USD',
  company_search: '',
  lucene_query: true,
  filters: {
    treeFilters: {},
    includedNullList: [],
    otherFilters: {},
  },
  pinned_company_ids: MOCKED_COMPANIES_LIST_PINNED_COMPANIES,
  excluded_company_ids: [],
  sort_key: null,
  sort_order: null,
  creator: '040f572c-af65-448a-b39e-85787a9d8643',
  is_sharable_link: false,
  created_at: '2023-03-15T09:12:49.596917',
  updated_at: '2023-03-15T09:12:49.596932',
};

const companiesListsDataMock = {
  search_lists: [companiesListMock],
};

export const companiesListsController = [
  rest.get(`${process.env.REACT_APP_API_URL}/search_lists`, (_, res, ctx) => res(ctx.json(companiesListsDataMock))),

  rest.get(`${process.env.REACT_APP_API_URL}/search_list/:id`, (req, res, ctx) => {
    if (req.params.id === getUuidFromStringEnd(MOCKED_NOT_AVAILABLE_COMPANIES_LIST_ID)) {
      return res(
        ctx.status(404),
        ctx.json({
          message: `Not found SearchList with id: ${MOCKED_NOT_AVAILABLE_COMPANIES_LIST_ID}`,
        }),
      );
    }

    return res(ctx.json({ ...companiesListMock, list_id: req.params.id }));
  }),

  rest.patch(`${process.env.REACT_APP_API_URL}/search_list/:id`, async (req, res, ctx) => {
    const requestBody = await req.json();

    return res(ctx.json({ companiesListMock, ...requestBody }));
  }),

  rest.post(`${process.env.REACT_APP_API_URL}/search_list`, async (req, res, ctx) => {
    const requestBody = await req.json();

    return res(ctx.json({ companiesListMock, ...requestBody }));
  }),
];

export const companiesListsErrorsController = [
  rest.get(`${process.env.REACT_APP_API_URL}/search_lists`, (_, res, ctx) =>
    res(ctx.status(500), ctx.json({ message: 'error' })),
  ),
];
