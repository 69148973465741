import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { isNil, isEmpty, isUndefined } from 'lodash';

import { useShallowSelector } from '../../../../../../hooks/use-shallow-selector';
import { actions } from '../../../../../../slices/filters';
import { NUMERIC_FILTER_TYPES } from '@/constants';
import './index.scss';

const MIN = 'min';
const MAX = 'max';
const MIN_UI_DATA = 'min_ui_data';
const MAX_UI_DATA = 'max_ui_data';

const prepareValue = (value) => {
  if (value === '-') return '-';

  return isEmpty(value) ? undefined : Number(value.replace(/,/g, ''));
};

const getOtherMinMaxType = (type) => (type === MIN_UI_DATA ? MAX_UI_DATA : MIN_UI_DATA);
const getUiMinMaxType = (type) => `${type}_ui_data`;

function getInputsConfig(numericFilterType) {
  switch (numericFilterType) {
    case NUMERIC_FILTER_TYPES.YEARS:
      return { min: { affix: 'From ' }, max: { affix: 'To ' }, prefix: true };
    case NUMERIC_FILTER_TYPES.PERCENTAGE:
      return { min: { affix: '%' }, max: { affix: '%' }, prefix: false };
    case NUMERIC_FILTER_TYPES.EUR:
      return { min: { affix: '€' }, max: { affix: '€' }, prefix: true };
    case NUMERIC_FILTER_TYPES.USD:
      return { min: { affix: '$' }, max: { affix: '$' }, prefix: true };
    case NUMERIC_FILTER_TYPES.OTHER:
      return { min: { affix: 'Min ' }, max: { affix: 'Max ' }, prefix: true };
    default:
      return { min: { affix: 'Min ' }, max: { affix: 'Max ' }, prefix: true };
  }
}

const FilterNumericalInput = ({ id, placeholder, type, value, handleChange, handleBlur, handleFocus, dataTestId }) => {
  const applyThousandSeparator = type !== NUMERIC_FILTER_TYPES.YEARS;

  const formattedValue = useMemo(() => {
    if (isNil(value)) return '';
    if (value === '-') return '-';

    return applyThousandSeparator ? value.toLocaleString('en') : String(value);
  }, [applyThousandSeparator, value]);

  return (
    <TextField
      type="text"
      className="filter-numerical-input"
      variant="outlined"
      size="small"
      fullWidth
      key={id}
      id={id}
      name={id}
      value={formattedValue}
      InputProps={{
        classes: {
          root: 'text-black text-base',
        },
      }}
      label={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      autoComplete="off"
      data-testid={dataTestId}
    />
  );
};

export const FilterNumerical = ({ values: { min_hint, max_hint }, type, itemId }) => {
  const dispatch = useDispatch();
  const [isMinFocused, setIsMinFocused] = useState(false);
  const [isMaxFocused, setIsMaxFocused] = useState(false);
  const filterData = useShallowSelector((state) => state.filters.otherFilters[itemId]);
  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);
  const isChecked = !includedNullList.includes(itemId) || filterData?.excludeNullValues;
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const updatedType = type === NUMERIC_FILTER_TYPES.CURRENCY ? currency : type;
  const inputConfig = getInputsConfig(updatedType);

  useEffect(() => {
    if (isChecked) {
      dispatch(actions.removeIdFromNullList(itemId));
    } else {
      dispatch(actions.addIdToNullList(itemId));
    }
  }, [dispatch, isChecked, itemId]);

  const handleChange = ({ target: { value } }, minMaxType) => {
    const formattedValue = prepareValue(value);

    if (isNil(formattedValue) || !isNaN(formattedValue) || formattedValue === '-') {
      dispatch(
        actions.setFilter({
          id: itemId,
          data: {
            ...filterData,
            [minMaxType]: formattedValue,
            [getUiMinMaxType(minMaxType)]: formattedValue,
          },
        }),
      );
    }

    if (isNil(formattedValue) && isNil(filterData[getOtherMinMaxType(getUiMinMaxType(minMaxType))]) && !isChecked) {
      dispatch(actions.removeFilter(itemId));
    }

    if (isNil(formattedValue) && isNil(filterData[getOtherMinMaxType(getUiMinMaxType(minMaxType))]) && isChecked) {
      dispatch(
        actions.setFilter({
          id: itemId,
          data: {
            min: null,
            max: null,
            excludeNullValues: isChecked,
          },
        }),
      );
    }
  };

  const handleChangeNull = ({ target: { checked } }) => {
    if (checked) {
      dispatch(actions.removeIdFromNullList(itemId));
    } else {
      dispatch(actions.addIdToNullList(itemId));
    }

    dispatch(
      actions.setFilter({
        id: itemId,
        data:
          !isNil(filterData?.[MIN_UI_DATA]) || !isNil(filterData?.[MAX_UI_DATA])
            ? { ...filterData, excludeNullValues: checked }
            : { min: null, max: null, excludeNullValues: checked },
      }),
    );

    if (!checked && isNil(filterData?.[MIN_UI_DATA]) && isNil(filterData?.[MAX_UI_DATA])) {
      dispatch(actions.removeFilter(itemId));
    }
  };

  const handleBlur = (minMaxType) => {
    if (filterData?.[getUiMinMaxType(minMaxType)] === '-') {
      dispatch(
        actions.setFilter({
          id: itemId,
          data: {
            ...filterData,
            [minMaxType]: 0,
            [getUiMinMaxType(minMaxType)]: 0,
          },
        }),
      );
    }

    if (minMaxType === MIN) {
      setIsMinFocused(false);
    } else {
      setIsMaxFocused(false);
    }
  };

  const minPlaceholder = useMemo(() => {
    if (!min_hint || !isUndefined(filterData?.[MIN_UI_DATA]) || isMinFocused) {
      return inputConfig.min.affix;
    }

    return inputConfig.prefix ? `${inputConfig.min.affix}${min_hint}` : `${min_hint}${inputConfig.min.affix}`;
  }, [filterData, inputConfig.min.affix, inputConfig.prefix, isMinFocused, min_hint]);

  const maxPlaceholder = useMemo(() => {
    if (!max_hint || !isUndefined(filterData?.[MAX_UI_DATA]) || isMaxFocused) {
      return inputConfig.max.affix;
    }

    return inputConfig.prefix ? `${inputConfig.max.affix}${max_hint}` : `${max_hint}${inputConfig.max.affix}`;
  }, [filterData, inputConfig.max.affix, inputConfig.prefix, isMaxFocused, max_hint]);

  return (
    <>
      <div className="flex justify-between gap-4 mt-4 mb-2">
        <FilterNumericalInput
          id={`${itemId}-min`}
          placeholder={minPlaceholder}
          type={updatedType}
          value={filterData?.[MIN_UI_DATA]}
          handleChange={(event) => handleChange(event, MIN)}
          handleBlur={() => handleBlur(MIN)}
          handleFocus={() => setIsMinFocused(true)}
          dataTestId={`${itemId}-min`}
        />
        <FilterNumericalInput
          id={`${itemId}-max`}
          placeholder={maxPlaceholder}
          type={updatedType}
          value={filterData?.[MAX_UI_DATA]}
          handleChange={(event) => handleChange(event, MAX)}
          handleBlur={() => handleBlur(MAX)}
          handleFocus={() => setIsMaxFocused(true)}
          dataTestId={`${itemId}-max`}
        />
      </div>
      <FormControlLabel
        id={`${itemId}-nullable`}
        label="Exclude missing values?"
        classes={{
          root: 'text-[#484848]',
        }}
        control={
          <Switch
            classes={{
              root: 'h-[44px] w-[60px] rounded-[10px]',
              track: 'rounded-[10px] opacity-20',
              thumb: 'border border-solid	border-[#DDDDDD] h-[26px] w-[26px]',
            }}
            checked={isChecked}
            onChange={handleChangeNull}
          />
        }
      />
    </>
  );
};
