import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import {
  getLastNSearches,
  getLatestQuery,
  getQueryEntity,
  isHistoryVersionIsObsolete,
  prepareSearchHistory,
  removeTooOldSearchHistoryQueries,
  shouldSaveNewQuery,
  updateHistoryFormat,
} from '@/Utils/searchQueriesHistoryUtils';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { HISTORY_KEYS, LOCAL_STORAGE_KEYS } from '@/constants';
import { HistoryQuery, QueryDetails } from '@/types/history';

const NUMBER_OF_DISPLAYED_SEARCHES = 5;

export type SearchHistory = {
  [key: string]: HistoryQuery[];
};

export const useSearchHistory = () => {
  const key = useShallowSelector((state) => state.search.historyKey);
  const [history, setHistory] = useLocalStorage<SearchHistory>(LOCAL_STORAGE_KEYS.QUERIES_HISTORY, {
    [HISTORY_KEYS.CUSTOM_HISTORY]: [],
    [HISTORY_KEYS.SCREENERS_HISTORY]: [],
  });

  // This effect makes sure that after history format changes, users won't need to clear their storages
  // updateHistoryFormat method should do it for them. Make sure that this method is valid after
  // each change in the structure
  useEffect(() => {
    if (isHistoryVersionIsObsolete(history)) {
      setHistory((prevHistory) => updateHistoryFormat(prevHistory as any));
    }
  }, []);

  const saveQuery = (queryDetails: QueryDetails) => {
    setHistory((prevHistory: SearchHistory) => {
      const prevQueries = prevHistory?.[key];
      const latestQuery = getLatestQuery(prevQueries);
      const newQuery = getQueryEntity({
        ...queryDetails,
        filters: queryDetails.isExport ? queryDetails.filters : undefined,
      });

      return {
        ...prevHistory,
        [key]: shouldSaveNewQuery(latestQuery, newQuery) ? [...prevQueries, newQuery] : [...prevQueries],
      };
    });
  };

  const cleanOldHistory = () => {
    setHistory((prevHistory) => {
      const prevQueries = prevHistory?.[key] ?? [];
      const newQueries = removeTooOldSearchHistoryQueries(prevQueries);

      return {
        ...prevHistory,
        [key]: newQueries.length !== prevQueries.length ? newQueries : prevQueries,
      };
    });
  };

  const getLastNSearchQueries = (n = NUMBER_OF_DISPLAYED_SEARCHES) => getLastNSearches(history?.[key] ?? [], n);

  const getGroupedSearchHistory = () => prepareSearchHistory(history?.[key] ?? []);

  return {
    cleanOldHistory,
    saveQuery,
    getLastNSearchQueries,
    getGroupedSearchHistory,
    history,
  };
};
