import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions as searchActions } from '../../slices/search';
import SearchPage from '../../pages/search-page';
import { useScreener } from '@/hooks/use-screen';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { HISTORY_KEYS } from '@/constants';
import { SaveSearchButton } from '@/Components/Shared/SavedSearch/SaveSearchButton';

import '../../App.css';

export const CompanyResearch = () => {
  const dispatch = useDispatch();
  const { softReset } = useScreener();
  const bainIds = useShallowSelector((state) => state.search.bainIds.data);

  useEffect(() => {
    dispatch(searchActions.setHistoryKey(HISTORY_KEYS.CUSTOM_HISTORY));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      softReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchPage
      bainIds={bainIds}
      controls={
        <div className="flex items-center gap-5">
          <span className="text-2xl text-[#666666] py-[2px] font-semibold">Company search</span>
          <SaveSearchButton />
        </div>
      }
    />
  );
};
