import React from 'react';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { dataNotAvailable, numberFormatterCustom } from '../../../../Shared/common';
import { GenericTable } from '../GenericTableTab/GenericTable';
import { ITransaction } from '@/types';

const formatNumber = (val?: number) => {
  if (!val) {
    return dataNotAvailable();
  } else if (isNaN(val)) {
    return val;
  } else {
    return numberFormatterCustom(val);
  }
};

const formatHeader = (value: string) => {
  return value
    .replace(/_/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((string) => string.charAt(0).toUpperCase() + string.substring(1))
    .join(' ');
};

interface MnATableProps {
  tableData: ITransaction[];
  title: string;
  columnsList: string[];
}

const MnATable = ({ tableData, title, columnsList }: MnATableProps) => {
  const columns: GridColumns = [];
  const rows: {
    [key: string]: any;
  }[] = [];

  const data = tableData[0] ?? [];

  Object.keys(data).forEach((columnName) => {
    if (columnsList.includes(columnName)) {
      const column = {
        field: columnName,
        headerName: formatHeader(columnName),
        width: 200,
        renderCell: (callParams: GridRenderCellParams) => {
          return (
            <span className="truncate">
              {callParams['value'] ? formatNumber(callParams['value']) : dataNotAvailable()}
            </span>
          );
        },
      };

      if (!columns.includes(column)) {
        columns.push(column);
      }
    }
  });

  tableData.forEach((rowData, index) => {
    //@ts-ignore
    if (rowData['bain_id_buyer'] !== '') {
      if (!rows.includes(rowData)) {
        rows.push({
          id: index + 1,
          ...rowData,
        });
      }
    }
  });

  return (
    <GenericTable
      tableName={title}
      columns={columns}
      rows={rows}
    />
  );
};

export default MnATable;
