import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IColumnMapper } from '@/types';

const initialState = {
  currency: 'USD',
  columnMapper: [] as IColumnMapper[],
};

export const { reducer, actions } = createSlice({
  name: 'customScreen',
  initialState,
  reducers: {
    setCurrency: (state, { payload }: PayloadAction<string>) => {
      state.currency = payload;
    },
    setColumnMapper: (state, { payload }: PayloadAction<IColumnMapper[]>) => {
      state.columnMapper = payload;
    },
  },
});

export type TState = typeof initialState;
