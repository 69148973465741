import React, { useState, useMemo } from 'react';

import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import { Row } from './Row';
import { MODALS } from '@/constants';
import { useModal } from '@/hooks/use-modal';
import { useQueryCompaniesListRevisions } from '@/hooks/queries/companies-list/use-query-companies-list-revisions';

export interface CompaniesListHistoryDialogModalData {
  listId?: string;
  revision?: number;
  listIdWithTitle?: string;
  is_sharable_link?: boolean;
}

export const CompaniesListHistoryDialog = () => {
  const {
    isOpen,
    handleClose,
    data: modalData,
  } = useModal<CompaniesListHistoryDialogModalData>(MODALS.COMPANIES_LIST_HISTORY);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const { data: companiesListVersions, isFetching } = useQueryCompaniesListRevisions({
    id: modalData?.listId,
    enable: isOpen,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () => companiesListVersions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [companiesListVersions, page, rowsPerPage],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ paper: 'w-3/5 rounded-lg p-4 mt-[250px] self-start max-h-[calc(100%-250px)]' }}
    >
      <div className="flex justify-between items-center pb-4">
        <DialogTitle className="flex text-base font-bold p-0 text-[#484848]">Companies list history</DialogTitle>

        <CloseIcon
          fontSize="small"
          className="cursor-pointer fill-[#484848]"
          onClick={handleClose}
        />
      </div>
      <div>
        {!isFetching && !companiesListVersions ? <div>Something went wrong...</div> : null}
        {isFetching ? (
          <div className="grid">
            <Skeleton
              variant="rounded"
              className="bg-[#0000000f] rounded-2xl"
              height={150}
            />
          </div>
        ) : (
          <>
            {modalData?.is_sharable_link && (
              <Alert severity="info">
                This is a publicly shared list, you may not have an access to older revisions.
              </Alert>
            )}
            <TableContainer>
              <Table
                aria-label="collapsible table"
                data-testid="companies-list-history-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className="font-bold">Version</TableCell>
                    <TableCell className="font-bold">Updated At</TableCell>
                    <TableCell className="font-bold">Changed By</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleRows?.map((row) => (
                    <Row
                      key={row.revision}
                      row={row}
                      isLatest={row.revision === companiesListVersions?.[0].revision}
                      currentRevision={modalData?.revision}
                      listIdWithTitle={modalData?.listIdWithTitle}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[3, 10, 25]}
              component="div"
              count={companiesListVersions?.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Show:"
            />
          </>
        )}
      </div>
    </Dialog>
  );
};
