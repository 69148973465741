import { QueryType } from '@/slices/search';

export enum ExportEnum {
  DATA = 'data',
  OVERVIEW = 'overview',
  COMPARISON = 'comparison',
}

export interface HistoryQuery {
  createTimestamp: number;
  searchText: string;
  isLucene: boolean;
  filters?: unknown;
  isUniq?: boolean;
  companiesCount?: number;
  isExport?: boolean;
  companyProfiles?: string[];
  targetComparisonCompany?: string;
  exportType?: ExportEnum;
  queryType: QueryType;
}

export type QueryDetails = Omit<HistoryQuery, 'createTimestamp'>;
