import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';

import Routes from './pages/routes';
import { NotificationCloseAction } from './Components/Shared/Notifications/NotificationCloseAction';
import { Main } from './Components/Main';
import Header from './Components/Header';
import { CaseCodeDialog } from './Components/Dialogs/CaseCodeDialog';
import { TooManyCompaniesDialog } from './Components/Dialogs/CompanyOverview/TooManyCompaniesDialog';
import { CompanyOverviewDialog } from './Components/Dialogs/CompanyOverview/CompanyOverviewDialog';
import { AdvancedSearchDialog } from './Components/Dialogs/AdvancedSearchDialog';
import { SearchCompaniesHistoryDialog } from './Components/Dialogs/SearchCompaniesHistoryDialog';
import ExportDialog from './Components/Dialogs/ExportDialog';
import { CreateCompaniesListDialog } from './Components/Dialogs/CreateCompaniesListDialog';
import { UpdateCompaniesListDialog } from './Components/Dialogs/UpdateCompaniesListDialog';
import { CreateSavedSearchDialog } from './Components/Dialogs/CreateSavedSearchDialog';
import { UpdateSavedSearchDialog } from './Components/Dialogs/UpdateSavedSearchDialog';
import { SimilarCompaniesLoadingDialog } from './Components/Dialogs/SimilarCompaniesLoadingDialog';
import { ShareListWithCollaboratorsDialog } from './Components/Dialogs/ShareListWithCollaboratorsDialog';
import { CompaniesListHistoryDialog } from './Components/Dialogs/CompaniesListHistoryDialog';
import awsService from './awsService';

import { theme } from './theme';
import { GlobalCss } from './globalStyle';
import { useQuerySecrets } from '@/hooks/queries/use-query-secrets';
import './App.css';

awsService.initializeAws();

const App = () => {
  useQuerySecrets();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={4500}
        preventDuplicate
        action={NotificationCloseAction}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Main>
          <Header />
          <Routes />

          {/** Render modals here */}
          <ExportDialog />
          <CompanyOverviewDialog />
          <TooManyCompaniesDialog />
          <CaseCodeDialog />
          <AdvancedSearchDialog />
          <SearchCompaniesHistoryDialog />
          <CreateCompaniesListDialog />
          <UpdateCompaniesListDialog />
          <CreateSavedSearchDialog />
          <UpdateSavedSearchDialog />
          <SimilarCompaniesLoadingDialog />
          <ShareListWithCollaboratorsDialog />
          <CompaniesListHistoryDialog />
          {/** Render modals here */}

          <GlobalCss />
        </Main>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
