import React, { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';

import { CtaButtons } from './cta-buttons';
import { ListSection } from './list-section';
import { useSavedSearches } from './use-saved-searches';
import { useCompaniesLists } from './use-companies-lists';
import { useModal } from '@/hooks/use-modal';
import { MODALS, LOCAL_STORAGE_KEYS } from '@/constants';
import { useScreener } from '@/hooks/use-screen';
import { CASE_CODE_INITIAL_STATE, useMutationLogCaseCode } from '@/hooks/queries/use-mutation-log-case-code';
import { TSecrets } from '@/hooks/queries/use-query-secrets';
import { LIST_TYPE } from '@/slices/search-lists-tables';

export const NO_SAVED_SEARCHES_TEXT = 'You have no searches to show, search for companies and save the search!';
export const FAIL_FETCH_SAVED_SEARCHES_TEXT = 'Failed to fetch saved searches. Please try again or contact support.';
export const NO_COMPANIES_LISTS_TEXT =
  'You have no lists to show, search for companies and add them to a list or create a new list here!';
export const FAIL_FETCH_COMPANIES_LISTS_TEXT = 'Failed to fetch companies lists. Please try again or contact support.';

const SearchLists = () => {
  const { softReset } = useScreener();
  const [redirectUrl, setRedirectUrl] = useLocalStorage(LOCAL_STORAGE_KEYS.REDIRECT_URL, '');
  const [uiSecrets] = useSessionStorage<TSecrets | undefined>(LOCAL_STORAGE_KEYS.UI_SECRETS, undefined);

  const { mutate: logCaseCode } = useMutationLogCaseCode();
  const { handleOpen: handleCaseCodeModalOpen, isOpen } = useModal(MODALS.CASE_CODE);
  const [caseCodeFormData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);
  const isCaseCodeLogged = useRef(false);

  const { allSavedSearches, userSavedSearches, isSavedSearchesFetching, isSavedSearchesError } = useSavedSearches();

  const { isCompaniesListsFetching, isCompaniesListsError, userCompaniesLists, sharedWithUserCompaniesLists } =
    useCompaniesLists();

  useEffect(() => {
    if (isOpen) {
      setRedirectUrl('');
    }
  }, [isOpen, setRedirectUrl]);

  if (!isOpen && isEmpty(caseCodeFormData?.formData)) {
    handleCaseCodeModalOpen({ redirectUrl });
  }

  useEffect(() => {
    return () => {
      softReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!caseCodeFormData?.shouldBeLogged || !uiSecrets || isCaseCodeLogged.current) return;

    isCaseCodeLogged.current = true;

    logCaseCode(caseCodeFormData, {});
  }, [caseCodeFormData, logCaseCode, uiSecrets]);

  return (
    <div className="mx-6 my-8">
      <CtaButtons />

      <ListSection
        title="Your searches"
        error={isSavedSearchesError ? FAIL_FETCH_SAVED_SEARCHES_TEXT : undefined}
        isLoading={isSavedSearchesFetching}
        emptySectionText={NO_SAVED_SEARCHES_TEXT}
        allLists={allSavedSearches}
        userLists={userSavedSearches}
        skeletonLoaderHeight={104}
        listType={LIST_TYPE.allSavedSearches}
      />

      <ListSection
        title="Your lists"
        error={isCompaniesListsError ? FAIL_FETCH_COMPANIES_LISTS_TEXT : undefined}
        isLoading={isCompaniesListsFetching}
        emptySectionText={NO_COMPANIES_LISTS_TEXT}
        allLists={userCompaniesLists}
        listType={LIST_TYPE.userCompaniesLists}
      />

      {sharedWithUserCompaniesLists.length && !isCompaniesListsFetching ? (
        <ListSection
          title="Lists shared with you"
          isLoading={isCompaniesListsFetching}
          allLists={sharedWithUserCompaniesLists}
          listType={LIST_TYPE.sharedWithUserCompaniesLists}
        />
      ) : null}
    </div>
  );
};

export default SearchLists;
