import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { createCompaniesList } from '@/services/api/companies-list';
import { MODALS } from '@/constants';
import { TSharedElementsMutationPayload } from '@/types/shared-elements';
import { createCompaniesListPath } from '@/Components/Shared/CompaniesList/ShareCompaniesListLink';

export const useMutationCreateCompaniesList = (
  resetCallback?: () => void,
  companyProfileSelectedCompanies?: string[],
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useModal(MODALS.CREATE_COMPANIES_LIST);
  const query_type = useShallowSelector((state) => state.search.queryType);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const pinned_company_ids = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);

  const body = {
    query_type,
    pinned_company_ids: companyProfileSelectedCompanies ?? pinned_company_ids,
    currency,
  };

  return useMutation({
    mutationKey: ['create-companies-list'],
    mutationFn: (payload: TSharedElementsMutationPayload) => createCompaniesList({ ...body, ...payload }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['companies-lists'] });

      resetCallback?.();
      handleClose();

      enqueueSnackbar(
        <NotificationMessage
          title={`List "${response.title}" has been added`}
          href={{
            to: createCompaniesListPath(response.title, response.list_id),
            name: 'Go to the list',
          }}
        />,
        { variant: 'success' },
      );
    },

    onError: () => {
      enqueueSnackbar(
        <NotificationMessage title="Failed to create companies list. Please try again or contact support." />,
        { variant: 'error' },
      );
    },
  });
};
