import API from '@aws-amplify/api-rest';

import { ICompaniesSubsidiaries, INews, IMultyYear, IProfile, IMergesAndAcquisitions, IMidtierTable } from '@/types';

export const getCompanyProfile = (bainId: string, currency: string): Promise<IProfile[]> =>
  API.post('CDPAPI', `/company_profile?currency=${currency}`, {
    body: { bainIds: bainId },
  }).then((response) => response.data);

export const getCompanySubsidiaries = (bainId: string, currency: string): Promise<ICompaniesSubsidiaries> =>
  API.get('CDPAPI', `/company_profile/subsidiaries/${bainId}?currency=${currency}`, {}).then(
    (response) => response.data,
  );

export const getCompanyMultiyearFinancials = (bainId: string, currency: string): Promise<IMultyYear> =>
  API.get('CDPAPI', `/company_profile/multiyear/${bainId}?currency=${currency}`, {});

export const getCompanyMergesAndAcquisitions = (bainId: string, currency: string): Promise<IMergesAndAcquisitions[]> =>
  API.get('CDPAPI', `/company_profile/ma/${bainId}?currency=${currency}`, {}).then((response) => response.data);

export const getCompanyNews = (bainId: string, currency: string): Promise<INews[]> =>
  API.get('CDPAPI', `/company_profile/news/${bainId}?currency=${currency}`, {}).then((response) => response.data);

export const getCompanyProfileDatasets = (bainId: string, currency: string): Promise<string[]> =>
  API.get('CDPAPI', `/company_profile2/${bainId}/_datasets?currency=${currency}`, {}).then(
    (response) => response.datasets,
  );

export const getCompanyProfileDataset = ({
  bainId,
  dataset,
  currency,
}: {
  bainId: string;
  dataset: string;
  currency: string;
}): Promise<IMidtierTable[]> =>
  API.get('CDPAPI', `/company_profile2/${bainId}/${dataset}?currency=${currency}`, {}).then(
    (response) => response.data,
  );
