import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';

import SearchPage from '../search-page';
import { actions as searchActions } from '@/slices/search';
import { actions as companiesListsActions } from '@/slices/navigation-tabs';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useScreener } from '@/hooks/use-screen';
import { useQueryTable } from '@/hooks/queries/use-query-table';
import { CompaniesListControls } from '@/Components/Shared/CompaniesList/CompaniesListControls';
import { useQueryCompaniesListById } from '@/hooks/queries/companies-list/use-query-companies-list-by-id';
import { ERROR_PAGE_PROPS, HISTORY_KEYS, LOCAL_STORAGE_KEYS } from '@/constants';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { ICompaniesList } from '@/types';
import { getUuidFromStringEnd } from '@/Utils/url-utils';

const CompaniesList = () => {
  const dispatch = useDispatch();
  const { id: idWithTitle, revision: revisionUrlParam } = useParams<{ id: string; revision?: string }>();
  const id = getUuidFromStringEnd(idWithTitle);
  const { softReset, cancelMidtier } = useScreener();
  const queryTable = useQueryTable(true);

  const onSuccess = (data: ICompaniesList) => {
    dispatch(searchActions.setIsLoading(false));
    dispatch(searchActions.setBainIds(data?.pinned_company_ids));
    queryTable.fetch({ fetchCount: true });
  };

  const revision = isNaN(Number(revisionUrlParam)) ? undefined : Number(revisionUrlParam);

  const query = useQueryCompaniesListById(id, true, revision, {
    onSuccess,
  });

  const { data: companiesLists } = useQueryCompaniesLists();

  const [companiesListsSharedWithUser, setCompaniesListsSharedWithUser] = useLocalStorage<ICompaniesList[]>(
    LOCAL_STORAGE_KEYS.COMPANIES_LISTS_SHARED_WITH_USER,
    [],
  );

  const bainIds = useShallowSelector((state) => state.search.bainIds.data);

  useEffect(() => {
    cancelMidtier();

    return () => {
      softReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(searchActions.setHistoryKey(HISTORY_KEYS.SCREENERS_HISTORY));
  }, [dispatch]);

  useEffect(() => {
    if (query.status === 'success' && !query.isRefetching) {
      dispatch(searchActions.setBainIds(query.data?.pinned_company_ids));
      queryTable.fetch({ fetchCount: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, query.isRefetching, query.data]);

  useEffect(() => {
    if (!query.data || query.isFetching || !companiesLists) return;
    dispatch(companiesListsActions.setCurrentCompaniesList(query.data));

    if (companiesLists.some((list) => list.list_id === query.data.list_id)) return;

    const alreadySharedWithUserItem = companiesListsSharedWithUser.find(
      (sharedCompaniesList) => sharedCompaniesList.list_id === query.data.list_id,
    );

    if (alreadySharedWithUserItem) {
      setCompaniesListsSharedWithUser(
        companiesListsSharedWithUser.map((sharedCompaniesList) =>
          sharedCompaniesList.list_id === query.data.list_id ? query.data : sharedCompaniesList,
        ),
      );

      return;
    }

    setCompaniesListsSharedWithUser([...companiesListsSharedWithUser, query.data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query.data, query.isFetching, companiesLists]);

  const isAccessDenied = query.error?.response?.status == 403;

  return (
    <SearchPage
      isError={query.isError}
      errorPage={isAccessDenied ? ERROR_PAGE_PROPS.ACCESS_DENIED : ERROR_PAGE_PROPS.LIST_NOT_FOUND}
      bainIds={bainIds}
      controls={
        <CompaniesListControls
          isLoading={query.isFetching}
          companiesList={query.data}
        />
      }
      hideSearch
      isCompaniesList
    />
  );
};

export default CompaniesList;
