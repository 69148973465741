import React, { useState, FC } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Tooltip from '@mui/material/Tooltip';

import { ICompaniesListRevision, ICompaniesListRevisionCompany } from '@/types';
import { AvatarVariant, Avatar } from '@/Components/Shared/Avatar/Avatar';
import { MODALS, ROUTES } from '@/constants';
import { useModal } from '@/hooks/use-modal';
import { IconArrowRight } from '@/Components/Icons/IconArrowRight';
import { createCompanyProfileLink } from '@/pages/company-profile';

const INITIAL_REVISION_NUMBER = 1;

const VersionCurrent = ({ name }: { name: string }) => (
  <Tooltip title="currently viewing">
    <div>
      {name}
      <VisibilityOutlinedIcon className="ml-2" />
    </div>
  </Tooltip>
);

const VersionLink = ({ name, url }: { name: string; url: string }) => {
  const { handleClose } = useModal(MODALS.COMPANIES_LIST_HISTORY);

  return (
    <Link
      to={url}
      className="w-full h-full flex items-center underline text-[#0484E7]"
      onClick={handleClose}
    >
      {name}
    </Link>
  );
};

const MetadataChange = ({ label, before, after }: { label: string; before?: string; after?: string }) => {
  return (
    <Typography className="mb-2 max-h-[94px] overflow-y-auto">
      {label}:{before ? <span className="ml-3">{before}</span> : null}
      {before && after ? (
        <span className="ml-3 inline-flex align-bottom	w-[18px]">
          <IconArrowRight />
        </span>
      ) : null}
      {after ? <span className="ml-3">{after}</span> : null}
    </Typography>
  );
};

const PinedCompaniesChange = ({ label, companies }: { label: string; companies: ICompaniesListRevisionCompany[] }) => {
  return (
    <div className="mb-2 max-h-[94px] overflow-y-auto">
      <Typography className="mb-2">{label}:</Typography>
      <Typography className="mb-2">
        {companies.map(({ bain_id, company_name }, index, self) => {
          const isLast = index === self.length - 1;

          return (
            <>
              <button
                key={bain_id}
                onClick={() => window.open(createCompanyProfileLink(String(bain_id)), '_blank')}
                className="underline cursor-pointer text-[#0484E7] flex-inline justify-start"
              >
                {`${company_name} (${bain_id})`}
              </button>
              {isLast ? '' : <span className="mr-1">,</span>}
            </>
          );
        })}
      </Typography>
    </div>
  );
};

const createCompaniesListPath = (listId?: string) => (listId ? `${ROUTES.COMPANIES_LIST}/${listId}` : '/');

interface RowProps {
  row: ICompaniesListRevision;
  isLatest: boolean;
  listIdWithTitle?: string;
  currentRevision?: number;
}

export const Row: FC<RowProps> = ({
  row: {
    revision,
    updated_at,
    updated_by,
    delta: { fields: changes },
  },
  isLatest,
  currentRevision,
  listIdWithTitle,
}) => {
  const [open, setOpen] = useState(false);

  // date string from BE is always as UTC but without timezone mark --> adding Z to mark UTC timezone
  const date = new Date(`${updated_at}Z`);
  const updatedAt = new Intl.DateTimeFormat(undefined, { timeStyle: 'medium', dateStyle: 'short' }).format(date);

  const isInitialRevision = isEmpty(changes) && revision === INITIAL_REVISION_NUMBER;

  const version = () => {
    if ((isNil(currentRevision) || currentRevision === revision) && isLatest)
      return <VersionCurrent name={`v. ${revision} (latest)`} />;

    if (!isNil(currentRevision) && isLatest) {
      return (
        <VersionLink
          name={`v. ${revision} (latest)`}
          url={createCompaniesListPath(listIdWithTitle)}
        />
      );
    }

    const label = isInitialRevision ? `v. ${revision} (initial)` : `v. ${revision}`;

    if (currentRevision === revision) return <VersionCurrent name={label} />;

    return (
      <VersionLink
        name={label}
        url={`${createCompaniesListPath(listIdWithTitle)}/${revision}`}
      />
    );
  };

  return (
    <>
      <TableRow>
        <TableCell className="border-b-0 w-[200px]">{version()}</TableCell>
        <TableCell className="border-b-0">{updatedAt}</TableCell>
        <TableCell className="border-b-0 flex items-center gap-3">
          <Avatar
            variant={AvatarVariant.HEADER}
            userEmail={updated_by}
          />
          {updated_by}
        </TableCell>
        <TableCell className="border-b-0">
          {isInitialRevision ? null : (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={4}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <div className="mt-2 mb-6">
              {changes.title ? (
                <MetadataChange
                  label="Title"
                  before={changes.title.before}
                  after={changes.title.after}
                />
              ) : null}
              {changes.description?.before && changes.description?.after ? (
                <MetadataChange
                  label="Description"
                  before={changes.description.before}
                  after={changes.description.after}
                />
              ) : null}
              {changes.description?.after && !changes.description.before ? (
                <MetadataChange
                  label="Description added"
                  after={changes.description.after}
                />
              ) : null}
              {changes.description?.before && !changes.description.after ? (
                <MetadataChange
                  label="Description removed"
                  before={changes.description.before}
                />
              ) : null}
              {changes.is_sharable_link ? (
                <MetadataChange
                  label="The status of sharing changed"
                  before="private"
                  after="shared"
                />
              ) : null}

              {changes.pinned_company_ids?.added.length ? (
                <PinedCompaniesChange
                  label="Added companies"
                  companies={changes.pinned_company_ids.added}
                />
              ) : null}
              {changes.pinned_company_ids?.removed.length ? (
                <PinedCompaniesChange
                  label="Removed companies"
                  companies={changes.pinned_company_ids.removed}
                />
              ) : null}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
