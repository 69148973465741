import React, { useState, useMemo } from 'react';
import classnames from 'classnames';

import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import { dataNotAvailable } from '@/Components/Shared/common';

const ENTRY_DIRECTION = {
  horizontal: 'horizontal',
  vertical: 'vertical',
} as const;

export interface CompanyProfileWidgetEntryProps {
  label: string;
  value?: unknown;
  id?: string;
  direction?: keyof typeof ENTRY_DIRECTION;
  expandableContent?: unknown;
  isLoading?: boolean;
}

export const CompanyProfileWidgetEntry: React.FC<CompanyProfileWidgetEntryProps> = ({
  label,
  value,
  id,
  direction = ENTRY_DIRECTION.horizontal,
  expandableContent,
  isLoading,
}) => {
  const [showMore, setShowMore] = useState(false);

  const isHorizontalDirection = direction === ENTRY_DIRECTION.horizontal;
  const isVerticalDirection = direction === ENTRY_DIRECTION.vertical;

  const paragraphValue = useMemo(() => {
    if (isLoading)
      return (
        <Skeleton
          width={50}
          height={20}
          className="bg-[#0000000f]"
        />
      );

    return (
      <p
        className={classnames('text-sm', {
          'text-right': isHorizontalDirection,
        })}
      >
        {value ?? dataNotAvailable()}
      </p>
    );
  }, [isHorizontalDirection, isLoading, value]);

  return (
    <div
      key={label}
      id={id}
    >
      <div className="flex mb-4">
        <p className={classnames('text-sm leading-7', { 'w-1/2': isHorizontalDirection })}>{label}</p>
        <div className={classnames('flex items-center', { 'w-1/2 justify-end': isHorizontalDirection })}>
          {isHorizontalDirection ? paragraphValue : null}
          {expandableContent && !isLoading ? (
            <Button
              size="small"
              className="text-[#2E3F4C] border-0 h-full text-medium ml-3"
              onClick={() => setShowMore((prevState) => !prevState)}
            >
              <span className="text-sm">{showMore ? 'Show less' : 'Show more'}</span>
              <KeyboardArrowDownIcon
                className={classnames('fill-cgray-50 text-xl  transition ml-1', { 'rotate-180': showMore })}
              />
            </Button>
          ) : null}
        </div>
      </div>
      <div className="mb-4">{isVerticalDirection ? paragraphValue : null}</div>
      {expandableContent && !isLoading ? (
        <Collapse
          in={showMore}
          timeout={100}
          unmountOnExit
        >
          {expandableContent}
        </Collapse>
      ) : null}
    </div>
  );
};
