import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';

import { Tab as TabType } from './types';
import CompanyProfileTabs from './tabs/CompanyProfileTabs';
import ExportComponent from '@/Components/ExportComponent';
import { ErrorPage } from '@/Components/Shared/ErrorPage';
import { ShareButton } from '@/Components/Shared/Buttons/ShareButton';
import { ERROR_PAGE_PROPS, HEADER_HEIGHT, MODALS } from '@/constants';
import { copyToClipboard } from '@/Utils/navigator-utils';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { AddToListDropdown } from '@/Components/Shared/CompaniesList/SelectCompaniesControls/AddToListDropdown';
import { useMenu } from '@/hooks/use-menu';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { useModal } from '@/hooks/use-modal';
import { useQueryCompanyProfile } from '@/hooks/queries/company-profile/use-query-company-profile';

interface CompanyProfileViewProps {
  bainId: string;
  currentTab: TabType;
}

const CompanyProfileView: React.FC<CompanyProfileViewProps> = ({ bainId, currentTab }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: profile, isLoading: isCompanyProfileLoading } = useQueryCompanyProfile(bainId);
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const query = useQueryCompaniesLists();
  const companiesLists = query.data ?? [];
  const { handleOpen: handleOpenNewCompaniesListDialog } = useModal(MODALS.CREATE_COMPANIES_LIST);

  const params = useMemo(() => {
    const visualizationTable = profile?.find((item: { table: string }) => item.table === 'visualization');
    const midtierData = visualizationTable?.data?.[0];

    if (!midtierData) return undefined;

    return {
      row: { ...midtierData },
      value: midtierData.self_firmo_name___,
    };
  }, [profile]);

  const handleShareButtonClick = () => {
    copyToClipboard(
      window.location.href,
      () =>
        enqueueSnackbar(<NotificationMessage title={'Link copied to clipboard'} />, {
          variant: 'success',
        }),
      () =>
        enqueueSnackbar(
          <NotificationMessage title={'Failed to copy link to clipboard. Please try again or contact support.'} />,
          {
            variant: 'error',
          },
        ),
    );
  };

  const handleOpenDialog = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    companiesLists.length > 0
      ? handleClick(event)
      : handleOpenNewCompaniesListDialog({ companyProfileSelectedCompanies: [bainId] });
  };

  if (!isCompanyProfileLoading && !params?.value) return <ErrorPage {...ERROR_PAGE_PROPS.COMPANY_PROFILE_NOT_FOUND} />;

  return (
    <Box
      className="flex flex-col bg-[#FAFAFA]"
      style={{
        minHeight: `calc(100vh - ${HEADER_HEIGHT}px`,
      }}
    >
      <Box
        px={3}
        py={2}
        className="flex justify-between shadow-[0_1px_3px_0px_rgba(46,48,48,0.14)] bg-[white] min-h-[68px]"
      >
        {isCompanyProfileLoading ? (
          <Skeleton
            width={200}
            height={28}
            className="bg-[#0000000f]"
            data-testid="skeleton"
          />
        ) : (
          <>
            <div className="flex items-center gap-5">
              <Typography
                id="company-profile"
                className="text-2xl font-semibold	 text-[#666] self-center ml-1"
              >
                {params?.value}
              </Typography>

              <ShareButton onClick={handleShareButtonClick} />
            </div>
            <Box className="flex gap-3">
              <Button
                variant="outlined"
                className="text-xs text-[#2e3f4c] border-[#2e3f4c] py-1 px-3"
                startIcon={<FormatListBulletedIcon fontSize="small" />}
                onClick={handleOpenDialog}
              >
                Add to list
              </Button>

              <ExportComponent
                bainIds={[params?.row.self_ids_id__cdp_?.toString()]}
                withoutCount
                isCompanyProfile
                companyProfileId={bainId}
              />
            </Box>
          </>
        )}
      </Box>

      <CompanyProfileTabs
        bainId={bainId}
        params={params}
        currentTab={currentTab}
        isCompanyProfileLoading={isCompanyProfileLoading}
      />

      <AddToListDropdown
        isOpen={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedCompanies={[bainId]}
        companiesLists={companiesLists}
        isCompanyProfile
      />
    </Box>
  );
};

export default CompanyProfileView;
