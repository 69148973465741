import React from 'react';
import { useDispatch } from 'react-redux';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';

import { IconButton, Skeleton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { actions } from '@/slices/table';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

interface CustomToolbarProps {
  onResetTableSettings: () => void;
  showResetTableSettingsButton: boolean;
  currentRows: {
    min: number;
    max: number;
  };
  countText: string;
  isLoading: boolean;
}

export const CustomToolbar = ({
  onResetTableSettings,
  showResetTableSettingsButton,
  currentRows,
  countText,
  isLoading,
}: CustomToolbarProps) => {
  const dispatch = useDispatch();
  const isChecked = useShallowSelector((state) => state.table.isConfidenceLevelChecked);

  const handleSwitch = () => {
    dispatch(actions.toggleConfidenceLevel());
  };

  const countNumber = Number(countText.replace(/[~,]/g, ''));

  return (
    <GridToolbarContainer className="ml-2 flex justify-between table-toolbar">
      <div className="flex gap-3 flex-1">
        <Tooltip title="Columns">
          <GridToolbarColumnsButton className="min-w-[40px] h-[40px] m-0 toolbar-button rounded-full [&>span]:m-0" />
        </Tooltip>

        <Tooltip title="Row height">
          <GridToolbarDensitySelector className="min-w-[40px] h-[40px] m-0 toolbar-button rounded-full [&>span]:m-0" />
        </Tooltip>

        {showResetTableSettingsButton ? (
          <Tooltip title="Reset table">
            <IconButton
              onClick={onResetTableSettings}
              className="hover:bg-[#3f51b50a] "
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>

      {isLoading ? (
        <Skeleton
          variant="rounded"
          className="bg-[#0000000f] w-[100px]"
        />
      ) : (
        <div
          data-testid="table-search-meta"
          className="text-[#484848] flex flex-1 justify-center"
        >
          {`${countNumber === 0 ? countNumber : currentRows.min} - ${Math.min(currentRows.max, countNumber)} of`}&nbsp;
          <span className="font-semibold">{countText}</span>
        </div>
      )}

      <div className="flex flex-1 justify-end">
        <FormControlLabel
          data-testid="column-visibility-toggle"
          className="text-sm confidence-level-switcher"
          control={
            <Switch
              checked={isChecked}
              onChange={handleSwitch}
              classes={{
                track: isChecked ? 'bg-cred-50 opacity-100' : 'bg-black',
                checked: 'text-white',
              }}
            />
          }
          label="Show confidence level"
          classes={{
            label: 'text-xs text-[#484848]',
          }}
        />
      </div>
    </GridToolbarContainer>
  );
};
