import React, { useState, useMemo, useRef, useEffect } from 'react';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import hash from 'object-hash';
import { reduce, isEmpty } from 'lodash';

import { FilterHeader } from './FilterHeader';
import { IconFilters } from '@/Components/Icons/IconFilters';
import { useQueryAvailableFilters } from '@/hooks/queries/use-query-available-filters';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useSearchCompanies } from '@/hooks/use-search-companies';
import { getFilters } from '@/Utils/filters';
import { resetAllSubject } from '@/Utils/subjects';
import { SEARCH_EVENTS } from '@/constants';
import { QueryType } from '@/slices/search';

const getHash = (filters) => hash({ ...getFilters(filters), includedNullList: filters.includedNullList });

export const FiltersDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const availableFiltersQuery = useQueryAvailableFilters();
  const queryType = useShallowSelector((state) => state.search.queryType);
  const searchText = useShallowSelector((state) => state.search.searchText);
  const searchCompanies = useSearchCompanies();
  const availableFiltersData = availableFiltersQuery.data ?? [];
  const availableFilters = availableFiltersData;
  const allAppliedFilters = useShallowSelector((state) => state.filters);
  const filterHashRef = useRef(getHash(allAppliedFilters));

  useEffect(() => {
    const subscription = resetAllSubject.subscribe(() => {
      filterHashRef.current = getHash(allAppliedFilters);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [allAppliedFilters]);

  const isFilterApplied = useMemo(
    () =>
      reduce(
        { ...allAppliedFilters.treeFilters, ...allAppliedFilters.otherFilters },
        (__, item) => !isEmpty(item),
        false,
      ),
    [allAppliedFilters],
  );

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    const newFilterHash = getHash(allAppliedFilters);

    if (filterHashRef.current !== newFilterHash) {
      const isSmartSearch = queryType === QueryType.SMART;

      searchCompanies({ searchText: isSmartSearch ? '' : searchText, eventName: SEARCH_EVENTS.CHANGE_FILTERS });
    }

    filterHashRef.current = newFilterHash;

    setIsOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<IconFilters fill="currentColor" />}
        endIcon={
          isFilterApplied ? (
            <CheckIcon
              className="ml-1"
              data-testid="filters-applied-icon"
            />
          ) : null
        }
        className={classnames(
          'px-2 py-2 border-bluegray-900 border border-solid hover:bg-bluegray-900 hover:text-white font-graphik text-xs h-[36px]',
          {
            'bg-[#fff] text-bluegray-900': !isFilterApplied,
            'bg-bluegray-900 text-white': isFilterApplied,
          },
        )}
        onClick={handleOpen}
        data-testid="filters-button"
      >
        Filters
      </Button>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleClose}
        variants="persistent"
        classes={{
          paper: 'w-[35vw] p-4 shadow-[-2px_0px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        <div className="flex items-center justify-between mb-4">
          <span className="font-semibold">Filters</span>
          <IconButton
            data-testid="close-filters-drawer"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className="flex flex-col gap-2">
          {availableFiltersQuery.isLoading ? (
            <Box className="flex justify-center">
              <CircularProgress />
            </Box>
          ) : (
            availableFilters?.map((filter) => (
              <FilterHeader
                key={filter.displayHeader}
                filter={filter}
              />
            ))
          )}
        </div>
      </Drawer>
    </>
  );
};
