import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useSessionStorage } from 'usehooks-ts';

import classNames from 'classnames';
import mainLogo from '../../assets/helix_find_logo.svg';
import { CardIconList } from '../Shared/Card/CardIcons/CardIconList';
import { CardIconSearch } from '../Shared/Card/CardIcons/CardIconSearch';
import { createCompaniesListPath } from '../Shared/CompaniesList/ShareCompaniesListLink';
import { createSavedSearchPath } from '../Shared/SavedSearch/ShareSavedSearchButton';
import { ProfileDropdown } from './ProfileDropdown';
import { HelixAppsDropdown } from './HelixAppsDropdown';
import { ROUTES, HEADER_HEIGHT, HOME_PAGE_SLUG, LOCAL_STORAGE_KEYS } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useTableLoading } from '@/hooks/table/use-table-loading';

import '../../App.css';

const shouldCurrencyShown = (path) =>
  path === ROUTES.CUSTOM_SEARCH ||
  path.includes(ROUTES.COMPANIES_LIST) ||
  path.includes(ROUTES.SAVED_SEARCH) ||
  path.includes(ROUTES.COMPANY_PROFILE);

const addCaseCodeQueryParamsToUrl = (url, caseCodeFormData) => {
  const { formData, ...rest } = caseCodeFormData;
  const flatCaseCodeFormData = { ...formData, ...rest };

  const queryParams = new URLSearchParams(flatCaseCodeFormData).toString();

  const alreadyHasQueryParams = url.includes('?');

  return `${url}${alreadyHasQueryParams ? '&' : '?'}${queryParams}`;
};

const Header = () => {
  const location = useLocation();
  const isLoading = useTableLoading();
  const { currentCompaniesList, currentSavedSearch } = useShallowSelector((state) => state.navigationTabs);
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE);
  const isLoginPage = location.pathname === ROUTES.ROOT;
  const isCompanyProfilePage = location.pathname.startsWith(ROUTES.COMPANY_PROFILE);

  const handleLogoClick = () => {
    //TODO: change to environment specific link when ready
    window.location = addCaseCodeQueryParamsToUrl('https://helix.bain.io', caseCodeData);
  };

  const isHomePageTabVisible = !isLoginPage;
  const isCompanySearchTabVisible = !isLoginPage && !isCompanyProfilePage;
  const isSavedSearchTabVisible = !isLoginPage && !!currentSavedSearch && !isCompanyProfilePage;
  const isCompaniesListTabVisible = !isLoginPage && !!currentCompaniesList && !isCompanyProfilePage;

  return (
    <div
      data-testid="headerComponent"
      style={{
        height: HEADER_HEIGHT,
      }}
    >
      <Box className=" items-center flex ">
        <Box className="flex w-full">
          <div className="w-full flex justify-between h-12">
            <div className="flex">
              <button
                className="flex items-center cursor-pointer pl-2 pr-3 border-r border-b border-b-[#dddddd] border-r-[#dddddd] "
                onClick={handleLogoClick}
                data-testid="helix-find-logo"
              >
                <img
                  src={mainLogo}
                  style={{ width: 100, height: 32 }}
                  alt="logo"
                />
              </button>
              {isHomePageTabVisible ? (
                <div className="w-14 border-r border-r-[#dddddd] ">
                  <NavLink
                    to={HOME_PAGE_SLUG}
                    className="w-full h-full flex items-center justify-center border-b border-b-[white] [&:not(.active)]:border-b-[#dddddd]"
                  >
                    <FormatListBulletedIcon
                      fontSize="medium"
                      className="fill-[#666]"
                    />
                  </NavLink>
                </div>
              ) : null}

              {isCompanySearchTabVisible ? (
                <div className="border-r border-r-[#dddddd] ">
                  <NavLink
                    to={ROUTES.CUSTOM_SEARCH}
                    className={classNames(
                      'w-full h-full px-6 flex items-center justify-center border-b border-b-[white] [&:not(.active)]:border-b-[#dddddd]',
                      {
                        'pointer-events-none': isLoading,
                      },
                    )}
                  >
                    <span className="text-sm font-semibold text-[#666]">Company search</span>
                  </NavLink>
                </div>
              ) : null}

              {isSavedSearchTabVisible ? (
                <div className="border-r border-r-[#dddddd] max-w-[320px]">
                  <NavLink
                    to={createSavedSearchPath(currentSavedSearch.title, currentSavedSearch.list_id)}
                    className={classNames(
                      'w-full h-full grid grid-cols-[40px_1fr] items-center px-4 border-b border-b-[white] [&:not(.active)]:border-b-[#dddddd]',
                      {
                        active: location.pathname.startsWith(`${ROUTES.SAVED_SEARCH}/`),
                        'pointer-events-none': isLoading,
                      },
                    )}
                  >
                    <CardIconSearch />
                    <span className="text-sm font-semibold text-[#666] truncate">{currentSavedSearch.title}</span>
                  </NavLink>
                </div>
              ) : null}

              {isCompaniesListTabVisible ? (
                <div className="border-r border-r-[#dddddd] max-w-[320px]">
                  <NavLink
                    to={createCompaniesListPath(currentCompaniesList.title, currentCompaniesList.list_id)}
                    className={classNames(
                      'w-full h-full grid grid-cols-[40px_1fr] items-center px-4 border-b border-b-[white] [&:not(.active)]:border-b-[#dddddd]',
                      {
                        active: location.pathname.startsWith(`${ROUTES.COMPANIES_LIST}/`),
                        'pointer-events-none': isLoading,
                      },
                    )}
                  >
                    <CardIconList />
                    <span className="text-sm font-semibold text-[#666] truncate">{currentCompaniesList.title}</span>
                  </NavLink>
                </div>
              ) : null}
            </div>

            <div className="pr-6 m-0 grow gap-6 supportOptions items-center justify-end border-b border-b-[#dddddd]">
              <HelixAppsDropdown />

              <Typography className="text-[#2E3F4C] font-medium text-sm cursor-pointer">What’s new?</Typography>

              {shouldCurrencyShown(location.pathname) && <ProfileDropdown />}
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Header;
