import React from 'react';
import {
  DataGridPro,
  GridColumns,
  GridFilterModel,
  GridPinnedColumns,
  GridRowsProp,
  GridToolbarContainer,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { IconButton, Grid, Tooltip } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

interface GenericTableProps {
  tableName: string;
  columns: GridColumns;
  rows: GridRowsProp;
  filterModel?: GridFilterModel;
  pinnedColumns?: GridPinnedColumns;
}

export const GenericTable = ({ tableName, columns, rows, filterModel, pinnedColumns }: GenericTableProps) => {
  const apiRef = useGridApiRef();

  const renderToolbar = () => {
    return (
      <GridToolbarContainer className="h-14 px-4 border-b flex justify-between items-center">
        <p className="font-semibold	 ">{tableName}</p>
        <div>
          <Tooltip title="Download as CSV">
            <IconButton
              onClick={() => apiRef.current.exportDataAsCsv()}
              className="ml-1 mb-[1px]"
              size="small"
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <Grid
      container
      spacing={1}
      style={{ margin: '0', marginBottom: 16, width: '100%' }}
      key={tableName}
    >
      <DataGridPro
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autoHeight
        rowHeight={48}
        //@ts-ignore
        customHeaderHeight={28}
        filterModel={filterModel}
        hideFooter
        disableColumnReorder
        pinnedColumns={pinnedColumns}
        sx={{
          backgroundColor: '#fff',
          '.MuiDataGrid-main': {
            boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 14%)',
            borderRadius: '4px',
          },
          '.MuiDataGrid-cell, .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid rgba(0,0,0,0.04)',
          },
        }}
        components={{
          Toolbar: renderToolbar,
        }}
      />
    </Grid>
  );
};
