import _ from 'lodash';

export const isCaseCodeValid = (data, noCaseCode) =>
  noCaseCode ? _.isNil(data?.projectName) && _.isNil(data?.caseType) : _.isNil(data?.caseCode);

export const prepareCaseCodeData = (caseCodeFormData, clearEmptyValues = true) => {
  const caseCodeData = {
    case_code: caseCodeFormData?.caseCode,
    partner_email: caseCodeFormData?.partnerEmail,
    project_type: caseCodeFormData?.projectType,
    project_name: caseCodeFormData?.projectName,
    other_info: caseCodeFormData?.otherInfo,
    other_email: caseCodeFormData?.otherEmail,
  };

  const cleanedCaseCodeData = _.pickBy(caseCodeData, _.identity);

  return clearEmptyValues ? cleanedCaseCodeData : caseCodeData;
};
