import React, { useMemo } from 'react';
import { SkeletonLoader } from '../../common/SkeletonLoader';
import MnATable from './MnATable';
import MnABuyerSummary from './MnABuyerSummary';
import { DatumDatum, IMergesAndAcquisitions } from '@/types';

const COLUMNS_LIST = ['entry_date', 'exit_date', 'transaction_value', 'ownership_pct', 'currently_in_portfolio'];

interface MnATabProps {
  params?: {
    row: DatumDatum;
    value?: string;
  };
  isLoading: boolean;
  isCompanyProfileLoading: boolean;
  m_n_a?: IMergesAndAcquisitions[];
}

const MnATab = ({ m_n_a: ma_data, isLoading, isCompanyProfileLoading, params }: MnATabProps) => {
  const maArray = ma_data && Object.entries(ma_data);

  const dataSets = useMemo(() => {
    return (maArray?.flat().filter((data) => typeof data !== 'string') as IMergesAndAcquisitions[])?.reduce(
      (accumulator, currentValue) => {
        return { ...accumulator, ...currentValue };
      },
      {},
    );
  }, [maArray]);

  if (isLoading || isCompanyProfileLoading) return <SkeletonLoader />;

  if (!ma_data || Object.entries(ma_data).length === 0 || !params)
    return <div>No merges and acquisitions data available</div>;

  const buyerSummary = dataSets.buyer_summary?.[0];
  const targetTransactions = dataSets.target_transactions;
  const buyerTransactions = dataSets.buyer_transactions;

  return (
    <div>
      {buyerSummary ? <MnABuyerSummary data={buyerSummary} /> : null}
      {targetTransactions ? (
        <MnATable
          tableData={targetTransactions}
          title="Transactions as target"
          columnsList={[...COLUMNS_LIST, 'buyer_name']}
        />
      ) : null}
      {buyerTransactions ? (
        <MnATable
          tableData={buyerTransactions}
          title="Transactions as buyer"
          columnsList={[...COLUMNS_LIST, 'company_name']}
        />
      ) : null}
    </div>
  );
};

export default MnATab;
