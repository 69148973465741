import { useMutation } from '@tanstack/react-query';
import API from '@aws-amplify/api-rest';
import { useDispatch } from 'react-redux';
import { useSessionStorage } from 'usehooks-ts';
import { isEmpty } from 'lodash';
import { useShallowSelector } from '../use-shallow-selector';
import { actions as modalActions } from '@/slices/modal';

import { IS_DEV, LOCAL_STORAGE_KEYS, MODALS, CASE_CODE_FORM_STEPS } from '@/constants';
import { prepareCaseCodeData } from '@/Utils/case-code-utils';

type TData = {
  formData: TFormData;
  redirect?: () => void;
  flowType: string;
  step: string;
  shouldBeLogged?: boolean;
};

export const CASE_CODE_INITIAL_STATE: TData = {
  formData: {},
  flowType: '',
  step: CASE_CODE_FORM_STEPS.FIRST,
};

export type TFormData = {
  caseCode?: string;
  partnerEmail?: string;
  projectType?: string;
  projectName?: string;
  otherInfo?: string;
  otherEmail?: string;
};

export const useMutationLogCaseCode = () => {
  const dispatch = useDispatch();
  const email = useShallowSelector((state) => state.user.email);
  const [, setCaseCode] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);

  return useMutation({
    mutationKey: ['log-case-code'],
    mutationFn: ({ formData, flowType }: TData) => {
      const userData = { email, sql_query: true };

      const body = {
        case_data: { ...prepareCaseCodeData(formData), work_stream: flowType },
        ...userData,
      };

      return API.post('CDPAPI', '/log_user_data', { body: body });
    },
    onSuccess: (_, { formData, redirect, flowType, step }) => {
      if (!isEmpty(formData) && !IS_DEV) {
        pendo.track('User Case Code registration', {
          workStream: flowType,
          caseCode: formData?.caseCode,
          partnerEmail: formData?.partnerEmail,
          projectType: formData?.projectType,
          projectName: formData?.projectName,
          otherInfo: formData?.otherInfo,
          otherEmail: formData?.otherEmail,
        });

        if (formData?.caseCode) {
          pendo.identify({
            account: {
              id: formData?.caseCode,
            },
          });
        }
      }

      setCaseCode({ formData, flowType, step });

      redirect?.();
      dispatch(modalActions.closeModal({ id: MODALS.CASE_CODE, data: {} }));
    },
    onError: (error) => alert(error),
  });
};
