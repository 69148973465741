import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isNil } from 'lodash';

import { actions as searchActions } from '@/slices/search';
import { getCompaniesListById, getCompaniesListRevisionById } from '@/services/api/companies-list';
import { TApiError } from '@/types';

export const useQueryCompaniesListById = (id: string, isLoadingTable = true, revision?: number, { ...props } = {}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useQuery(
    ['companies-list', id, revision],
    () => {
      if (isLoadingTable) {
        dispatch(searchActions.setIsLoading(true));
      }

      if (!isNil(revision)) return getCompaniesListRevisionById(id, revision);

      return getCompaniesListById(id);
    },
    {
      enabled: !!id,
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onError: () =>
        enqueueSnackbar('Failed to fetch companies list. Please try again or contact support.', { variant: 'error' }),
      // do not retry on 404 and 403
      retry: (failureCount, error: TApiError) => {
        return error?.response?.status >= 500 && failureCount < 3;
      },
      ...props,
    },
  );
};
