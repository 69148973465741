export const ucFirstLetter = (string: string) => string?.charAt(0).toUpperCase() + string?.slice(1);

export const spaceToDash = (string?: string) => string?.replace(/ /g, '-').toLowerCase();

export const clearString = (string?: string) =>
  string
    ?.replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s\s+/g, ' ')
    .trim()
    .toLowerCase();

export const removeTrailingSlash = (string?: string) => string?.replace(/\/+$/, '');

export const formatCompanyDescription = (description?: string) => {
  if (!description) return;

  const startsWithSource = description.startsWith('Source');

  if (!startsWithSource) {
    const trimmedDescription = description.trim();

    return {
      source: undefined,
      description: trimmedDescription,
      formattedDescription: trimmedDescription,
    };
  }

  if (startsWithSource) {
    const sourceRegexp = /Source ([A-Za-z -]+):/g;
    const multipleSpaceRegexp = /\s\s+/g;

    const sources: string[] = Array.from(description.matchAll(sourceRegexp), (m) => m[1].trim());
    const rawDescription: string = description.replace(sourceRegexp, '').replace(multipleSpaceRegexp, ' ').trim();

    const joinedSources = sources.join(', ');
    const formattedDescription = sources.length ? `${rawDescription} - Source: ${joinedSources}` : rawDescription;

    return {
      source: joinedSources,
      description: rawDescription,
      formattedDescription,
    };
  }
};

export const isStringPositiveNumber = (string: string) => {
  const number = Math.floor(Number(string));

  return number !== Infinity && String(number) === string && number > 0;
};

export const validateEmail = (email: string) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};
