/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { useEffectOnce, useSessionStorage } from 'usehooks-ts';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Auth } from '@aws-amplify/auth';

import awsService from '../awsService';
import { actions as userActions } from '../slices/user';

import { readStorageValue, setStorageValue } from '@/Utils/local-storage-utils';
import { LOCAL_STORAGE_KEYS, HOME_PAGE_SLUG, CASE_CODE_FLOWS, CASE_CODE_FORM_STEPS } from '@/constants';
import '../App.css';

const isQueryParamsCaseCodeValid = (queryParams) => {
  if (!queryParams) return false;

  const validFlow = Object.values(CASE_CODE_FLOWS).find(({ label }) => label === queryParams.flowType);
  const validStep = Object.values(CASE_CODE_FORM_STEPS).find((step) => step === queryParams.step);

  if (!validFlow || !validStep) return false;

  const availableCaseCodeFields = validFlow[validStep];

  if (!availableCaseCodeFields) return false;

  const requiredFields = availableCaseCodeFields.filter(({ required }) => !!required);

  return requiredFields.every(({ key }) => !!queryParams?.[key]?.trim());
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [, setCaseCode] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE);

  const handleLogin = async () => {
    setIsLoggingIn(true);

    const res = await awsService.authenticate();

    const redirectUrl = readStorageValue(LOCAL_STORAGE_KEYS.REDIRECT_URL);

    const caseCodeFormData = readStorageValue(LOCAL_STORAGE_KEYS.CASE_CODE, window.sessionStorage);
    const isCaseCodeProvided = !isEmpty(caseCodeFormData);

    if (!res) {
      console.log('Waiting for token...');
    } else if (res.status === 'error') {
      setIsLoggingIn(false);
      setIsLoginFailed(true);
    } else {
      history.push(isCaseCodeProvided && redirectUrl !== '' ? redirectUrl : HOME_PAGE_SLUG);
      setIsLoggingIn(false);

      setStorageValue(LOCAL_STORAGE_KEYS.REDIRECT_URL, '');
    }
  };

  useEffectOnce(async () => {
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

    if (isQueryParamsCaseCodeValid(queryParams)) {
      const { flowType, step, ...rest } = queryParams;

      const caseCodeFormData = {
        flowType,
        step,
        formData: rest,
        shouldBeLogged: true,
      };

      setCaseCode(caseCodeFormData);

      handleLogin();
    }

    const res = await awsService.userLoggedIn();

    const { username: email } = await Auth.currentAuthenticatedUser();

    const redirectUrl = readStorageValue(LOCAL_STORAGE_KEYS.REDIRECT_URL);

    const caseCodeFormData = readStorageValue(LOCAL_STORAGE_KEYS.CASE_CODE, window.sessionStorage);
    const isCaseCodeProvided = !isEmpty(caseCodeFormData);

    if (res) {
      console.log('User is logged in, redirecting');

      awsService.setAuthenticationStatus(true);
      history.push(isCaseCodeProvided && !!redirectUrl ? redirectUrl : HOME_PAGE_SLUG);

      setStorageValue(LOCAL_STORAGE_KEYS.REDIRECT_URL, '');

      dispatch(userActions.setEmail(email.split('_')[1]));
      dispatch(userActions.setIsAuth(true));
    }
  });

  return (
    <Box
      sx={{ display: 'flex' }}
      p={20}
      data-testid="loginComponent"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '100',
          width: 315,
          borderRadius: 2,
        }}
        px={3}
        py={5}
        mx="auto"
      >
        <Button
          id="login-button"
          disabled={isLoggingIn}
          className={classnames('px-3', {
            'bg-[#ddd]': isLoggingIn,
            'bg-[#cc0100] text-white': !isLoggingIn,
          })}
          onClick={handleLogin}
          data-testid="login-using-sso"
        >
          <span className="flex items-center relative">
            Login Using SSO
            {isLoggingIn ? (
              <CircularProgress
                color="info"
                size={18}
                sx={{ ml: 1, position: 'absolute', left: '100%' }}
              />
            ) : null}
          </span>
        </Button>
        {isLoginFailed && (
          <Typography
            variant="h3"
            color="error"
            my={2}
            fontWeight="bold"
          >
            Login Failed
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Login;
