import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import React, { useState, forwardRef } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'react-redux';

import { actions, QueryType } from '@/slices/search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { ucFirstLetter } from '@/Utils/text';

const SearchTypeDropdown = forwardRef(({ onDropdownToggle }, ref) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const queryType = useShallowSelector((state) => state.search.queryType);
  const isOpened = !!anchorEl;

  const openDropdown = () => {
    setAnchorEl(ref?.current);
    onDropdownToggle?.(true);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    onDropdownToggle?.(false);
  };

  const handleOptionClick = (payload) => {
    dispatch(actions.setQueryType(payload));
    dispatch(actions.setSearchQuery(''));
    dispatch(actions.setSearchText(''));
    handleDropdownClose();
  };

  const options = [
    {
      label: 'Keyword',
      description: 'Use terms to describe your target companies e.g. "e-learning" OR "online courses',
      onClick: () => {
        handleOptionClick(QueryType.KEYWORD);
      },
    },
    {
      label: 'Match',
      description: 'Use company names, URL’s or descriptions e.g. “Amazon”',
      onClick: () => {
        handleOptionClick(QueryType.MATCH);
      },
    },
    {
      label: 'Smart search',
      afterLabel: (
        <span className="bg-[#0087EE] flex items-center text-white px-2 rounded mr-1 font-bold text-xs">Beta</span>
      ),
      description: 'Use sentences e.g. "Production of biofuel from algae"',
      onClick: () => {
        handleOptionClick(QueryType.SMART);
      },
    },
  ];

  return (
    <>
      <Button
        data-testid="search-type-dropdown"
        aria-controls={isOpened ? 'history-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpened ? 'true' : undefined}
        disableElevation
        onClick={openDropdown}
        classes={{ root: 'min-w-[30px] p-1 w-20' }}
      >
        <span className="font-normal text-[#666666] text-xs">{ucFirstLetter(queryType)}</span>
        <KeyboardArrowDownIcon
          className={classnames('fill-cgray-50 text-xl transition ml-1', { 'rotate-180': isOpened })}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpened}
        onClose={handleDropdownClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={option.onClick}
            sx={{ width: '492px' }}
          >
            <Box className="flex flex-col">
              <Box className="flex gap-x-1">
                <Typography
                  className="font-bold"
                  variant="inherit"
                  noWrap
                >
                  {option.label}
                </Typography>
                {option.afterLabel}
              </Box>
              <span className="text-xs text-[#666666]">{option.description}</span>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

SearchTypeDropdown.displayName = 'SearchTypeDropdown';

export default SearchTypeDropdown;
