import { useMutation } from '@tanstack/react-query';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { queryElasticSearchBainIds } from '@/Api/search-api.service';

export const useMutationValidateBainIds = () => {
  const currency = useShallowSelector((state) => state.customScreen.currency);

  return useMutation({
    mutationKey: ['validate-bain-ids'],
    mutationFn: (pinned_company_ids: string[]) => {
      let query = '';

      pinned_company_ids?.forEach((company, index, array) => {
        const isLast = index === array.length - 1;

        query += `${company}${isLast ? '' : ' OR '}`;
      });

      return queryElasticSearchBainIds({
        company_search: `bain_id:(${query})`,
        filters: {},
        include_null: [],
        unique_company: false,
        lucene_query: true,
        currency,
      });
    },
  });
};
