import React from 'react';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Skeleton } from '@mui/material';

import { difference } from 'lodash';
import classnames from 'classnames';
import { TabPanel } from '../common/TabPanel';
import { Tab as TabType, TABS, TABS_SLUGS, CUSTOM_DATASETS } from '../types';
import { MultiyearFinancialsTab } from './MultiyearFinTab/MultiyearFinTab';
import MnATab from './MnATab/MnATab';
import { OverviewTab } from './OverviewTab/OverviewTab';
import { GenericTableTab } from './GenericTableTab/GenericTableTab';
import { createCompanyProfileLink } from '@/pages/company-profile';
import { useQueryCompanyMergesAndAcquisitions } from '@/hooks/queries/company-profile/use-query-company-m-a';
import { DatumDatum } from '@/types';
import { useQueryCompanyProfileDatasets } from '@/hooks/queries/company-profile/use-query-company-profile-datasets';
import { ucFirstLetter } from '@/Utils/text';

export const generateTab = (dataset: string) => {
  const formattedSlug = dataset.replaceAll('_', '-');

  return {
    slug: formattedSlug,
    id: formattedSlug,
    label: ucFirstLetter(dataset.replaceAll('_', ' ')),
  };
};

interface CompanyProfileTabsProps {
  bainId: string;
  currentTab: TabType;
  isCompanyProfileLoading: boolean;
  params?: {
    row: DatumDatum;
    value?: string;
  };
}

const CompanyProfileTabs: React.FC<CompanyProfileTabsProps> = ({
  bainId,
  currentTab,
  params,
  isCompanyProfileLoading,
}) => {
  const { push } = useHistory();

  const { data: mergesAndAcquisitions, isLoading: isMergesAndAcquisitionsLoading } =
    useQueryCompanyMergesAndAcquisitions(bainId, currentTab.slug === TABS_SLUGS.M_AND_A);

  const { data: companyProfileDatasets, isLoading: isCompanyProfileDatasetsLoading } =
    useQueryCompanyProfileDatasets(bainId);

  const genericDatasets = difference(companyProfileDatasets ?? CUSTOM_DATASETS, CUSTOM_DATASETS).map(generateTab);

  const customTabs = TABS.map((tab) => ({
    ...tab,
    disabled: !tab.datasets?.some((dataset) => companyProfileDatasets?.includes(dataset)),
  }));

  const tabs: TabType[] = [...customTabs, ...genericDatasets];

  const handleChange = (_: React.SyntheticEvent<Element, Event>, newIndex: number) =>
    push(createCompanyProfileLink(bainId, tabs[newIndex]));

  const currentTabIndex = tabs.findIndex((tab) => currentTab.slug === tab.slug);

  const renderTabContent = (slug: string) => {
    switch (slug) {
      case TABS_SLUGS.OVERVIEW:
        return (
          <OverviewTab
            params={params}
            bainId={bainId}
            isCompanyProfileLoading={isCompanyProfileLoading}
          />
        );
      case TABS_SLUGS.FINANCIAL_DETAILS:
        return (
          <MultiyearFinancialsTab
            bainId={bainId}
            revenueMetadata={params?.row?.self_financials_revenue___metadata}
          />
        );
      case TABS_SLUGS.M_AND_A:
        return (
          <MnATab
            params={params}
            isLoading={isMergesAndAcquisitionsLoading}
            isCompanyProfileLoading={isCompanyProfileLoading}
            m_n_a={mergesAndAcquisitions}
          />
        );
      default:
        return (
          <GenericTableTab
            bainId={bainId}
            dataset={slug.replaceAll('-', '_')}
          />
        );
    }
  };

  return (
    <>
      <Box
        px={3}
        className="flex flex-col pt-6"
      >
        {isCompanyProfileDatasetsLoading ? (
          <Skeleton
            width={600}
            height={28}
            className="bg-[#0000000f]"
            data-testid="skeleton"
          />
        ) : (
          <Tabs
            value={currentTabIndex}
            id="company-tab-container"
            onChange={handleChange}
            className="min-h-[auto]"
            classes={{
              indicator: 'bg-[#2E3F4C]',
            }}
          >
            {tabs.map(({ label, id, disabled }) => (
              <Tab
                key={id}
                label={label}
                id={id}
                disabled={disabled}
                className={classnames(
                  'text-sm text-[#2E3F4C] font-normal p-0 justify-end mb-2 min-w-[auto] min-h-[auto] mr-10 tracking-wide',
                  {
                    'opacity-50': disabled,
                  },
                )}
              />
            ))}
          </Tabs>
        )}
      </Box>

      {tabs.map(({ id, slug, disabled }, index) => (
        <TabPanel
          key={id}
          value={currentTabIndex}
          index={index}
        >
          {disabled ? null : renderTabContent(slug)}
        </TabPanel>
      ))}

      {currentTabIndex === -1 && !isCompanyProfileDatasetsLoading ? (
        <div className="px-6 pt-4">Incorrect tab url</div>
      ) : null}
    </>
  );
};

export default CompanyProfileTabs;
