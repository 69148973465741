import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GridSortModel } from '@mui/x-data-grid-pro';
import { DEFAULT_PAGE_SIZE, DEFAULT_VISIBLE_COLUMNS, EXTENDED_DEFAULT_COLUMNS } from '@/constants';

const initialState = {
  pageNumber: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  sortModel: [] as GridSortModel,
  totalRows: 0,
  defaultColumns: DEFAULT_VISIBLE_COLUMNS,
  visible: EXTENDED_DEFAULT_COLUMNS,
  customColumnsOrder: {} as Record<string, number>,
  isConfidenceLevelChecked: false,
  isMidtierEnabled: false,
  isMidtierCountEnabled: false,
};

export const { reducer, actions } = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setEnableMidtierQuery: (state, action: PayloadAction<boolean>) => {
      state.isMidtierEnabled = action.payload;
    },
    setEnableMidtierCountQuery: (state, action: PayloadAction<boolean>) => {
      state.isMidtierCountEnabled = action.payload;
    },
    toggleConfidenceLevel: (state) => {
      state.isConfidenceLevelChecked = !state.isConfidenceLevelChecked;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRows: (state, action: PayloadAction<number>) => {
      state.totalRows = action.payload;
    },
    setSortModel: (state, action: PayloadAction<GridSortModel>) => {
      state.sortModel = action.payload;
    },
    setVisibleColumns: (state, action: PayloadAction<string[]>) => {
      state.visible = action.payload;
    },
    setCustomColumnsOrder: (state, action: PayloadAction<Record<string, number>>) => {
      state.customColumnsOrder = action.payload;
    },
    setDefaultColumns: (state, action: PayloadAction<string[]>) => {
      state.defaultColumns = action.payload;
    },
    resetTable: () => {
      return initialState;
    },
  },
});

export type TState = typeof initialState;
