import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';

import { useModal } from '../../../../hooks/use-modal';
import { MODALS } from '../../../../constants';

export const TooManyCompaniesDialog = () => {
  const { isOpen, handleClose } = useModal(MODALS.TOO_MANY_COMPANIES);

  const handleCloseDialog = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      classes={{ paper: 'w-1/3' }}
    >
      <DialogTitle classes={{ root: 'font-bold text-base' }}>Too many companies</DialogTitle>

      <CloseIcon
        fontSize="small"
        className="absolute right-3 top-4 cursor-pointer"
        onClick={handleCloseDialog}
      />

      <DialogContent className="pt-0">
        <DialogContentText className="text-sm">
          <div>We can only compare up to 5 companies.</div>
          <div>Please narrow your selection.</div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
