import React from 'react';
import Button from '@mui/material/Button';
import classnames from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isNil } from 'lodash';
import { Loader } from '../Loader/Loader';
import { IconShare } from '@/Components/Icons/IconShare';

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  isOpen?: boolean;
  isSharedWithUserList?: boolean;
};

export const ShareButton = ({
  onClick,
  isLoading = false,
  dataTestId,
  disabled = false,
  isOpen,
  isSharedWithUserList,
}: Props) => {
  const isExpandable = !isNil(isOpen);

  const label = isSharedWithUserList ? 'Copy link to clipboard' : 'Share';

  return (
    <Button
      variant="outlined"
      startIcon={<IconShare />}
      className={classnames('text-[#2E3F4C] border-0 pl-3 pr-2 h-full', {
        'opacity-50': disabled,
      })}
      onClick={onClick}
      data-testid={dataTestId}
      disabled={disabled}
    >
      <span
        className={classnames('w-[45px] flex justify-center font-medium', {
          'opacity-50': disabled,
          'w-[140px]': isSharedWithUserList,
        })}
      >
        {isLoading ? <Loader /> : label}
      </span>
      {isExpandable ? (
        <KeyboardArrowDownIcon
          className={classnames('fill-cgray-50 text-xl transition ml-1', { 'rotate-180': isOpen })}
        />
      ) : null}
    </Button>
  );
};
