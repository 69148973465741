import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { useQueryTable } from './queries/use-query-table';
import { actions as filterActions } from '@/slices/filters';
import { actions as searchActions } from '@/slices/search';
import { actions as tableActions } from '@/slices/table';
import { actions as selectedCompaniesActions } from '@/slices/selected-companies';
import { resetAllSubject } from '@/Utils/subjects';

export const useScreener = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const query = useQueryTable();

  const resetAll = useCallback(async () => {
    await dispatch(filterActions.resetFilters());
    await dispatch(searchActions.resetSearch());
    await dispatch(tableActions.resetTable());
    await dispatch(selectedCompaniesActions.reset());

    queryClient.clear();
    resetAllSubject?.next(true);

    query.refetch();
  }, [dispatch, query, queryClient]);

  const softReset = useCallback(() => {
    dispatch(filterActions.resetFilters());
    dispatch(searchActions.resetSearch());
    dispatch(tableActions.resetTable());
    dispatch(selectedCompaniesActions.reset());
  }, [dispatch]);

  const cancelMidtier = useCallback(() => {
    queryClient.cancelQueries({ queryKey: ['table-midtier', 'table-midtier-count'] });
  }, [queryClient]);

  return {
    resetAll,
    softReset,
    cancelMidtier,
  };
};
