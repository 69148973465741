import React, { FC, useState, useMemo } from 'react';
import { range, times } from 'lodash';
import { useDispatch } from 'react-redux';

import Skeleton from '@mui/material/Skeleton';
import { TablePagination as Pagination } from '@mui/material';

import { ICompaniesListSnippet, ISavedSearchSnippet } from '@/types';
import { SavedSearchCard } from '@/Components/Shared/SavedSearch/SavedSearchCard';
import { CompaniesListCard } from '@/Components/Shared/CompaniesList/CompaniesListCard';
import {
  DEFAULT_CARDS_PER_PAGE,
  LIST_TYPE,
  ListType,
  actions as searchListsTablesActions,
} from '@/slices/search-lists-tables';

import { useShallowSelector } from '@/hooks/use-shallow-selector';

interface ListSectionProps {
  title: string;
  error?: string;
  emptySectionText?: string;
  isLoading: boolean;
  allLists: ISavedSearchSnippet[] | ICompaniesListSnippet[];
  userLists?: ISavedSearchSnippet[] | ICompaniesListSnippet[];
  skeletonLoaderHeight?: number;
  listType: ListType;
}

export const ListSection: FC<ListSectionProps> = ({
  title,
  error,
  isLoading,
  allLists,
  userLists,
  emptySectionText,
  skeletonLoaderHeight = 140,
  listType,
}) => {
  const dispatch = useDispatch();
  const cardsPerPage = useShallowSelector((state) => state.searchListsTables[listType].cardsPerPage);
  const [page, setPage] = useState(0);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(searchListsTablesActions.setCardsPerPage({ table: listType, value: parseInt(event.target.value, 10) }));
    setPage(0);
  };

  const visibleCards = useMemo(
    () => allLists?.slice(page * cardsPerPage, page * cardsPerPage + cardsPerPage),
    [allLists, page, cardsPerPage],
  );

  const renderCards = () => {
    if (listType === LIST_TYPE.allSavedSearches) {
      return visibleCards.map((savedSearch) => (
        <SavedSearchCard
          key={savedSearch.list_id}
          savedSearch={savedSearch}
          isUserSearch={userLists?.includes(savedSearch)}
        />
      ));
    }

    if (listType === LIST_TYPE.userCompaniesLists || listType === LIST_TYPE.sharedWithUserCompaniesLists) {
      return visibleCards.map((companiesList) => (
        <CompaniesListCard
          key={companiesList.list_id}
          companiesList={companiesList}
          isUserList={'shared_with_me' in companiesList && !companiesList.shared_with_me}
        />
      ));
    }
  };

  const showPagination = allLists.length > DEFAULT_CARDS_PER_PAGE;

  return (
    <div className="flex flex-col mt-8">
      <span className="text-[#2E3F4C] font-bold border-b-2 border-b-[#2E3F4C] w-max">{title}</span>

      <div className="grid gap-4 grid-cols-3 mt-4">
        {error ? <span className="text-[#666] italic text-sm col-span-3">{error}</span> : null}

        {isLoading
          ? range(3).map((idx) => (
              <Skeleton
                key={idx}
                variant="rounded"
                className="bg-[#0000000f] rounded-2xl"
                height={skeletonLoaderHeight}
              />
            ))
          : renderCards()}

        {!isLoading && allLists.length === 0 && !error && !!emptySectionText ? (
          <span className="text-[#666] italic text-sm col-span-3">{emptySectionText}</span>
        ) : null}
      </div>
      {showPagination ? (
        <Pagination
          rowsPerPageOptions={times(3, (index) => (index + 1) * DEFAULT_CARDS_PER_PAGE)}
          component="div"
          count={allLists?.length ?? 0}
          rowsPerPage={cardsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Show:"
          className="flex mt-2"
        />
      ) : null}
    </div>
  );
};
