import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import { GridSortDirection } from '@mui/x-data-grid-pro';

import SearchPage from '../search-page';
import { actions as searchActions, QueryType } from '@/slices/search';
import { actions as navigationTabsActions } from '@/slices/navigation-tabs';
import { actions as filterActions } from '@/slices/filters';
import { actions as tableActions } from '@/slices/table';
import { actions as customScreenActions } from '@/slices/custom-screen';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useScreener } from '@/hooks/use-screen';
import { ERROR_PAGE_PROPS, HISTORY_KEYS, LOCAL_STORAGE_KEYS, SEARCH_EVENTS } from '@/constants';
import { ISavedSearch } from '@/types';
import { SavedSearchControls } from '@/Components/Shared/SavedSearch/SavedSearchControls';
import { useQuerySavedSearchById } from '@/hooks/queries/saved-search/use-query-saved-search-by-id';
import { useQuerySavedSearches } from '@/hooks/queries/saved-search/use-query-saved-searches';
import { getUuidFromStringEnd } from '@/Utils/url-utils';
import { useSearchCompanies } from '@/hooks/use-search-companies';
import { useHasChangedSearchSettings } from '@/hooks/saved-search/use-has-changed-search-settings';

const SavedSearch = () => {
  const dispatch = useDispatch();
  const { id: idWithTitle } = useParams<{ id: string }>();
  const id = getUuidFromStringEnd(idWithTitle);
  const { softReset, cancelMidtier } = useScreener();
  const query = useQuerySavedSearchById(id);
  const { data: savedSearches } = useQuerySavedSearches();
  const searchCompanies = useSearchCompanies();
  const bainIds = useShallowSelector((state) => state.search.bainIds.data);
  const hasSearchChanged = useHasChangedSearchSettings(query.data);

  const [savedSearchesSharedWithUser, setSavedSearchesSharedWithUser] = useLocalStorage<ISavedSearch[]>(
    LOCAL_STORAGE_KEYS.SEARCHES_SHARED_WITH_USER,
    [],
  );

  useEffect(() => {
    cancelMidtier();
    dispatch(searchActions.setWillBeProceed(true));

    return () => {
      softReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!query.data || !query.isFetched) return;

    const isSmartSearch = query.data?.query_type === QueryType.SMART;

    dispatch(searchActions.setQueryType(query.data?.query_type));
    dispatch(customScreenActions.setCurrency(query.data?.currency));

    if (query.data?.filters) {
      dispatch(filterActions.setFilters(query.data?.filters));
    }

    if (query.data?.company_search) {
      dispatch(searchActions.setSearchText(query.data?.company_search));
    }

    if (query.data?.search_query && !isSmartSearch) {
      dispatch(searchActions.setSearchQuery(query.data?.search_query));
    }

    if (query.data?.visible_columns) {
      dispatch(tableActions.setVisibleColumns(query.data?.visible_columns));
    }

    if (query.data?.sort_order && query.data?.sort_key) {
      dispatch(
        tableActions.setSortModel([
          {
            sort: query.data?.sort_order as GridSortDirection,
            field: query.data?.sort_key,
          },
        ]),
      );
    }

    if (query.data?.columns_order) {
      dispatch(tableActions.setCustomColumnsOrder(query.data?.columns_order));
    }

    searchCompanies(
      {
        searchText: isSmartSearch ? query.data?.company_search : query.data?.search_query,
        queryType: query.data?.query_type,
        eventName: SEARCH_EVENTS.HISTORY,
        isUniq: query.data?.is_unique_company,
      },
      { concatPrevQuery: false },
    );

    dispatch(searchActions.setWillBeProceed(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query.isFetched, query.data]);

  useEffect(() => {
    dispatch(searchActions.setHistoryKey(HISTORY_KEYS.SCREENERS_HISTORY));
  }, [dispatch]);

  useEffect(() => {
    if (!query.data || query.isFetching || !savedSearches) return;
    dispatch(navigationTabsActions.setCurrentSavedSearch(query.data));

    if (savedSearches.some((list) => list.list_id === query.data.list_id)) return;

    const alreadySharedWithUserItem = savedSearchesSharedWithUser.find(
      (sharedSavedSearch) => sharedSavedSearch.list_id === query.data.list_id,
    );

    if (alreadySharedWithUserItem) {
      setSavedSearchesSharedWithUser(
        savedSearchesSharedWithUser.map((sharedSavedSearch) =>
          sharedSavedSearch.list_id === query.data.list_id ? query.data : sharedSavedSearch,
        ),
      );

      return;
    }

    setSavedSearchesSharedWithUser([...savedSearchesSharedWithUser, query.data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query.data, query.isFetching, savedSearches]);

  return (
    <SearchPage
      isError={query.isError}
      errorPage={ERROR_PAGE_PROPS.SAVED_SEARCH_NOT_FOUND}
      bainIds={bainIds}
      controls={
        <SavedSearchControls
          isLoading={query.isFetching}
          savedSearch={query.data}
          hasSearchChanged={hasSearchChanged}
        />
      }
    />
  );
};

export default SavedSearch;
