import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isNil } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { actions as searchActions, QueryType } from '../slices/search';
import { actions as filterActions } from '../slices/filters';
import { actions as selectedCompaniesActions } from '../slices/selected-companies';
import { useSearchHistory } from './use-save-search-query';
import { useShallowSelector } from './use-shallow-selector';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { normalizeQueryString, sanitizeQueryString, guessUserFriendlyLuceneError } from '@/Utils/pegjs/astTreeUtils';
import { searchSubject } from '@/Utils/subjects';
import { Nullish } from '@/types';
import { DEFAULT_ERROR_MESSAGE, SEARCH_EVENTS } from '@/constants';
import { postSmartSearch } from '@/services/api';

export interface SearchParams {
  searchText: string;
  isUniq?: boolean;
  isLucene?: boolean;
  filters?: unknown;
  queryType: QueryType;
  eventName?: string;
}

const mergeQueries = (prevQuery: string, nextQuery: string) =>
  !prevQuery ? nextQuery : `${prevQuery} AND ${nextQuery}`;

export const useSearchCompanies = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { saveQuery } = useSearchHistory();
  const searchQuery = useShallowSelector((state) => state.search.searchQuery);
  const searchTextValue = useShallowSelector((state) => state.search.searchText);
  const searchQueryTree = useShallowSelector((state) => state.search.searchQueryTree);
  const queryType = useShallowSelector((state) => state.search.queryType);
  const hasLuceneGrammarError = useShallowSelector((state) => state.search.hasLuceneGrammarError);
  const shouldSaveQuery = useRef<Nullish<{ searchText: string; isUniq?: boolean }>>();

  const isKeywordQuery = queryType === QueryType.KEYWORD;
  const isSmartQuery = queryType === QueryType.SMART;

  useEffect(() => {
    if (shouldSaveQuery.current) {
      saveQuery({
        searchText: isSmartQuery ? searchTextValue : shouldSaveQuery.current.searchText,
        isLucene: isKeywordQuery,
        isUniq: shouldSaveQuery.current.isUniq,
        queryType,
      });
      shouldSaveQuery.current = null;
    }
  }, [searchQueryTree]);

  useEffect(() => {
    if (hasLuceneGrammarError) {
      dispatch(searchActions.setHasLuceneGrammarError(false));
    }
  }, [searchTextValue]);

  const elasticSearch = useCallback(
    (searchValue: string, concatPrevQuery: boolean) => {
      const noSearchValueSearchText = !searchValue ? searchQuery : mergeQueries(searchQuery, searchValue);

      const searchText = !(concatPrevQuery && !!searchQueryTree) ? searchValue : noSearchValueSearchText;

      try {
        const normalizedQuery = normalizeQueryString(sanitizeQueryString(searchText));

        searchSubject.next({ text: normalizedQuery });

        dispatch(searchActions.setSearchQuery(normalizedQuery));

        if (normalizedQuery) {
          shouldSaveQuery.current = {
            searchText: isSmartQuery ? searchTextValue : normalizedQuery,
          };
        }
      } catch (error) {
        dispatch(searchActions.setHasLuceneGrammarError(true));

        const luceneQueryError = guessUserFriendlyLuceneError(searchText);
        const isDefaultMessage = luceneQueryError === DEFAULT_ERROR_MESSAGE;

        enqueueSnackbar(
          <NotificationMessage
            title={isDefaultMessage ? DEFAULT_ERROR_MESSAGE : 'Your query is not valid'}
            description={!isDefaultMessage ? luceneQueryError : undefined}
          />,
          { variant: 'error' },
        );
      }
    },
    [dispatch, enqueueSnackbar, isSmartQuery, searchQuery, searchQueryTree, searchTextValue],
  );

  const regularSearch = useCallback(
    (searchValue: string, params: SearchParams) => {
      searchSubject.next({
        text: searchValue,
        isUniq: params.isUniq,
      });

      dispatch(searchActions.setSearchText(searchValue));
      dispatch(searchActions.setSearchQuery(searchValue));
      dispatch(searchActions.setIsUniqueCompanySearch(!!params.isUniq));

      saveQuery({
        searchText: searchValue,
        isLucene: false,
        isUniq: params.isUniq,
        queryType: params.queryType,
      });
      shouldSaveQuery.current = null;
    },
    [dispatch, saveQuery, queryType],
  );

  const { mutateAsync: smartSearchCall } = useMutation((text: string) => postSmartSearch({ text }), {
    onSuccess(res) {
      elasticSearch(res.suggested_query, false);
    },
    onError() {
      enqueueSnackbar(
        <NotificationMessage title="Failed to proceed smart search. Please try again or contact support." />,
        { variant: 'error' },
      );
      dispatch(searchActions.setIsLoading(false));
    },
  });

  const smartSearch = useCallback(
    async (searchValue: string) => {
      if (searchValue) {
        dispatch(searchActions.setIsLoading(true));
        await smartSearchCall(searchValue);
      }

      searchSubject.next({});
    },
    [dispatch, smartSearchCall],
  );

  const eventAvailableSearch = useCallback(
    (data: SearchParams, options, searchValue) => {
      const isHistory = data.eventName === SEARCH_EVENTS.HISTORY;
      const isRemoveQuerySmartSearchEvent = data.eventName === SEARCH_EVENTS.REMOVE_QUERY_SMART_SEARCH;
      const isRegularHistorySearch = isHistory && data.queryType === QueryType.MATCH;
      const isSmartHistorySearch = isHistory && data.queryType === QueryType.SMART;

      if (isRegularHistorySearch) {
        regularSearch(searchValue, data);
      } else if (isSmartHistorySearch) {
        smartSearch(searchValue);
      } else if (isRemoveQuerySmartSearchEvent) {
        elasticSearch('', options.concatPrevQuery);
      } else {
        elasticSearch(searchValue, options.concatPrevQuery);
      }
    },
    [elasticSearch, smartSearch, regularSearch],
  );

  const search = useCallback(
    (
      data: SearchParams,
      options = {
        concatPrevQuery: true,
        useStateFilters: true,
      },
    ) => {
      const searchValue = data.searchText.trim();

      dispatch(selectedCompaniesActions.reset());
      dispatch(searchActions.setIsUniqueCompanySearch(false));

      if (!options.useStateFilters && !isNil(data?.filters)) {
        dispatch(filterActions.setFilters(data?.filters));
      }

      if (data?.eventName && Object.values(SEARCH_EVENTS).includes(data.eventName)) {
        eventAvailableSearch(data, options, searchValue);

        return;
      }

      if ((!data.queryType && isKeywordQuery) || data.queryType === QueryType.KEYWORD) {
        elasticSearch(searchValue, options.concatPrevQuery);
      } else if ((!data?.queryType && isSmartQuery) || data?.queryType === QueryType.SMART) {
        smartSearch(searchValue);
      } else {
        regularSearch(searchValue, data);
      }
    },
    [isKeywordQuery, isSmartQuery, dispatch, eventAvailableSearch, elasticSearch, smartSearch, regularSearch],
  );

  return search;
};
