import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Tree } from 'antd';
import classnames from 'classnames';
import Tooltip from '@mui/material/Tooltip';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/filters';
import { prepareFilterTree, getParentNode, getParentKey } from '@/Utils/filters';

const TreeCheckbox = ({ checked, halfChecked }) => {
  if (checked)
    return (
      <CheckBoxIcon
        className="mr-2 fill-[#0288d1]"
        data-testid="checkbox-checked"
      />
    );

  if (halfChecked)
    return (
      <IndeterminateCheckBoxIcon
        className="mr-2 fill-[#0288d1]"
        data-testid="checkbox-half-checked"
      />
    );

  return (
    <CheckBoxOutlineBlankIcon
      className="mr-2 fill-[#DDD]"
      data-testid="checkbox-not-checked"
    />
  );
};

export const FilterTree = ({ itemId, data, isFetching }) => {
  const dispatch = useDispatch();
  const nodes = useMemo(() => prepareFilterTree({ data: data ?? [], Icon: TreeCheckbox }), [data]);
  const checkedKeys = useShallowSelector((state) => state.filters.treeFilters[itemId] ?? []);

  const handleTreeChange = (checked, event) => {
    const filteredHalfCheckedKeys = event.halfCheckedKeys
      .filter((halfCheckedKey) => JSON.parse(halfCheckedKey).value !== 'All')
      .map((halfCheckedKey) => {
        const parsedHalfCheckedKey = JSON.parse(halfCheckedKey);

        return JSON.stringify({
          ...parsedHalfCheckedKey,
          halfChecked: true,
        });
      });

    const extendedCheckedList = checked.map((item) => {
      const parsedItem = JSON.parse(item);
      const parentNode = getParentNode(item, nodes);

      return JSON.stringify({
        ...parsedItem,
        hasParentNodeChecked: checked.includes(parentNode?.key),
        halfChecked: false,
      });
    });

    dispatch(
      actions.setFilterTree({
        id: itemId,
        data: [...extendedCheckedList, ...filteredHalfCheckedKeys],
      }),
    );
  };

  const checkedKeysWithoutHalfChecked = useMemo(
    () => checkedKeys.filter((checkedKey) => !JSON.parse(checkedKey).halfChecked),
    [checkedKeys],
  );

  const [searchValue, setSearchValue] = useState('');
  const [expandedKeys, setExpandedKeys] = useState([nodes[0].key]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const dataFlatList = useMemo(() => {
    const dataList = [];

    const generateList = (nodesData) => {
      for (const node of nodesData) {
        dataList.push(node);

        if (node.children) {
          generateList(node.children);
        }
      }
    };

    generateList(nodes);

    return dataList;
  }, [nodes]);

  const handleSearchValueChange = (event) => {
    const { value } = event.target;

    if (value === '') {
      setExpandedKeys([nodes[0].key]);
      setSearchValue(value);
      setAutoExpandParent(true);

      return;
    }

    const newExpandedKeys = dataFlatList
      .map((node) => {
        if (node.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return getParentKey(node.key, nodes);
        }

        return null;
      })
      .filter((item, index, self) => item && self.indexOf(item) === index);

    setExpandedKeys(newExpandedKeys);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setExpandedKeys([nodes[0].key]);
  };

  const treeData = useMemo(() => {
    const loop = (nodesData) =>
      nodesData.map((node) => {
        const stringTitle = node.title;
        const stringTitleLowerCase = node.title.toLowerCase();
        const index = stringTitleLowerCase.indexOf(searchValue.toLowerCase());
        const beforeHighlightedText = stringTitle.substring(0, index);
        const highlightedText = stringTitle.substring(index, index + searchValue.length);
        const afterHighlightedText = stringTitle.slice(index + searchValue.length);

        const title =
          index > -1 ? (
            <span>
              {beforeHighlightedText}
              <span className="text-[#cc0000]">{highlightedText}</span>
              {afterHighlightedText}
            </span>
          ) : (
            <span data-testid={'test'}>{stringTitle}</span>
          );

        if (node.children) {
          return { ...node, title, children: loop(node.children) };
        }

        return {
          ...node,
          title,
        };
      });

    return loop(nodes);
  }, [nodes, searchValue]);

  return isFetching ? (
    <Skeleton
      variant="rounded"
      className="bg-[#0000000f] mr-4 my-4"
      data-testid="skeleton-loader"
    />
  ) : (
    <>
      <TextField
        style={{ marginBottom: 8, marginTop: 8 }}
        size="small"
        fullWidth
        placeholder="Search..."
        onChange={handleSearchValueChange}
        value={searchValue}
        InputProps={{
          endAdornment: searchValue?.length ? (
            <IconButton
              onClick={handleClearSearch}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : undefined,
        }}
      />

      <Tree
        checkable
        showIcon
        treeData={treeData}
        selectable={false}
        checkedKeys={checkedKeysWithoutHalfChecked}
        onCheck={handleTreeChange}
        titleRender={(node) =>
          node.description ? (
            <Tooltip
              title={node.description}
              placement="top"
            >
              <span>{node.title}</span>
            </Tooltip>
          ) : (
            node.title
          )
        }
        switcherIcon={(props) => (
          <KeyboardArrowDownIcon
            className={classnames('fill-[#979797] text-xl mt-0.5 transition', {
              'rotate-180': props.expanded,
            })}
          />
        )}
        className="mt-2"
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
      />
    </>
  );
};
