import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useShallowSelector } from '../../use-shallow-selector';
import { getCompanySubsidiaries } from '@/services/api/company-profile';

export const useQueryCompanySubsidiaries = (bainId: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(['company-subsidiaries', bainId, currency], () => getCompanySubsidiaries(bainId, currency), {
    enabled: isAuth && !!bainId,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
    onError: () =>
      enqueueSnackbar('Failed to fetch company subsidiaries data. Please try again or contact support.', {
        variant: 'error',
      }),
  });
};
