import React, { useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import _, { trim } from 'lodash';

import { actions } from '@/slices/table';
import { useQueryColumnMapper } from '@/hooks/queries/column-mapper/use-query-column-mapper';
import { useQueryTable } from '@/hooks/queries/use-query-table';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { useQuerySavedSearches } from '@/hooks/queries/saved-search/use-query-saved-searches';
import { ROUTES } from '@/constants';
import { useScreener } from '@/hooks/use-screen';
import { searchSubject } from '@/Utils/subjects';
import { actions as searchActions } from '@/slices/search';

interface IProps {
  children: ReactNode;
}

export const Main = ({ children }: IProps) => {
  useQueryCompaniesLists();
  useQuerySavedSearches();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const columnMapperQuery = useQueryColumnMapper();
  const location = useLocation();
  const query = useQueryTable();
  const { resetAll } = useScreener();
  const queryTable = useQueryTable();

  useEffect(() => {
    const columnMapper = columnMapperQuery.data ?? [];

    if (!_.isEmpty(columnMapper)) {
      const defaultColumns = columnMapper.filter((item) => item.DEFAULT_COLUMN).map((item) => item['Backend Name']);

      dispatch(actions.setDefaultColumns(defaultColumns));

      const isSavedSearchPage = location?.pathname?.startsWith(`${ROUTES.SAVED_SEARCH}/`);

      if (!isSavedSearchPage) {
        dispatch(actions.setVisibleColumns(['__check__'].concat(defaultColumns)));
      }
    }
  }, [columnMapperQuery.data, dispatch, location?.pathname]);

  useEffect(() => {
    query.fetch({ fetchCount: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['filter-tree'],
    });
  }, [location, queryClient]);

  useEffect(() => {
    const subscription = searchSubject?.subscribe(async ({ text, isUniq = false }) => {
      if (text) {
        await dispatch(
          // @ts-ignore
          searchActions.fetchBainIds({
            searchText: trim(text),
            unique_company: isUniq,
          }),
        );
      }

      queryTable.fetch({ fetchCount: true });
    });

    return () => {
      resetAll();
      subscription?.unsubscribe();
      searchSubject?.next({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return <main data-testid="appComponent">{children}</main>;
};
