import React from 'react';
import { CompanyProfileWidget } from '../../common/CompanyProfileWidget/CompanyProfileWidget';
import { IBuyerSummary } from '@/types';

interface MnABuyerSummaryProps {
  data: IBuyerSummary;
}

const MnABuyerSummary = ({ data }: MnABuyerSummaryProps) => {
  return (
    <div className="grid grid-cols-3	gap-4">
      <CompanyProfileWidget
        title={{ text: 'Portfolio', id: 'portfolio-header' }}
        entries={[
          {
            label: 'Current count',
            value: data.current_portfolio_count ? data.current_portfolio_count.toFixed(0) : undefined,
          },
          {
            label: 'Exited count',
            value: data.exited_portfolio_count ? data.exited_portfolio_count.toFixed(2) : undefined,
          },
          {
            label: 'Current average age',
            value: data.current_portfolio_avg_age ? `${data.current_portfolio_avg_age.toFixed(2)} YRS` : undefined,
          },
          {
            label: 'Holding period average',
            value: data.holding_period_avg ? data.holding_period_avg.toFixed(2) : undefined,
          },
          {
            label: 'Holding period standard',
            value: data.holding_period_std ? data.holding_period_std.toFixed(2) : undefined,
          },
        ]}
      />
      <CompanyProfileWidget
        title={{ text: 'Aquisitions', id: 'aquisitions-header' }}
        entries={[
          {
            label: 'Last year',
            value: data.acquisitions_last_1yr ? data.acquisitions_last_1yr.toFixed(0) : undefined,
          },
          {
            label: 'Last 3 years',
            value: data.acquisitions_last_3yr ? data.acquisitions_last_3yr.toFixed(0) : undefined,
          },
          {
            label: 'Last 5 years',
            value: data.acquisitions_last_5yr ? data.acquisitions_last_5yr.toFixed(0) : undefined,
          },
        ]}
      />
      <CompanyProfileWidget
        title={{ text: 'Divestitures', id: 'divestitures-header' }}
        entries={[
          {
            label: 'Last year',
            value: data.sales_last_1yr ? data.sales_last_1yr.toFixed(0) : undefined,
          },
          {
            label: 'Last 3 years',
            value: data.sales_last_3yr ? data.sales_last_3yr.toFixed(0) : undefined,
          },
          {
            label: 'Last 5 years',
            value: data.sales_last_5yr ? data.sales_last_5yr.toFixed(0) : undefined,
          },
        ]}
      />
    </div>
  );
};

export default MnABuyerSummary;
