import React from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import { IconArrowLeft } from '@/Components/Icons/IconArrowLeft';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useQuerySimilarCompaniesSearchById } from '@/hooks/queries/similar-companies/use-query-similar-companies-search-by-id';
import { IconLoading } from '@/Components/Icons/IconLoading';
import { actions as similarCompaniesActions } from '@/slices/similar-companies';

const INITIAL_PROGRESS = 3;

export const SIMILAR_COMPANIES_LOADING_DIALOG_TITLE = 'Finding similar companies..';
export const SIMILAR_COMPANIES_LOADING_DIALOG_ABANDON_BUTTON_TEXT = 'Abandon search';

export const SimilarCompaniesLoadingDialog = () => {
  const dispatch = useDispatch();
  const { similarCompaniesSearchProgress, similarCompaniesSearchId } = useShallowSelector(
    (state) => state.similarCompaniesSearch,
  );

  useQuerySimilarCompaniesSearchById();

  const abandonSearch = () => {
    dispatch(similarCompaniesActions.resetState());
  };

  return (
    <Dialog
      open={!!similarCompaniesSearchId}
      classes={{ paper: 'w-[500px]' }}
    >
      <LinearProgress
        variant="determinate"
        value={Math.max(INITIAL_PROGRESS, similarCompaniesSearchProgress)}
        sx={{
          backgroundColor: 'white',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#c00',
          },
        }}
      />
      <div className="mt-6 mb-2 flex justify-center">
        <IconLoading />
      </div>
      <DialogTitle classes={{ root: 'font-bold text-base text-center' }}>
        {SIMILAR_COMPANIES_LOADING_DIALOG_TITLE}
      </DialogTitle>

      <DialogContent className="pt-0">
        <DialogContentText className="text-sm">
          <div className="text-center">We&apos;ll find companies similar to what you&apos;ve selected based on</div>
          <div className="text-center">
            names & descriptions, this may take up to <strong>40</strong> seconds...
          </div>
          <div className="mt-8 mb-2 flex justify-center">
            <Button
              className="text-[#2E3F4C]"
              onClick={abandonSearch}
              startIcon={<IconArrowLeft />}
            >
              {SIMILAR_COMPANIES_LOADING_DIALOG_ABANDON_BUTTON_TEXT}
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
