import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { SkeletonLoader } from '../../common/SkeletonLoader';
import { useFinancialDataSource, DATA_SOURCE_USER_FRIENDLY_NAME } from './use-data-source';
import { MultiyearFinTable } from './MultiyearFinTable';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions as customScreenActions } from '@/slices/custom-screen';
import { CURRENCIES } from '@/constants';
import { useQueryCompanyMultiyearFinancials } from '@/hooks/queries/company-profile/use-query-company-multiyear-financials';

const PERIOD_TYPES = {
  ANNUAL: 'Annual',
  QUARTERLY: 'Quarterly',
} as const;

type PeriodTypesKeys = keyof typeof PERIOD_TYPES;

export type PeriodTypes = typeof PERIOD_TYPES[PeriodTypesKeys];

export const DATA_TYPES = {
  ANNUAL_IS: 'Annual_IS',
  ANNUAL_BS: 'Annual_BS',
  ANNUAL_OTHER: 'Annual_OTHER',
  QUARTERLY_IS: 'Quarterly_IS',
  QUARTERLY_BS: 'Quarterly_BS',
  QUARTERLY_OTHER: 'Quarterly_OTHER',
} as const;

type DataTypesKeys = keyof typeof DATA_TYPES;

export type DataTypes = typeof DATA_TYPES[DataTypesKeys];

const ANNUAL_DATA_TABLES = [DATA_TYPES.ANNUAL_IS, DATA_TYPES.ANNUAL_BS, DATA_TYPES.ANNUAL_OTHER];
const QUARTERLY_DATA_TABLES = [DATA_TYPES.QUARTERLY_IS, DATA_TYPES.QUARTERLY_BS, DATA_TYPES.QUARTERLY_OTHER];

interface MultiyearFinancialsTabProps {
  bainId: string;
  revenueMetadata?: string;
}

export const MultiyearFinancialsTab = ({ bainId, revenueMetadata }: MultiyearFinancialsTabProps) => {
  const dispatch = useDispatch();
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const [financialPeriod, setFinancialPeriod] = useState<PeriodTypes>(PERIOD_TYPES.ANNUAL);
  const { data: multiYear, isLoading: isMultiYearLoading } = useQueryCompanyMultiyearFinancials(bainId);

  const { currentDataSource, dataSourcesAvailable, handleDataSourceChange } = useFinancialDataSource(
    multiYear,
    revenueMetadata,
  );

  const handleFinancialPeriodChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    newFinancialPeriod: PeriodTypes,
  ) => {
    if (newFinancialPeriod !== null) {
      setFinancialPeriod(newFinancialPeriod);
    }
  };

  const handleCurrencyChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, newCurrency: string) => {
    if (newCurrency !== null) {
      dispatch(customScreenActions.setCurrency(newCurrency));
    }
  };

  if (isMultiYearLoading) {
    return <SkeletonLoader />;
  }

  if (isEmpty(multiYear)) {
    return <div>No financials data available</div>;
  }

  const tables = financialPeriod === PERIOD_TYPES.ANNUAL ? ANNUAL_DATA_TABLES : QUARTERLY_DATA_TABLES;
  const financialPeriodsAvailable = Object.keys(multiYear) as DataTypes[];
  //@ts-ignore
  const hasQuarterlyData = financialPeriodsAvailable.some((item) => QUARTERLY_DATA_TABLES.includes(item));

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <Typography className="self-center">
          The following data has been calculated using Fiscal year (taking precedence over Calendar year) where
          applicable.
        </Typography>

        <div className="flex gap-3 items-center">
          {currentDataSource && (
            <div>
              <FormControl
                sx={{ minWidth: 150 }}
                fullWidth
                size="small"
              >
                <InputLabel id="fintab-data-source-label">Data Source</InputLabel>
                <Select
                  labelId="fintab-data-source-label"
                  id="fintab-data-source"
                  value={currentDataSource}
                  label="Data Source"
                  onChange={handleDataSourceChange}
                  disabled={!dataSourcesAvailable || dataSourcesAvailable.length < 2}
                >
                  {dataSourcesAvailable.map((dataSource) => (
                    <MenuItem
                      value={dataSource}
                      key={dataSource}
                    >
                      {DATA_SOURCE_USER_FRIENDLY_NAME[dataSource] ?? dataSource}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div>
            <Stack
              direction="row"
              spacing={2}
            >
              <ToggleButtonGroup
                value={financialPeriod}
                exclusive
                onChange={handleFinancialPeriodChange}
                aria-label="period"
                size="small"
              >
                <ToggleButton
                  value={PERIOD_TYPES.ANNUAL}
                  aria-label={`left ${PERIOD_TYPES.ANNUAL}`}
                  sx={{ padding: '6px', paddingX: '12px' }}
                  disabled={financialPeriod === PERIOD_TYPES.ANNUAL}
                >
                  Annual
                </ToggleButton>
                <ToggleButton
                  value={PERIOD_TYPES.QUARTERLY}
                  aria-label={`right ${PERIOD_TYPES.QUARTERLY}`}
                  sx={{ padding: '6px', paddingX: '12px' }}
                  disabled={financialPeriod === PERIOD_TYPES.QUARTERLY || !hasQuarterlyData}
                >
                  Quarterly
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </div>
          <div>
            <Stack
              direction="row"
              spacing={2}
            >
              <ToggleButtonGroup
                value={currency}
                exclusive
                onChange={handleCurrencyChange}
                aria-label="currency"
                size="small"
              >
                {Object.entries(CURRENCIES).map(([key, val]) => (
                  <ToggleButton
                    key={key}
                    value={key}
                    aria-label={key}
                    sx={{ padding: '6px', paddingX: '14px' }}
                    disabled={currency === key}
                  >
                    {val.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
          </div>
        </div>
      </div>

      {tables.map((tableName) => {
        const tableData = multiYear[tableName];

        if (!tableData) return null;

        return (
          <MultiyearFinTable
            key={tableName}
            tableName={tableName}
            tableData={tableData}
            currency={currency}
            currentDataSource={currentDataSource}
          />
        );
      })}
    </div>
  );
  // }
};
