import { useIsFetching } from '@tanstack/react-query';

import { useParams } from 'react-router';
import { useShallowSelector } from '../use-shallow-selector';
import { getUuidFromStringEnd } from '@/Utils/url-utils';

export const useTableLoading = () => {
  const { id: idWithTitle } = useParams<{ id: string }>();
  const id = getUuidFromStringEnd(idWithTitle || '');
  const isCompaniesListLoading = useIsFetching({ queryKey: ['companies-list', id] }) > 0;
  const isSavedSearchLoading = useIsFetching({ queryKey: ['saved-search', id] }) > 0;
  const isTableLoading = useIsFetching({ queryKey: ['table-midtier'] }) > 0;
  const isTableCountLoading = useIsFetching({ queryKey: ['table-midtier-count'] }) > 0;
  const isColumnMapperLoading = useIsFetching({ queryKey: ['column-mapper'] }) > 0;
  const isBainIdsLoading = useShallowSelector((state) => state.search.bainIds.isLoading);
  const willSearchBeProceed = useShallowSelector((state) => state.search.willBeProceed);

  const isLoading =
    isTableLoading ||
    isTableCountLoading ||
    isCompaniesListLoading ||
    isSavedSearchLoading ||
    isColumnMapperLoading ||
    isBainIdsLoading ||
    willSearchBeProceed;

  return isLoading;
};
