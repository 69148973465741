import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { PredefinedCompaniesTextField } from './PredefinedCompaniesTextField';

export const CREATE_SHARED_ELEMENT_DEFAULT_VALUES = {
  title: '',
  description: '',
};

export const CreateUpdateSharedElementDialog = ({
  register,
  formState,
  isOpen,
  handleClose,
  title,
  subtitle,
  buttonLabel,
  handleButtonClick,
  isLoading,
  disabled,
  elementData,
  predefinedCompanies,
}) => (
  <Dialog
    open={isOpen}
    onClose={handleClose}
    classes={{ paper: 'w-1/3 rounded-lg p-4' }}
  >
    <div className="flex justify-between items-center pb-4">
      <DialogTitle className="flex text-base font-bold p-0 text-[#484848]">{title}</DialogTitle>

      <CloseIcon
        fontSize="small"
        className="cursor-pointer fill-[#484848]"
        onClick={handleClose}
      />
    </div>

    <DialogContent className="text-sm p-0">
      {subtitle ? <span>{subtitle}</span> : null}

      <form className="flex flex-col gap-4 pt-4">
        <div className="flex flex-col gap-2">
          <span className="font-semibold">Name</span>
          <TextField
            placeholder="Pick something topical..."
            {...register('title', { required: true })}
            defaultValue={elementData?.title ?? ''}
            variant="outlined"
            size="small"
            disabled={disabled}
            inputProps={{
              'data-testid': 'data-name',
            }}
            error={!!formState?.errors?.title}
          />
        </div>

        <div className="flex flex-col gap-2">
          <span className="font-semibold">Description</span>
          <TextField
            placeholder="Optional"
            {...register('description', { required: false })}
            defaultValue={elementData?.description ?? ''}
            variant="outlined"
            size="small"
            multiline
            rows={3}
            disabled={disabled}
            inputProps={{
              'data-testid': 'data-description',
            }}
          />
        </div>

        {predefinedCompanies ? (
          <PredefinedCompaniesTextField
            register={register}
            disabled={disabled}
            predefinedCompanies={predefinedCompanies}
            formState={formState}
          />
        ) : null}
      </form>

      {elementData ? (
        <div className="flex flex-col mt-4">
          <span className="font-bold mb-2">Date created</span>
          <span>{dayjs(elementData?.created_at).format('DD MMM YYYY')}</span>
          <span className="font-bold my-2">Last updated</span>
          <span>{dayjs(elementData?.updated_at).format('DD MMM YYYY')}</span>
        </div>
      ) : null}

      <div className="flex justify-end mt-4">
        <Button
          variant="contained"
          className={classnames({
            'bg-cred-50': !isLoading,
          })}
          data-testid="create-button"
          onClick={handleButtonClick}
          endIcon={
            <CircularProgress
              className={classnames('text-white transition', {
                hidden: !isLoading,
              })}
              size={20}
            />
          }
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);
