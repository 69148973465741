import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { isNil } from 'lodash';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { ExportDropdown } from './CompanyResearch/ExportDropdown';
import { SelectCompaniesControls } from '@/Components/Shared/CompaniesList/SelectCompaniesControls';

import './ExportComponent.css';

const ExportComponent = ({
  bainIds,
  withoutCount = false,
  isCompanyProfile = false,
  companyProfileId,
  isCompaniesList = false,
}) => {
  const exportDropdownRef = useRef();

  const { revision: revisionUrlParam } = useParams();

  const revision = isNaN(Number(revisionUrlParam)) ? undefined : Number(revisionUrlParam);

  return (
    <div
      className="ml-auto flex justify-end h-[36px]"
      data-testid="exportComponent"
    >
      {!withoutCount && <SelectCompaniesControls readOnlyMode={isCompaniesList && !isNil(revision)} />}

      <Box ref={exportDropdownRef}>
        <ExportDropdown
          skipComparison={withoutCount}
          bainIds={bainIds}
          ref={exportDropdownRef}
          isCompanyProfile={isCompanyProfile}
          companyProfileId={companyProfileId}
        />
      </Box>
    </div>
  );
};

ExportComponent.propTypes = {
  companyProfileId: PropTypes.string,
};

export default ExportComponent;
