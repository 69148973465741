import { rest } from 'msw';
import { intersection } from 'lodash';

const AVAILABLE_BAIN_IDS = ['555'];
const COMPANY_SEARCH_PREFIX = 'bain_id:';

export const elasticController = [
  rest.post(`${process.env.REACT_APP_API_URL}/midtier/company_search_elastic`, async (req, res, ctx) => {
    const requestBody = await req.json();
    const companySearch = requestBody?.company_search;

    if (companySearch?.startsWith(COMPANY_SEARCH_PREFIX)) {
      const requestedBainIds = companySearch.slice(COMPANY_SEARCH_PREFIX.length).slice(1, -1).split(' OR ');

      const commonBainIds = intersection(requestedBainIds, AVAILABLE_BAIN_IDS);

      return res(
        ctx.json({
          id_count: commonBainIds.length,
          bain_id: commonBainIds.length ? commonBainIds : ['-1'],
        }),
      );
    }

    return res(
      ctx.json({
        data: requestBody.get_count ? [{ row_count: 1 }] : [],
      }),
    );
  }),
];
