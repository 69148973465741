import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import { HEADER_HEIGHT } from '@/constants';

interface IProps {
  title: string;
  description: string;
  Icon: () => JSX.Element;
}

export const ErrorPage = ({ title, description, Icon }: IProps) => {
  return (
    <div
      className="flex justify-center items-center gap-32"
      style={{ height: `calc(100vh - ${HEADER_HEIGHT}px)` }}
    >
      <div className="flex flex-col">
        <h2 className="text-4xl mb-5 font-bold">{title}</h2>
        <p className="text-xl mb-8">{description}</p>
        <div>
          <Link to="/">
            <Button className="bg-cred-50 text-white px-4 py-2.5">Return to Helix</Button>
          </Link>
        </div>
      </div>

      <div className="items-center">
        <Icon />
      </div>
    </div>
  );
};
