import React, { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import CompanyProfileView from '@/Components/CompanyResearch/CompanyProfile/CompanyProfileView';
import { DEFAULT_TAB, Tab } from '@/Components/CompanyResearch/CompanyProfile/types';
import { ROUTES } from '@/constants';
import { generateTab } from '@/Components/CompanyResearch/CompanyProfile/tabs/CompanyProfileTabs';

export const createCompanyProfileLink = (bainId: string, tab: Tab = DEFAULT_TAB) =>
  `${ROUTES.COMPANY_PROFILE}/${bainId}/${tab.slug}`;

const CompanyProfile: FC = () => {
  const { bainId, tab } = useParams<{ bainId: string; tab?: string }>();
  const { push } = useHistory();
  const [currentTab, setCurrentTab] = useState<Tab>();

  useEffect(() => {
    if (tab) {
      setCurrentTab(generateTab(tab));
    }

    if (!tab) {
      setCurrentTab(DEFAULT_TAB);
      push(createCompanyProfileLink(bainId));
    }
  }, [bainId, push, tab]);

  return (
    <CompanyProfileView
      bainId={bainId}
      currentTab={currentTab ?? DEFAULT_TAB}
    />
  );
};

export default CompanyProfile;
