import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useSearchHistory } from '../../../hooks/use-save-search-query';
import { useSearchCompanies } from '../../../hooks/use-search-companies';
import { useModal } from '../../../hooks/use-modal';
import { MODALS, SEARCH_EVENTS } from '../../../constants';
import { QueryRecord } from './QueryRecord';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/search';

export const SearchCompaniesHistoryDialog = () => {
  const dispatch = useDispatch();
  const { isOpen, handleClose } = useModal(MODALS.SEARCH_COMPANIES_HISTORY);
  const searchCompanies = useSearchCompanies();
  const historyKey = useShallowSelector((state) => state.search.historyKey);
  const { history, getGroupedSearchHistory } = useSearchHistory();
  const [groupedQueries, setGroupedQueries] = useState([]);

  useEffect(() => {
    setGroupedQueries(getGroupedSearchHistory());
  }, [history]);

  const handleSearch = (query) => {
    dispatch(actions.setQueryType(query.queryType));
    dispatch(actions.setSearchQuery(''));
    dispatch(actions.setSearchText(query.isLucene ? '' : query.searchText));
    searchCompanies({ ...query, eventName: SEARCH_EVENTS.HISTORY }, {});
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ paper: 'w-9/12 rounded-lg p-2' }}
    >
      <DialogTitle className="text-base font-bold pb-2 text-[#484848]">History</DialogTitle>
      <CloseIcon
        fontSize="small"
        className="absolute right-4 top-6 cursor-pointer fill-[#484848]"
        onClick={handleClose}
      />
      <DialogContent className="text-sm pt-2">
        {groupedQueries.map(({ label, queries }) => (
          <Box key={label}>
            <Typography className="flex-none w-16 mb-3 text-[10px] text-cgray-50 uppercase font-medium">
              {label}
            </Typography>
            {queries.map((query) => (
              <Box
                key={query.createTimestamp}
                className="mb-3"
              >
                <QueryRecord
                  query={query}
                  onSearch={handleSearch}
                />
              </Box>
            ))}
          </Box>
        ))}
        {history?.[historyKey]?.length === 0 && <Typography className="mt-2 text-sm">No history</Typography>}
      </DialogContent>
    </Dialog>
  );
};
