import API from '@aws-amplify/api-rest';

import { IMidtierTable, IReqMidtier } from '@/types';

export const getMidtierTable = ({ currency, body }: IReqMidtier): Promise<IMidtierTable[]> =>
  API.post('CDPAPI', `/midtier?currency=${currency}`, { body }).then((response) => response.data);

export const getMidtierTableCount = ({ currency, body }: IReqMidtier): Promise<number> =>
  API.post('CDPAPI', `/midtier?currency=${currency}`, { body }).then((response) => response.data[0].row_count);

export const postSmartSearch = async ({ text }: { text: string }): Promise<{ suggested_query: string }> =>
  await API.post('CDPAPI', '/smart_search', { body: { user_input: text } });
