import React, { useMemo } from 'react';

import { isNaN } from 'lodash';
import { Tooltip } from '@mui/material';
import { numberFormatterCustom } from '../../../../Shared/common';
import { CURRENCIES } from '@/constants';
import { createCompanyProfileLink } from '@/pages/company-profile';
import { Currency, DatumDatum } from '@/types';

const SOURCES = ['BrightQuery', 'CapitalIQ', 'ZoomInfo'];

interface OverviewTabProps {
  row?: DatumDatum;
  currency: string;
}

export const useOverviewTabData = ({ currency, row }: OverviewTabProps) => {
  const topLevelParentCompany = useMemo(() => {
    const parentName = row?.parent_firmo_name___;

    if (!parentName) return undefined;

    const parentBainId = row?.parent_ids_id__cdp_ ? String(row?.parent_ids_id__cdp_) : undefined;
    const selfBainId = row?.self_ids_id__cdp_ ? String(row?.self_ids_id__cdp_) : undefined;

    if (!parentBainId || parentBainId === selfBainId) return parentName;

    return (
      <button
        onClick={() => window.open(createCompanyProfileLink(parentBainId), '_blank')}
        className="underline cursor-pointer text-[#0484E7]"
      >
        {parentName}
      </button>
    );
  }, [row?.parent_firmo_name___, row?.parent_ids_id__cdp_, row?.self_ids_id__cdp_]);

  // TODO: get from separate field after data refresh
  const revenueYear = useMemo(() => {
    if (!row) return;

    const { self_financials_revenue___metadata, self_financials_revenue___ } = row;

    if (!self_financials_revenue___ || !self_financials_revenue___metadata) return;

    const indexOfRevenue = self_financials_revenue___metadata.indexOf(String(self_financials_revenue___));

    const sourceSlicedMetadata = self_financials_revenue___metadata.slice(0, indexOfRevenue);

    const revenueSource = SOURCES.filter((source) => sourceSlicedMetadata.endsWith(`${source}: `))?.[0];

    if (!revenueSource) return;

    const yearMetadataLabel = `${revenueSource} year end: `;

    const indexOfYearMetadata = self_financials_revenue___metadata.indexOf(yearMetadataLabel);

    if (indexOfYearMetadata === -1) return;

    const yearSlicedMetadata = self_financials_revenue___metadata.slice(indexOfYearMetadata + yearMetadataLabel.length);

    const year = yearSlicedMetadata.slice(0, 4);

    if (isNaN(Number(year))) return;

    return {
      year,
      label: ` (${year})`,
      source: revenueSource,
    };
  }, [row]);

  const revenueLabel = useMemo(() => {
    if (!row) return;

    const { self_financials_revenue___ } = row;

    if (!self_financials_revenue___) return;

    const currencySign = CURRENCIES[currency as Currency].label;
    const formattedRevenue = numberFormatterCustom(self_financials_revenue___);
    const revenueWithCurrency = `${currencySign}${formattedRevenue}`;

    if (revenueYear)
      return (
        <Tooltip title={`Source: ${revenueYear.source}`}>
          <span>{`${revenueWithCurrency}${revenueYear.label}`}</span>
        </Tooltip>
      );

    return revenueWithCurrency;
  }, [currency, row, revenueYear]);

  return { topLevelParentCompany, revenueLabel };
};
