import React from 'react';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { CaseCodeForm } from './CaseCodeForm';
import { CaseCodeSubmitButton } from './CaseCodeSubmitButton';
import { CASE_CODE_FLOW_TYPES, CASE_CODE_FORM_STEPS } from '@/constants';

export const CaseCodeFirstStep = ({
  isLoading,
  isEditMode,
  onSubmit,
  setStep,
  flowData,
  handleFlowChange,
  defaultValues,
}) => {
  const form = useForm({ defaultValues });

  return (
    <>
      <FormControl classes={{ root: 'py-5 mr-8' }}>
        <FormLabel
          id="form-type"
          classes={{ root: 'text-[#484848] text-sm font-semibold' }}
        >
          What can we help you with?
        </FormLabel>
        <RadioGroup
          aria-labelledby="form-type"
          name="form-type"
          value={flowData?.value ?? ''}
          onChange={handleFlowChange}
          classes={{ root: 'pt-2 ml-1' }}
        >
          {CASE_CODE_FLOW_TYPES.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio />}
              label={label}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {flowData ? (
        <>
          <Divider />

          <CaseCodeForm
            inputs={flowData?.[CASE_CODE_FORM_STEPS.FIRST]}
            form={form}
          />

          {flowData?.[CASE_CODE_FORM_STEPS.SECOND] ? (
            <button
              className="flex justify-start self-start"
              onClick={() => setStep(CASE_CODE_FORM_STEPS.SECOND)}
            >
              <Typography className="underline text-[#0484E7] font-medium text-sm">Don’t have a case code?</Typography>
            </button>
          ) : null}

          <CaseCodeSubmitButton
            isLoading={isLoading}
            isEditMode={isEditMode}
            onSubmit={form.handleSubmit(onSubmit)}
          />
        </>
      ) : null}
    </>
  );
};
