import React from 'react';
import { useHistory } from 'react-router';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Button from '@mui/material/Button';

import { useModal } from '@/hooks/use-modal';
import { MODALS, ROUTES } from '@/constants';
import { IconSearch } from '@/Components/Icons/IconSearch';

export const NO_SAVED_SEARCHES_TEXT = 'You have no searches to show, search for companies and save the search!';
export const FAIL_FETCH_SAVED_SEARCHES_TEXT = 'Failed to fetch saved searches. Please try again or contact support.';
export const NO_COMPANIES_LISTS_TEXT =
  'You have no lists to show, search for companies and add them to a list or create a new list here!';
export const FAIL_FETCH_COMPANIES_LISTS_TEXT = 'Failed to fetch companies lists. Please try again or contact support.';

export const CtaButtons = () => {
  const history = useHistory();
  const { handleOpen: handleCreateCompaniesListModalOpen } = useModal(MODALS.CREATE_COMPANIES_LIST);

  const handleStartNewSearch = () => {
    history.push(ROUTES.CUSTOM_SEARCH);
  };

  return (
    <>
      <Button
        variant="outlined"
        className="text-[#2e3f4c] border-[#2e3f4c] py-2 px-3 mr-4"
        startIcon={<IconSearch />}
        onClick={handleStartNewSearch}
      >
        Start new search
      </Button>

      <Button
        variant="outlined"
        className="text-[#2e3f4c] border-[#2e3f4c] py-2 px-3"
        startIcon={<FormatListBulletedIcon fontSize="small" />}
        onClick={() => handleCreateCompaniesListModalOpen({ predefinedCompanies: true })}
      >
        Create new list
      </Button>
    </>
  );
};
