import { filtersController } from './handlers/filters-controller';
import { columnMapperController } from './handlers/column-mapper-controller';
import { companiesListsController } from './handlers/companies-lists-controller';
import { savedSearchesController } from './handlers/saved-searches-controller';
import { midtierController } from './handlers/midtier-controller';
import { similarCompaniesController } from './handlers/similar-companies';
import { elasticController } from './handlers/elastic-controller';
import { exportController } from './handlers/export-controller';
import { companySearchController } from './handlers/company-search-controller';
import { smartSearchController } from './handlers/smart-search-controller';
import { companyOverviewExportController } from './handlers/company-overview-export-controller';

export const handlers = [
  ...filtersController,
  ...columnMapperController,
  ...companiesListsController,
  ...savedSearchesController,
  ...midtierController,
  ...similarCompaniesController,
  ...elasticController,
  ...exportController,
  ...companySearchController,
  ...smartSearchController,
  ...companyOverviewExportController,
];
