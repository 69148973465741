import { rest } from 'msw';

const MOCK_VISIBLE_COLUMNS = [
  '_check_',
  'self_firmo_name___',
  'self_firmo_description___',
  'self_firmo_webpage___',
  'self_location_country___',
  'self_sector_industry___',
  'self_firmo_employeeCount___',
  'self_financials_ebitda___',
  'self_financials_netIncome___',
  'self_financials_revenue___',
  'self_financials_revenueCagr_1yr__',
];

export const columnMapperDataMock = [
  {
    'Backend Name': 'id',
    'Display Name': 'ID',
    FILTER_COLUMN: 'N',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': null,
    'Display Rank': null,
  },
  {
    'Backend Name': 'self_financials_revenue___',
    'Display Name': 'Revenue',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: 'USD',
    NUMERIC_PRECISION: 2.0,
    'Display Header': 'Financials',
    'Display Rank': 400.0,
  },
  {
    'Backend Name': 'self_firmo_employeeCount___',
    'Display Name': 'Employee Count',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: '#',
    NUMERIC_PRECISION: 0.0,
    'Display Header': 'Firmographics',
    'Display Rank': 300.0,
  },
  {
    'Backend Name': 'self_firm_statusL2___',
    'Display Name': 'Status Detail',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Firmographics',
    'Display Rank': 315.0,
  },
  {
    'Backend Name': 'parent_sector_sector___',
    'Display Name': 'Parent Sector',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Parent',
    'Display Rank': 620.0,
  },
  {
    'Backend Name': 'parent_sector_industryGroup___',
    'Display Name': 'Parent Industry Group',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Parent',
    'Display Rank': 630.0,
  },
  {
    'Backend Name': 'parent_sector_industry___',
    'Display Name': 'Parent Industry',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Parent',
    'Display Rank': 625.0,
  },
  {
    'Backend Name': 'parent_sector_subIndustry___',
    'Display Name': 'Parent Sub-Industry',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Parent',
    'Display Rank': 635.0,
  },
];

export const tableColumnsMock = columnMapperDataMock.map(({ 'Backend Name': field, 'Display Name': headerName }) => ({
  field,
  headerName,
}));

export const defaultVisibleTableColumnsMock = tableColumnsMock.filter(({ field }) =>
  MOCK_VISIBLE_COLUMNS.includes(field),
);

export const defaultHiddenTableColumnsMock = tableColumnsMock.filter(
  ({ field }) => !MOCK_VISIBLE_COLUMNS.includes(field),
);

export const columnMapperController = [
  rest.get(`${process.env.REACT_APP_API_URL}/column_mapper`, (_, res, ctx) =>
    res(
      ctx.json({
        data: columnMapperDataMock,
      }),
    ),
  ),
];
