import API from '@aws-amplify/api-rest';

import { ICreateSavedSearch, ISavedSearch, ISavedSearchSnippet, IUpdateSavedSearch } from '@/types';

export const getSavedSearches = (): Promise<ISavedSearchSnippet[]> =>
  API.get('CDPAPI', '/saved_searches', {}).then((response) => response.saved_searches);

export const getSavedSearchById = (id: string): Promise<ISavedSearch> => API.get('CDPAPI', `/saved_search/${id}`, {});

export const createSavedSearch = (body: ICreateSavedSearch): Promise<ISavedSearch> =>
  API.post('CDPAPI', '/saved_search', { body: { ...body, list_type: 'dynamic' } });

export const updateSavedSearch = ({ id, body }: { id: string; body: IUpdateSavedSearch }): Promise<ISavedSearch> =>
  API.patch('CDPAPI', `/saved_search/${id}`, { body });

export const removeSavedSearch = (id: string): Promise<ISavedSearch> => API.del('CDPAPI', `/saved_search/${id}`, {});
