import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import classnames from 'classnames';
import { createEditor, Transforms, Editor } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';

import { Keywords } from './Keywords';
import { useSearchCompanies } from '@/hooks/use-search-companies';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { decorate, getSlateText } from '@/Utils/slate';
import { actions, QueryType } from '@/slices/search';
import { MODALS } from '@/constants';

const getInitialSlateValue = (searchQuery, searchText) => [
  {
    type: 'paragraph',
    children: [{ text: getSlateText(searchQuery, searchText) }],
  },
];

export const AdvancedSearchDialog = () => {
  const searchCompanies = useSearchCompanies();
  const { isOpen, handleClose } = useModal(MODALS.ADVANCED_SEARCH);
  const [editor] = useState(() => withReact(createEditor()));
  const searchQuery = useShallowSelector((state) => state.search.searchQuery);
  const text = useShallowSelector((state) => state.search.advancedSearchText);
  const dispatch = useDispatch();

  const initialSlateValue = getInitialSlateValue(searchQuery, text);

  useEffect(() => {
    const offset = initialSlateValue[0].children[0].text.length;

    /** Move the cursor to the last editor character  */
    Transforms.select(editor, { offset, path: [0, 0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, searchQuery]);

  useEffect(() => {
    if (isOpen) {
      dispatch(actions.setAdvancedSearchText(initialSlateValue[0].children[0].text));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleChange = (data) => {
    const result = data.reduce((previous, current) => previous + current.children[0].text, '');

    dispatch(actions.setAdvancedSearchText(result.replace(/\s\s+/g, ' ')));
  };

  const handleApplyClick = () => {
    if (text.trim() === '') {
      dispatch(actions.resetSearch());
    } else {
      searchCompanies(
        { searchText: text, queryType: QueryType.KEYWORD },
        { useStateFilters: false, concatPrevQuery: false },
      );
    }

    handleClose();
  };

  const handleClearClick = () => {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    });
    ReactEditor.focus(editor);
  };

  const handleCloseDialog = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      classes={{ paper: 'w-9/12 rounded-lg' }}
    >
      <DialogTitle className="text-sm">
        <p className="mb-2 text-base font-bold">Advanced Search</p>
        <p>
          Add in keywords and conditions using the field and buttons below, or simply paste in an existing query and
          we’ll highlight the conditions for you.
        </p>
      </DialogTitle>

      <DialogContent className="text-xs">
        <div className="relative p-2 rounded border border-[#ddd] overflow-y-auto">
          <Slate
            editor={editor}
            value={initialSlateValue}
            onChange={handleChange}
          >
            <Editable
              id="query-editor"
              decorate={decorate}
              className="h-40"
              renderLeaf={({ attributes, children, leaf }) => {
                return (
                  <span
                    className={classnames('bg-[#ebf9f2]', {
                      'text-cgray-150 inline-block rounded text-center': leaf.keyword || leaf.keychar,
                      'w-2': leaf.keychar,
                      'w-8': leaf.keyword,
                      'bg-[#e7cede]': leaf.keychar,
                      'bg-[#eae6ff]': leaf.keyword,
                    })}
                    {...attributes}
                  >
                    {children}
                  </span>
                );
              }}
            />
          </Slate>
        </div>

        <Keywords editor={editor} />

        <div className="mt-6 flex justify-end text-sm">
          <Button
            className="mx-2 text-black underline"
            onClick={handleCloseDialog}
          >
            Close
          </Button>
          <Button
            className={classnames('mx-2', {
              'bg-[#ddd] text-[#484848]': text,
            })}
            variant="contained"
            disabled={!text}
            onClick={handleClearClick}
          >
            Clear
          </Button>
          <Button
            className="mx-2 bg-[#cc0000]"
            variant="contained"
            onClick={handleApplyClick}
          >
            Apply
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
