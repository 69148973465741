import React, { useState, useEffect, useRef } from 'react';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';

import { MODALS } from '../../../constants';
import { useModal } from '../../../hooks/use-modal';
import { useSearchHistory } from '../../../hooks/use-save-search-query';
import { IconHistory } from '../../Icons/IconHistory';

const MENU_DEFAULT_WIDTH = 550;

const HistoryDropdown = ({ onQuerySelect, onDropdownToggle }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const historyBoxRef = useRef(null);
  const { getLastNSearchQueries, cleanOldHistory } = useSearchHistory();
  const [queriesToDisplay, setQueriesToDisplay] = useState([]);
  const { handleOpen: handleModalOpen } = useModal(MODALS.SEARCH_COMPANIES_HISTORY);
  const isOpened = !!anchorEl;

  useEffect(() => {
    cleanOldHistory();
  }, []);

  const openDropdown = () => {
    setAnchorEl(historyBoxRef?.current);
    setQueriesToDisplay(() => getLastNSearchQueries());
    onDropdownToggle?.(true);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    onDropdownToggle?.(false);
  };

  const handleSelectQuery = (query) => {
    if (query && onQuerySelect) {
      onQuerySelect(query);
      handleDropdownClose();
    }
  };

  const viewFullHistory = () => {
    handleDropdownClose();
    handleModalOpen();
  };

  return (
    <>
      <IconButton
        data-testid="query-history-dropdown"
        aria-controls={isOpened ? 'history-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpened ? 'true' : undefined}
        className="rounded bg-white border border-bluegray-900 border-solid text-bluegray-900 hover:bg-bluegray-900 hover:text-white h-[36px]"
        onClick={openDropdown}
        ref={historyBoxRef}
      >
        <div>
          <IconHistory
            fill="currentColor"
            data-testid="history-icon"
          />
        </div>
        <KeyboardArrowDownIcon
          fill="currentColor"
          className={classnames('text-xl transition', { 'rotate-180': isOpened })}
          data-testid="chevron-icon"
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={isOpened}
        onClose={handleDropdownClose}
        classes={{
          paper: 'rounded w-[480px] mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
          list: 'py-0',
        }}
      >
        {queriesToDisplay.map((query) => (
          <MenuItem
            key={query.createTimestamp}
            onClick={() => handleSelectQuery(query)}
            classes={{ root: 'h-[32px] min-h-[32px]' }}
            sx={{ width: '100%' }}
          >
            <Box
              className="mr-3"
              component="span"
            >
              <IconHistory color="#999999" />
            </Box>
            <Typography
              variant="inherit"
              noWrap
            >
              {query.isLucene ? query.searchText : `"${query.searchText}"`}
            </Typography>
          </MenuItem>
        ))}
        {queriesToDisplay.length === 0 && (
          <Typography
            className="pl-4 mb-2 mt-2 text-sm w-full"
            sx={{ width: MENU_DEFAULT_WIDTH }}
          >
            No history
          </Typography>
        )}
        <Link
          component="button"
          onClick={viewFullHistory}
          className="ml-4 mb-4 mt-2 text-xs"
          data-testid="query-history-view-full-history"
        >
          View full history
        </Link>
      </Menu>
    </>
  );
};

export default HistoryDropdown;
