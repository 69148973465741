import React from 'react';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import _ from 'lodash';

import { FilterTree } from './FilterTree';
import { FilterCheckboxList } from './FilterCheckboxList';
import { FilterNumerical } from './FilterNumerical';
import { FILTER_TYPES } from '@/constants';

export const getAllCount = (items) => items.reduce((acc, current) => (acc += current.active), 0);

export const FilterItem = ({ isFetching, itemId, itemName, itemType, itemUnit, filterItems, categoryFilterId }) => {
  if (itemType === FILTER_TYPES.TREE) {
    return (
      <div
        data-testid="filter-item"
        className="mt-2"
      >
        <Typography
          gutterBottom
          className="font-semibold text-[10px]"
        >
          {itemName}
        </Typography>
        <FilterTree
          data={[
            {
              _backend: itemId,
              value: 'All',
              active: getAllCount(filterItems),
              children: filterItems,
              categoryFilterId,
            },
          ]}
          isFetching={isFetching}
          itemId={itemId}
        />
      </div>
    );
  }

  if (itemType === FILTER_TYPES.NUMERICAL) {
    return (
      <div
        data-testid="filter-item"
        className="mt-2"
      >
        <Typography
          gutterBottom
          className="font-semibold text-[10px]"
        >
          {itemName}
        </Typography>
        <div>
          {isFetching && (
            <Skeleton
              variant="rounded"
              className="bg-[#0000000f] mr-4"
            />
          )}

          {!isFetching && _.isNull(filterItems) && <span className="pl-6">No filters</span>}

          {!isFetching && filterItems && (
            <FilterNumerical
              values={filterItems}
              type={itemUnit}
              itemId={itemId}
            />
          )}
        </div>
      </div>
    );
  }

  if (itemType === FILTER_TYPES.CATEGORICAL) {
    return (
      <div
        data-testid="filter-item"
        className="mt-2"
      >
        <Typography
          gutterBottom
          className="font-semibold text-[10px]"
        >
          {itemName}
        </Typography>
        <div>
          {isFetching && (
            <Skeleton
              variant="rounded"
              className="bg-[#0000000f] mr-4"
            />
          )}

          {!isFetching && _.isNull(filterItems) && <span className="pl-6">No filters</span>}

          {!isFetching && filterItems && (
            <FilterCheckboxList
              itemId={itemId}
              values={filterItems}
            />
          )}
        </div>
      </div>
    );
  }

  return null;
};
