import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useSessionStorage } from 'usehooks-ts';

import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import awsService from '../../../awsService';
import { useQueryTable } from '@/hooks/queries/use-query-table';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions as customScreenActions } from '@/slices/custom-screen';
import { actions as userActions } from '@/slices/user';
import { CASE_CODE_PROJECT_TYPES, CURRENCIES, LOCAL_STORAGE_KEYS, MODALS, CASE_CODE_FLOWS } from '@/constants';
import { useMenu } from '@/hooks/use-menu';
import { CASE_CODE_INITIAL_STATE } from '@/hooks/queries/use-mutation-log-case-code';
import { useModal } from '@/hooks/use-modal';
import { Avatar } from '@/Components/Shared/Avatar/Avatar';

export const COVERAGE_LINK =
  'https://tableau.bain.com/t/HelixFind/views/DQ_Dashboards_User_v1/HFDataCoverageOverview?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=n';

export const SOURCE_LINK = 'https://tableau.bain.com/#/site/HelixFind/views/HelixDataDictionary/HDPDataDictionary';

enum AvatarVariant {
  HEADER,
  DROPDOWN,
}

export const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const { handleOpen: handleCaseCodeModalOpen } = useModal(MODALS.CASE_CODE);
  const userEmail = useShallowSelector((state) => state.user.email);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const query = useQueryTable();
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);

  const caseCodeFormData = caseCodeData?.formData;
  const caseCodeFlowType = caseCodeData?.flowType;
  const caseCodeFormStep = caseCodeData?.step;

  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(customScreenActions.setCurrency(value));

    query.fetch();
    handleClose();
  };

  const logOut = () => {
    dispatch(userActions.setEmail(null));
    dispatch(userActions.setIsAuth(false));
    awsService.signOut();
  };

  const changeCaseCode = () => {
    handleCaseCodeModalOpen({
      formData: caseCodeFormData,
      flowType: Object.values(CASE_CODE_FLOWS).find((f) => f.label == caseCodeFlowType)?.value,
      step: caseCodeFormStep,
      isEditMode: true,
    });
    handleClose();
  };

  return (
    <>
      <Button
        data-testid="profile-dropdown"
        aria-controls={isOpen ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        classes={{ root: 'p-1' }}
      >
        <Avatar
          variant={AvatarVariant.HEADER}
          userEmail={userEmail}
        />
        <KeyboardArrowDownIcon
          className={classnames('fill-cgray-50 text-xl transition ml-1', { 'rotate-180': isOpen })}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] min-w-[250px]',
        }}
      >
        <div className="flex p-4 gap-4">
          <div>
            <Avatar
              variant={AvatarVariant.DROPDOWN}
              userEmail={userEmail}
            />
          </div>
          <div>
            <div className="flex flex-col">
              <Typography className="text-[#2E3F4C] font-medium text-base">{userEmail}</Typography>
              {caseCodeFormData?.caseCode ? (
                <Typography className="text-[#2E3F4C] font-medium text-base">{`Case code - ${caseCodeFormData?.caseCode}`}</Typography>
              ) : null}
              {caseCodeFormData?.projectType ? (
                <Typography className="text-[#2E3F4C] font-medium text-base">
                  {CASE_CODE_PROJECT_TYPES.find(({ value }) => value === caseCodeFormData?.projectType)?.label}
                </Typography>
              ) : null}
              {caseCodeFormData?.projectName ? (
                <Typography className="text-[#2E3F4C] font-medium text-base">
                  {caseCodeFormData?.projectName}
                </Typography>
              ) : null}
              {caseCodeFormData?.partnerEmail ? (
                <Typography className="text-[#2E3F4C] font-medium text-base">
                  {caseCodeFormData?.partnerEmail}
                </Typography>
              ) : null}
              {caseCodeFormData?.otherInfo ? (
                <Typography className="text-[#2E3F4C] font-medium text-base">{caseCodeFormData?.otherInfo}</Typography>
              ) : null}
              <button
                className="flex justify-start"
                onClick={changeCaseCode}
              >
                <Typography className="underline text-[#0484E7] font-medium text-base">Change</Typography>
              </button>
            </div>
          </div>
        </div>

        <Divider />

        <FormControl className="w-full py-2">
          <RadioGroup
            aria-labelledby="currency-radio-buttons-group"
            name="currency-radio-buttons-group"
            value={currency}
            onChange={handleChange}
          >
            {Object.entries(CURRENCIES).map(([key, val]) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio color="info" />}
                label={`${key} - ${val.label}`}
                className={classnames('pl-1 m-0 h-[42px] min-h-[42px] hover:bg-[#0000000a]')}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Divider />

        <MenuItem className="h-[42px] min-h-[42px] p-0 mt-2">
          <a
            href={COVERAGE_LINK}
            className="underline text-[#0484E7] font-semibold w-full h-full flex items-center pl-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Data coverage
          </a>
        </MenuItem>

        <MenuItem className="h-[42px] min-h-[42px] p-0">
          <a
            href={SOURCE_LINK}
            className="underline text-[#0484E7] font-semibold w-full h-full flex items-center pl-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Data source descriptions
          </a>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => logOut()}
          className="h-[42px] min-h-[42px]"
        >
          <span className="font-semibold">Log out</span>
        </MenuItem>
      </Menu>
    </>
  );
};
