import { rest } from 'msw';

export const itCompaniesListInputPrompt = 'give me a list of IT companies';

export const smartSearchController = [
  rest.post(`${process.env.REACT_APP_API_URL}/smart_search`, async (req, res, ctx) => {
    const requestBody = await req.json();
    const userInput = requestBody?.user_input;

    if (userInput === itCompaniesListInputPrompt) {
      return res(
        ctx.json({
          suggested_query: 'IT OR tech',
        }),
      );
    }

    return res(
      ctx.json({
        suggested_query: 'test',
      }),
    );
  }),
];
