import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { Box, Dialog, DialogActions, DialogTitle, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import styled from 'styled-components';
import { take } from 'rxjs';
import API from '@aws-amplify/api-rest';
import { Close } from '@mui/icons-material';
import _ from 'lodash';
import { useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import { useSessionStorage } from 'usehooks-ts';

import { ExportButton } from '../../Shared/Buttons/ExportButton';
import { NotificationMessage } from '../../Shared/Notifications/NotificationMessage';
import { getFilters, normalizeFilters } from '@/Utils/filters';
import { prepareCaseCodeData } from '@/Utils/case-code-utils';
import { useSearchHistory } from '@/hooks/use-save-search-query';
import { FIXED_ELASTIC_SEARCH_OUTPUT, MODALS, LOCAL_STORAGE_KEYS, ROUTES } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { searchSubject } from '@/Utils/subjects';
import { useModal } from '@/hooks/use-modal';
import { ExportEnum } from '@/types/history';

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    padding: '9px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '&.MuiButtonBase-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  '&.Mui-checked': {
    color: '#c00',
  },
}));

const getCompaniesCount = ({ exportModalData, exportOption, selectedCompanies, isCompanyProfile, totalRows }) => {
  if (isCompanyProfile) {
    return exportModalData?.bainIds?.length ?? 0;
  }

  if (exportOption === 'selected') {
    return selectedCompanies?.length;
  }

  return Math.min(totalRows, FIXED_ELASTIC_SEARCH_OUTPUT);
};

const ExportDialog = () => {
  const { isOpen, handleClose, data: exportModalData } = useModal(MODALS.EXPORT);
  const [exportOption, setExportOption] = useState('all');
  const location = useLocation();
  const { saveQuery } = useSearchHistory();
  const selectedCompanies = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);
  const totalRows = useShallowSelector((state) => state.table.totalRows);
  const total = useShallowSelector((state) => state.search.bainIds.total);
  const storedFilters = useShallowSelector((state) => state.filters);
  const filters = useShallowSelector((state) => getFilters(state.filters));
  const searchQuery = useShallowSelector((state) => state.search.searchQuery);
  const searchQueryTree = useShallowSelector((state) => state.search.searchQueryTree);
  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const email = useShallowSelector((state) => state.user.email);
  const queryType = useShallowSelector((state) => state.search.queryType);
  const visibleColumns = useShallowSelector((state) => state.table.visible);
  const sortModel = useShallowSelector((state) => state.table.sortModel);
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE);
  const caseCodeFormData = caseCodeData?.formData;
  const caseCodeFlowType = caseCodeData?.flowType;
  const bainIds = useShallowSelector((state) => state.search.bainIds.data);
  const areIdsEmpty = _.isEmpty(selectedCompanies);
  const countToUse = total || totalRows;
  const snackbar = useSnackbar();

  const isCompanyProfile = location.pathname.includes(ROUTES.COMPANY_PROFILE);

  const handleExportData = () => {
    const isCustomScreen = location.pathname === ROUTES.CUSTOM_SEARCH;

    let body = {
      email,
      sql_query: true,
      ...(!_.isEmpty(bainIds) && { self_ids_id__cdp_: bainIds }),
      ...(!_.isEmpty(includedNullList) && {
        include_null_list: includedNullList,
      }),
      ...(isCustomScreen && { free_search_text: searchQuery }),
      ...normalizeFilters(filters),
      case_data: { ...prepareCaseCodeData(caseCodeFormData), work_stream: caseCodeFlowType },
    };

    if (isCompanyProfile) {
      body.company_profile = true;
      body.bainIds = exportModalData?.bainIds;
    } else if (exportOption === 'selected') {
      body.company_profile = true;
      body.bainIds = selectedCompanies;
    }

    body['summary_columns'] = visibleColumns.filter((column) => !['__check__', 'id'].includes(column));

    if (sortModel.length > 0) {
      body['sort_key'] = sortModel[0].field;
      body['sort_order'] = sortModel[0].sort;
    }

    try {
      API.post('CDPAPI', `/midtier/export?currency=${currency}`, {
        body: body,
      });
      snackbar.enqueueSnackbar(
        <NotificationMessage
          title="Success! You will receive an email with a link to your export."
          description="Exports may take upwards of 10 minutes based on the size of the data.
            Please wait before re-attempting to export."
        />,
        { variant: 'success' },
      );
    } catch (err) {
      snackbar.enqueueSnackbar(<NotificationMessage title="Error. Please try again or contact support." />, {
        variant: 'error',
      });
    }

    handleClose();

    if (saveQuery) {
      const companiesCount = getCompaniesCount({
        exportModalData,
        exportOption,
        selectedCompanies,
        isCompanyProfile,
        totalRows,
      });

      // TODO: refactor, this subscription is needed to get isUniq value
      searchSubject.pipe(take(1)).subscribe(({ isUniq }) => {
        saveQuery({
          searchText: searchQuery,
          companiesCount,
          isLucene: !!searchQueryTree,
          isExport: true,
          exportType: ExportEnum.DATA,
          filters: storedFilters,
          isUniq,
          queryType,
          ...(exportOption === 'selected' ? { selectedCompanies: selectedCompanies.join(', ') } : {}),
          ...(isCompanyProfile ? { selectedCompanies: exportModalData?.bainIds.join(', ') } : {}),
        });
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      id="dialog-box"
    >
      <div style={{ backgroundColor: 'white', position: 'absolute', right: '5px', padding: '10px' }}>
        <Close
          id="close-icon"
          onClick={handleClose}
          sx={{ cursor: 'pointer', color: 'black' }}
        />
      </div>
      <DialogTitle
        sx={{
          backgroundColor: 'white',
          color: 'black',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography>
          <b>Export Results</b>
        </Typography>
        {!isCompanyProfile && countToUse > FIXED_ELASTIC_SEARCH_OUTPUT && (
          <Alert
            severity="warning"
            className="mb-4 mt-3 px-3 py-0.5 whitespace-pre-line bg-[#FEF7EA]"
          >
            {`You can only export the first ${FIXED_ELASTIC_SEARCH_OUTPUT.toLocaleString('en')} companies.
Refine your search or if you need to exceed this limit contact the `}

            <a
              className="text-cblue-900"
              href="mailto:helixfind@bain.com"
            >
              Helix Find Team
            </a>
          </Alert>
        )}
        {isCompanyProfile ? (
          <Typography
            variant="body1"
            gutterBottom
          >
            Please select "Export" to export all data for the selected company.
          </Typography>
        ) : (
          <Typography
            variant="body1"
            gutterBottom
          >
            Please select an export option:
          </Typography>
        )}
      </DialogTitle>
      <DialogActions
        sx={{ width: '500px', backgroundColor: 'white', color: 'black', padding: '15px' }}
        id="dialog-actions"
      >
        {!isCompanyProfile ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <RadioGroup
                aria-label="export"
                name="export"
                value={exportOption}
                onChange={(_, value) => setExportOption(value)}
                style={{
                  position: 'relative',
                }}
                id="radio-group"
              >
                <FormControlLabel
                  value="all"
                  control={<StyledRadio id="export-all-radio" />}
                  label="Export summary data for all search results (excludes time-series data on financials, news, M&A, others...)"
                  id="export-all"
                />
                <FormControlLabel
                  value="selected"
                  control={<StyledRadio id="export-selected-radio" />}
                  disabled={areIdsEmpty}
                  label="Export all data for only selected companies"
                  id="export-selected"
                />
              </RadioGroup>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <ExportButton
                id="export-button-1"
                onClick={handleExportData}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ExportButton
              id="export-button-2"
              onClick={handleExportData}
            />
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
