import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import Skeleton from '@mui/material/Skeleton';
import { range } from 'lodash';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useModal } from '../../../hooks/use-modal';
import { MODALS } from '../../../constants';
import { useMutationUpdateCompaniesListCollaborators } from '@/hooks/queries/companies-list/use-mutation-update-companies-list-collaborators';
import { useQueryCompaniesListCollaboratorsById } from '@/hooks/queries/companies-list/use-query-companies-list-collaborators-by-id';
import { useMutationRemoveCompaniesListCollaborator } from '@/hooks/queries/companies-list/use-mutation-remove-companies-list-collaborator';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { validateEmail } from '@/Utils/text';

export const ShareListWithCollaboratorsDialog = () => {
  const { isOpen, handleClose, data: modalData } = useModal(MODALS.SHARE_LIST_WITH_COLLABORATORS);
  const { register, handleSubmit, formState, reset: resetForm, setError } = useForm();
  const userEmail = useShallowSelector((state) => state.user.email);
  const listId = modalData?.listId;
  const { data: collaboratorsData, isFetching: isCollaboratorsLoading } =
    useQueryCompaniesListCollaboratorsById(listId);
  const collaborators = collaboratorsData?.collaborators;

  const { mutate: updateCollaborators, isLoading: isUpdatingCollaborators } =
    useMutationUpdateCompaniesListCollaborators();

  const { mutate: removeCollaborator, isLoading: isRemovingCollaborator } =
    useMutationRemoveCompaniesListCollaborator(listId);

  const handleModalClose = () => {
    resetForm();
    handleClose();
  };

  const onSubmit = ({ email }) => {
    updateCollaborators(
      { id: listId, email },
      {
        onSuccess: () => resetForm(),
        onError: () =>
          setError(
            'email',
            {
              type: 'focus',
              message: `Failed to share list with ${email}`,
            },
            { shouldFocus: true },
          ),
      },
    );
  };

  const validate = (value) => {
    if (!value) {
      return 'Email is required';
    }

    if (!validateEmail(value)) {
      return 'Wrong email format';
    }

    if (value === userEmail) {
      return 'Cannot share the list with yourself';
    }

    return true;
  };

  const handleEnter = (event) => {
    const isEnter = event.key === 'Enter';

    if (isEnter) {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const setValueAs = (value) => value?.trim()?.toLowerCase();

  const error = formState?.errors?.email;

  return (
    <Dialog
      open={isOpen}
      onClose={handleModalClose}
      classes={{ paper: 'w-1/3 rounded-lg p-4' }}
    >
      <div className="flex justify-between items-center pb-4">
        <DialogTitle className="flex text-base font-bold p-0 text-[#484848]">Share list with...</DialogTitle>

        <CloseIcon
          fontSize="small"
          className="cursor-pointer fill-[#484848]"
          onClick={handleModalClose}
        />
      </div>

      <DialogContent className="text-sm p-0">
        <form className="flex flex-col gap-4 pt-4">
          <div className="flex flex-col gap-2">
            <TextField
              placeholder="Email"
              {...register('email', { validate, setValueAs })}
              variant="outlined"
              size="small"
              disabled={isUpdatingCollaborators}
              inputProps={{
                'data-testid': 'data-email',
              }}
              error={!!error}
              onKeyPress={handleEnter}
              autoFocus
            />
            {error ? <span className="text-xs text-[#D63333]">{error?.message}</span> : <div className="h-4" />}
          </div>
        </form>

        <div className="flex gap-3 flex-wrap mt-2 max-h-[80px] overflow-y-auto">
          {isCollaboratorsLoading || isRemovingCollaborator ? (
            <div className="w-full flex justify-between gap-2">
              {range(3).map((index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width="32%"
                  height={32}
                />
              ))}
            </div>
          ) : (
            collaborators?.map(({ email, sub }) => {
              return (
                <Chip
                  key={email}
                  label={email}
                  onDelete={() => removeCollaborator({ email, id: sub })}
                />
              );
            })
          )}
        </div>

        <div className="flex justify-end mt-4">
          <Button
            variant="contained"
            className={classnames({
              'bg-cred-50': !isUpdatingCollaborators,
            })}
            data-testid="create-button"
            onClick={handleSubmit(onSubmit)}
            endIcon={
              <CircularProgress
                className={classnames('text-white transition', {
                  hidden: !isUpdatingCollaborators,
                })}
                size={20}
              />
            }
            disabled={isUpdatingCollaborators}
          >
            Share
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
