import { rest } from 'msw';

export const midtierController = [
  rest.post(`${process.env.REACT_APP_API_URL}/midtier`, async (req, res, ctx) => {
    const requestBody = await req.json();

    return res(
      ctx.json({
        data: requestBody.get_count ? [{ row_count: 1 }] : [],
      }),
    );
  }),
];
