import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useShallowSelector } from '../../use-shallow-selector';
import { getCompanyProfileDataset } from '@/services/api/company-profile';

interface UseQueryCompanyProfileDatasetProps {
  bainId: string;
  dataset: string;
}

export const useQueryCompanyProfileDataset = ({ bainId, dataset }: UseQueryCompanyProfileDatasetProps) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    ['company-profile-dataset', bainId, dataset, currency],
    () => getCompanyProfileDataset({ bainId, dataset, currency }),
    {
      enabled: isAuth && !!bainId && !!dataset,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      onError: () =>
        enqueueSnackbar(`Failed to fetch ${dataset} dataset. Please try again or contact support.`, {
          variant: 'error',
        }),
    },
  );
};
