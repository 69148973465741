import { combineReducers } from '@reduxjs/toolkit';

import { reducer as userReducer } from './user';
import { reducer as customScreenReducer } from './custom-screen';
import { reducer as modalReducer } from './modal';
import { reducer as searchReducer } from './search';
import { reducer as filtersReducer } from './filters';
import { reducer as tableReducer } from './table';
import { reducer as navigationTabsReducer } from './navigation-tabs';
import { reducer as similarCompaniesSearchReducer } from './similar-companies';
import { reducer as selectedCompaniesReducer } from './selected-companies';
import { reducer as searchListsTablesReducer } from './search-lists-tables';

export const rootReducer = combineReducers({
  user: userReducer,
  customScreen: customScreenReducer,
  modal: modalReducer,
  search: searchReducer,
  filters: filtersReducer,
  table: tableReducer,
  navigationTabs: navigationTabsReducer,
  similarCompaniesSearch: similarCompaniesSearchReducer,
  selectedCompanies: selectedCompaniesReducer,
  searchListsTables: searchListsTablesReducer,
});
