import React, { memo } from 'react';
import {
  GridCallbackDetails,
  GridColumns,
  GridColumnVisibilityChangeParams,
  GridRowParams,
  GridSortModel,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { isEqual, pick } from 'lodash';

import { useCustomColumnsTransformation } from '../../../hooks/table/use-custom-columns-transformation';
import { Component as DataGridPro } from './styles';
import { CustomToolbar } from './CustomToolbar';
import { Pagination } from './Pagination';
import ColumnsVisibilityPanel from '@/Components/CompanyResearch/ColumnsVisibilityPanel';
import { IconGridDensity } from '@/Components/Icons/IconGridDensity';
import { IconTableColumns } from '@/Components/Icons/IconTableColumns';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { IMidtierTable } from '@/types';
import { FIXED_ELASTIC_SEARCH_OUTPUT } from '@/constants';

import './index.scss';

interface IProps {
  isLoading: boolean;
  isNumRowsLoading: boolean;
  columns: GridColumns;
  rows: IMidtierTable[];
  totalNumRows: number;
  countText: string;
  handlePageChange: (currentPage: number) => void;
  handleSortChange: (model: GridSortModel, details: GridCallbackDetails) => void;
  handleRowClick: ({ row }: GridRowParams, { target }: MuiEvent<React.MouseEvent>) => void;
}

export const TableGridUI = ({
  isLoading,
  isNumRowsLoading,
  columns,
  rows,
  totalNumRows,
  countText,
  handlePageChange,
  handleSortChange,
  handleRowClick,
}: IProps) => {
  const pageSize = useShallowSelector((state) => state.table.pageSize);
  const pageNumber = useShallowSelector((state) => state.table.pageNumber);

  const {
    isTableModified,
    transformedColumns,
    customDensity,
    muiTableKey,
    customTableFilters,
    onColumnOrderChange,
    onColumnVisibilityChange,
    onColumnWidthChange,
    onResetColumnSettings,
    onFilterModelChange,
    onStateChange,
  } = useCustomColumnsTransformation({ columns });

  return (
    <DataGridPro
      key={muiTableKey}
      rows={rows}
      columns={isLoading ? columns : transformedColumns}
      filterModel={{ items: customTableFilters }}
      pageSize={pageSize}
      page={pageNumber}
      checkboxSelection
      checkboxSelectionVisibleOnly
      autoHeight={false}
      rowHeight={35}
      rowCount={Math.min(totalNumRows, FIXED_ELASTIC_SEARCH_OUTPUT)}
      disableSelectionOnClick
      pagination
      localeText={{
        toolbarColumns: '',
        toolbarDensity: '',
      }}
      components={{
        Pagination: () => <Pagination handlePageChange={handlePageChange} />,
        ColumnSelectorIcon: IconTableColumns,
        DensityComfortableIcon: IconGridDensity,
        DensityStandardIcon: IconGridDensity,
        DensityCompactIcon: IconGridDensity,
        Toolbar: () => (
          <CustomToolbar
            countText={countText}
            onResetTableSettings={onResetColumnSettings}
            showResetTableSettingsButton={isTableModified}
            currentRows={{ min: pageNumber * pageSize + 1, max: pageNumber * pageSize + pageSize }}
            isLoading={isLoading || isNumRowsLoading}
          />
        ),
        NoRowsOverlay: () => (
          <div className="h-full flex justify-center items-center italic text-[#66666d]">
            No results found. Please try different search criteria.
          </div>
        ),
        ColumnsPanel: ColumnsVisibilityPanel,
      }}
      classes={{ cell: 'relative', footerContainer: 'border-t' }}
      disableColumnPinning={true}
      disableColumnSelector={false}
      paginationMode="server"
      rowsPerPageOptions={[]}
      onPageChange={handlePageChange}
      sortingMode="server"
      onSortModelChange={handleSortChange}
      onRowClick={handleRowClick}
      // TODO: consider custom load-aware pagination component instead of hiding
      hideFooterPagination={isNumRowsLoading}
      onColumnOrderChange={onColumnOrderChange}
      onColumnVisibilityChange={({ field, isVisible }: GridColumnVisibilityChangeParams) =>
        onColumnVisibilityChange(field, isVisible)
      }
      onColumnWidthChange={onColumnWidthChange}
      density={customDensity}
      onStateChange={onStateChange}
      onFilterModelChange={onFilterModelChange}
    />
  );
};

export const TableGrid = memo(TableGridUI, (prev, next) => {
  return isEqual(
    pick(prev, ['rows', 'totalNumRows', 'isNumRowsLoading', 'countText']),
    pick(next, ['rows', 'totalNumRows', 'isNumRowsLoading', 'countText']),
  );
});
