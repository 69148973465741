import React, { FC } from 'react';
import { range } from 'lodash';

import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { createCompanyProfileLink } from '@/pages/company-profile';
import { ICompaniesSubsidiaries } from '@/types';

interface SubsidiariesTableProps {
  subsidiaries: ICompaniesSubsidiaries;
}

export const SubsidiariesTable: FC<SubsidiariesTableProps> = ({ subsidiaries }) => {
  const subsidiariesCount = subsidiaries?.length;

  const filterModel = {
    items: [{ columnField: 'companyName', operatorValue: 'contains', value: '' }],
  };

  const columns = [
    { field: 'bainId', headerName: 'Bain ID', width: 110 },
    {
      field: 'companyName',
      headerName: 'Company Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ row, value }: GridRenderCellParams) => {
        return (
          <button
            onClick={() => window.open(createCompanyProfileLink(row.bainId), '_blank')}
            className="underline cursor-pointer text-[#0484E7] w-full flex justify-start"
          >
            {value}
          </button>
        );
      },
    },
  ];
  const rows = range(subsidiariesCount).map((id) => {
    const { bain_id, self_firmo_name___: name } = subsidiaries[id];

    return {
      id,
      bainId: bain_id,
      companyName: name,
    };
  });

  return (
    <div className="h-[40vh] mb-4">
      <DataGridPro
        pagination
        pageSize={1000}
        rowsPerPageOptions={[1000]}
        columns={columns}
        rows={rows}
        rowHeight={35}
        hideFooterPagination={true}
        filterModel={filterModel}
        hideFooter
        checkboxSelection={false}
        disableSelectionOnClick
      />
    </div>
  );
};
