import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

export const CaseCodeForm = ({
  inputs,
  form: {
    control,
    register,
    formState: { errors },
  },
}) => (
  <form className="flex flex-col text-black mt-5">
    {inputs.map(({ key, label: inputLabel, required, dataTestId, items, info }) => {
      if (items) {
        return (
          <Controller
            key={key}
            name={key}
            control={control}
            rules={{ required }}
            render={({ field }) => (
              <>
                <InputLabel
                  htmlFor={key}
                  classes={{ root: 'text-[#484848] text-sm font-semibold pb-4' }}
                >
                  {inputLabel}
                </InputLabel>
                {info ? <span className="text-[#484848] text-sm font-semibold pb-2">{info}</span> : null}
                <FormControl>
                  <Select
                    {...field}
                    id={key}
                    className="text-black"
                    MenuProps={{
                      classes: {
                        paper: 'bg-white text-black',
                      },
                    }}
                    error={!!errors[key]}
                    size="small"
                  >
                    {items.map(({ value, label }) => (
                      <MenuItem
                        key={label}
                        value={value}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          />
        );
      }

      return (
        <React.Fragment key={key}>
          <InputLabel
            htmlFor={key}
            classes={{ root: 'text-[#484848] text-sm font-semibold pb-2 pt-3' }}
          >
            {inputLabel}
          </InputLabel>
          {info ? <span className="text-[#484848] text-sm font-semibold pb-2">{info}</span> : null}
          <TextField
            key={key}
            id={key}
            data-testid={dataTestId}
            {...register(key, {
              required,
              setValueAs: (value) => value.trim(),
            })}
            classes={{ root: 'mt-2 mb-4' }}
            variant="outlined"
            InputProps={{
              classes: {
                root: 'text-black',
              },
            }}
            error={!!errors[key]}
            placeholder={required ? undefined : 'Optional'}
            size="small"
          />
        </React.Fragment>
      );
    })}
  </form>
);
