import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  selectedCompanies: [] as string[],
};

export const { reducer, actions } = createSlice({
  name: 'selectedCompanies',
  initialState,
  reducers: {
    toggleSelectedCompany: (state, { payload }: PayloadAction<string>) => {
      const item = _.find(state.selectedCompanies, (id) => id === payload);

      const result = item
        ? _.filter(state.selectedCompanies, (id) => id !== payload)
        : _.concat(state.selectedCompanies, payload);

      state.selectedCompanies = result;
    },
    setSelectedCompanies: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedCompanies = payload;
    },
    reset: (state) => {
      state.selectedCompanies = [];
    },
  },
});

export type TState = typeof initialState;
