import API from '@aws-amplify/api-rest';

import {
  ICreateCompaniesList,
  ICompaniesListSnippet,
  ICompaniesList,
  IUpdateCompaniesList,
  IUpdateCompaniesListCollaboratorVariables,
  ICompaniesListRevision,
} from '@/types';

export const getCompaniesLists = (): Promise<ICompaniesListSnippet[]> =>
  API.get('CDPAPI', '/search_lists', {}).then((response) => response.search_lists);

export const getCompaniesListById = (id: string): Promise<ICompaniesList> =>
  API.get('CDPAPI', `/search_list/${id}`, {});

export const createCompaniesList = (body: ICreateCompaniesList): Promise<ICompaniesList> =>
  API.post('CDPAPI', '/search_list', { body: { ...body, list_type: 'static' } });

export const updateCompaniesList = ({
  id,
  body,
}: {
  id: string;
  body: IUpdateCompaniesList;
}): Promise<ICompaniesList> => API.patch('CDPAPI', `/search_list/${id}`, { body });

export const removeCompaniesList = (id: string): Promise<ICompaniesList> => API.del('CDPAPI', `/search_list/${id}`, {});

export const updateCompaniesListCollaborator = ({
  id,
  email,
}: IUpdateCompaniesListCollaboratorVariables): Promise<unknown> =>
  API.put('CDPAPI', `/search_list/${id}/collaborators`, { body: { email } });

export const getCompaniesListCollaborators = (id: string): Promise<string[]> =>
  API.get('CDPAPI', `/search_list/${id}/collaborators`, {});

export const removeCompaniesListCollaborator = (listId: string, userId: string): Promise<unknown> =>
  API.del('CDPAPI', `/search_list/${listId}/collaborators`, { body: { sub: userId } });

export const getCompaniesListRevisions = (listId: string): Promise<ICompaniesListRevision[]> =>
  API.get('CDPAPI', `/search_list/${listId}/revisions`, {}).then((response) => response.revisions);

export const getCompaniesListRevisionById = (listId: string, revisionId: number): Promise<ICompaniesList> =>
  API.get('CDPAPI', `/search_list/${listId}/${revisionId}`, {});
