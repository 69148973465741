import React from 'react';

export const IconFilterSettings = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.167 15.833a.834.834 0 1 1 0-1.667.834.834 0 0 1 0 1.667Zm8.333-1.666h-5.988A2.496 2.496 0 0 0 9.167 12.5c-1.085 0-2.001.7-2.346 1.667H2.5a.833.833 0 1 0 0 1.666h4.32A2.496 2.496 0 0 0 9.168 17.5c1.085 0 2-.7 2.345-1.667H17.5a.833.833 0 1 0 0-1.666Zm-1.667-3.334a.834.834 0 1 1 0-1.667.834.834 0 0 1 0 1.667Zm0-3.333c-1.085 0-2 .7-2.345 1.667H2.5a.833.833 0 1 0 0 1.666h10.988a2.496 2.496 0 0 0 2.345 1.667c1.379 0 2.5-1.122 2.5-2.5s-1.121-2.5-2.5-2.5Zm-10-3.333a.834.834 0 1 1 0 1.667.834.834 0 0 1 0-1.667ZM2.5 5.833h.987A2.496 2.496 0 0 0 5.833 7.5c1.085 0 2.001-.7 2.346-1.667H17.5a.833.833 0 1 0 0-1.666H8.18A2.496 2.496 0 0 0 5.832 2.5c-1.085 0-2 .7-2.346 1.667H2.5a.833.833 0 1 0 0 1.666Z"
      fill="#666"
    />
  </svg>
);
