import API from '@aws-amplify/api-rest';

import {
  ISimilarCompaniesSearch,
  ITriggerSimilarCompaniesSearch,
  ITriggerSimilarCompaniesSearchResponse,
} from '@/types';

export const getSimilarCompaniesSearchInfoById = (id: string): Promise<ISimilarCompaniesSearch> =>
  API.get('CDPAPI', `/similar_companies/${id}`, {});

export const triggerSimilarCompaniesSearch = (
  body: ITriggerSimilarCompaniesSearch,
): Promise<ITriggerSimilarCompaniesSearchResponse> => API.post('CDPAPI', '/similar_companies', { body });
