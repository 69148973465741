import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { CaseCodeForm } from './CaseCodeForm';
import { CaseCodeSubmitButton } from './CaseCodeSubmitButton';
import { IconArrowLeft } from '@/Components/Icons/IconArrowLeft';
import { CASE_CODE_FORM_STEPS } from '@/constants';

export const CaseCodeSecondStep = ({ isLoading, isEditMode, onSubmit, setStep, flowData, defaultValues }) => {
  const form = useForm({ defaultValues });

  return (
    <>
      <CaseCodeForm
        inputs={flowData?.[CASE_CODE_FORM_STEPS.SECOND]}
        form={form}
      />

      <div className="flex gap-4 justify-end">
        <Button
          className="text-[#2E3F4C] mt-5 px-3 h-9"
          disabled={isLoading}
          onClick={() => setStep(CASE_CODE_FORM_STEPS.FIRST)}
          startIcon={<IconArrowLeft />}
        >
          Back
        </Button>

        <CaseCodeSubmitButton
          isLoading={isLoading}
          isEditMode={isEditMode}
          onSubmit={form.handleSubmit(onSubmit)}
        />
      </div>
    </>
  );
};
