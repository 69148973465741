import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Button, Menu, Typography } from '@mui/material';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { HELIX_APPS } from '@/constants';
import { useMenu } from '@/hooks/use-menu';
import { IconBrowser } from '@/Components/Icons/IconBrowser';

export const HelixAppsDropdown = () => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const currentApp = HELIX_APPS.FIND.label;

  return (
    <>
      <Button
        data-testid="helix-apps-dropdown"
        aria-controls={isOpen ? 'helix-apps-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        classes={{ root: 'p-1' }}
      >
        <IconBrowser className="ml-1 mr-2" />

        <Typography className="text-[#2E3F4C] font-medium text-sm cursor-pointer">Find</Typography>

        <KeyboardArrowDownIcon
          className={classnames('fill-cgray-50 text-xl transition ml-1', { 'rotate-180': isOpen })}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        {Object.entries(HELIX_APPS).map(([key, val]) => {
          const isCurrentApp = val.label === currentApp;

          return (
            <MenuItem
              key={key}
              value={key}
              classes={{ root: classnames('p-0 h-5 ', { 'bg-[#0000000a]': isCurrentApp }) }}
              onClick={handleClose}
            >
              {isCurrentApp ? (
                <Link
                  to="/"
                  className="w-full h-full flex items-center	px-4	"
                >
                  {val.label}
                </Link>
              ) : (
                <a
                  className="w-full h-full flex items-center	px-4	"
                  href={val.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {val.label}
                </a>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
