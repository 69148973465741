import { useQuery } from '@tanstack/react-query';

import { useSnackbar } from 'notistack';
import { getSavedSearchById } from '@/services/api/saved-search';

export const useQuerySavedSearchById = (id: string) => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(['saved-search', id], () => getSavedSearchById(id), {
    enabled: !!id,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    onError: () =>
      enqueueSnackbar('Failed to fetch saved search. Please try again or contact support.', { variant: 'error' }),
  });
};
