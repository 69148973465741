import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { range } from 'lodash';

export const SkeletonLoader: React.FC = () => {
  return (
    <div className="grid gap-4 grid-cols-2 mt-4">
      {range(4).map((idx) => (
        <Skeleton
          key={idx}
          variant="rounded"
          className="bg-[#0000000f] rounded-2xl"
          height={300}
        />
      ))}
    </div>
  );
};
