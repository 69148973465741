import React, { FC } from 'react';

import { SkeletonLoader } from '../../common/SkeletonLoader';
import { CompanyProfileWidget } from '../../common/CompanyProfileWidget/CompanyProfileWidget';
import { SubsidiariesTable } from './SubsidiariesTable';
import { useOverviewTabData } from './use-overview-tab-data';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { formatCompanyDescription } from '@/Utils/text';
import { useQueryCompanySubsidiaries } from '@/hooks/queries/company-profile/use-query-company-subsidiaries';
import { DatumDatum } from '@/types';

interface OverviewTabProps {
  params?: {
    row: DatumDatum;
  };
  bainId: string;
  isCompanyProfileLoading: boolean;
}

export const OverviewTab: FC<OverviewTabProps> = ({ params, bainId, isCompanyProfileLoading }) => {
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const { data: subsidiaries, isLoading: isSubsidiariesLoading } = useQueryCompanySubsidiaries(bainId);
  const { topLevelParentCompany, revenueLabel } = useOverviewTabData({ row: params?.row, currency });

  if (isCompanyProfileLoading) {
    return <SkeletonLoader />;
  }

  if (!params) {
    return null;
  }

  return (
    <div className="columns-3	gap-4">
      <CompanyProfileWidget
        title={{ text: 'Key information', id: 'overview-header' }}
        description={{
          text: formatCompanyDescription(params.row.self_firmo_description___)?.formattedDescription,
          id: 'overview-description',
        }}
        entries={[
          {
            label: 'Top-Level Parent Company',
            value: topLevelParentCompany,
            id: 'overview-company',
          },
          {
            label: 'Year founded',
            value: params.row.self_firmo_yearFounded___,
            id: 'overview-founded',
          },
          {
            label: 'Employee Count',
            value: params.row.self_firmo_employeeCount___
              ?.toLocaleString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
            id: 'overview-count',
          },
          {
            label: 'Known Subsidiaries',
            value: subsidiaries?.length,
            expandableContent: subsidiaries?.length ? <SubsidiariesTable subsidiaries={subsidiaries} /> : undefined,
            isLoading: isSubsidiariesLoading,
          },
          {
            label: 'Website',
            value:
              params.row.self_firmo_webpage___ !== 'UNKNOWN' ? (
                <a
                  href={`${'https://' + params.row.self_firmo_webpage___}`}
                  id="website-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-[#0484E7]"
                >
                  {params.row.self_firmo_webpage___}
                </a>
              ) : (
                'Unknown'
              ),
            id: 'overview-website',
          },
        ]}
      />
      <CompanyProfileWidget
        title={{ text: 'Location', id: 'location-header' }}
        entries={[
          {
            label: 'City',
            value: params.row.self_location_city___,
            id: 'location-city',
          },
          {
            label: 'Country',
            value: params.row.self_location_country___,
            id: 'location-country',
          },
          {
            label: 'Region',
            value: params.row.self_location_region___,
            id: 'location-region',
          },
        ]}
      />
      <CompanyProfileWidget
        title={{ text: 'Industry', id: 'industry-header' }}
        entries={[
          {
            label: 'Sector',
            value: params.row.self_sector_sector___,
            id: 'industry-sector',
          },
          {
            label: 'Industry Group',
            value: params.row.self_sector_industryGroup___,
            id: 'industry-group',
          },
          {
            label: 'Industry',
            value: params.row.self_sector_industry___,
            id: 'industry',
          },
          {
            label: 'Sub Industry',
            value: params.row.self_sector_subIndustry___,
            id: 'industry-sub',
          },
        ]}
      />
      <CompanyProfileWidget
        title={{ text: 'Financials', id: 'financial-highlights-header' }}
        entries={[
          {
            label: 'Revenue',
            value: revenueLabel,
            id: 'financial-revenue',
          },
          {
            label: 'Revenue Profile',
            value: params.row.self_financials_revenueProfile___,
            id: 'financial-profile',
          },
          {
            label: 'Revenue CAGR 1 yr',
            value: params.row.self_financials_revenueCagr_1yr__
              ? `${params.row.self_financials_revenueCagr_1yr__.toFixed(0)}%`
              : undefined,
            id: 'financial-1',
          },
          {
            label: 'Revenue CAGR 3 yr',
            value: params.row.self_financials_revenueCagr_3yr__
              ? `${params.row.self_financials_revenueCagr_3yr__.toFixed(0)}%`
              : undefined,
            id: 'financial-3',
          },
          {
            label: 'EBITDA Margin',
            value: params.row.self_financials_ebitdaMargin___
              ? `${params.row.self_financials_ebitdaMargin___.toFixed(0)}%`
              : undefined,
            id: 'financial-ebitda',
          },
          {
            label: 'Net Income Margin',
            value: params.row.self_financials_netIncomeMargin___
              ? `${params.row.self_financials_netIncomeMargin___.toFixed(0)}%`
              : undefined,
            id: 'financial-net',
          },
        ]}
      />
    </div>
  );
};
