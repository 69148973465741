import { useState, useMemo, useEffect } from 'react';
import { invert, uniq } from 'lodash';
import { SelectChangeEvent } from '@mui/material/Select';
import { IMultyYear } from '@/types';

export const DATA_SOURCE_USER_FRIENDLY_NAME = {
  // Temporary hack, technically these values should be served from Backend
  capiq: 'CapitalIQ',
  bq: 'Bright Query',
} as const;

export type DataSource = keyof typeof DATA_SOURCE_USER_FRIENDLY_NAME;

const DATA_SOURCE_LABEL = invert(DATA_SOURCE_USER_FRIENDLY_NAME);

const getPreferredDataSource = (revenueMetadata?: string): DataSource | null => {
  // TODO: this is ad-hoc hack, this eventually needs to come from backend.
  // Unfortunately backend will have to execute same regular expressions.
  // Proper fix is to convert metadata to semi-structured format
  // (variant in Snowflake, JSONB in RDS, nested object in ElasticSearch)
  const metadata1 = revenueMetadata?.match(/(\S+) multi year financials prioritized/);

  if (metadata1) {
    return DATA_SOURCE_LABEL[metadata1[1]] as DataSource;
  }

  const metadata2 = revenueMetadata?.match(/Top quality source (\S+) used/);

  if (metadata2) {
    return DATA_SOURCE_LABEL[metadata2[1]] as DataSource;
  }

  return null;
};

export const useFinancialDataSource = (multiYear?: IMultyYear, revenueMetadata?: string) => {
  const [currentDataSource, setCurrentDataSource] = useState<DataSource | null>(null);

  const dataSourcesAvailable = useMemo(
    () =>
      multiYear
        ? ([
            ...uniq(
              Object.values(multiYear)
                .map((data) => data?.data_source)
                .map((dataSource) => Object.values(dataSource))
                .flat(),
            ),
          ] as DataSource[])
        : ([] as DataSource[]),

    [multiYear],
  );

  useEffect(() => {
    const preferredDataSource = getPreferredDataSource(revenueMetadata);

    if (preferredDataSource && dataSourcesAvailable.includes(preferredDataSource))
      setCurrentDataSource(preferredDataSource);

    setCurrentDataSource(dataSourcesAvailable ? dataSourcesAvailable[0] : null);
  }, [dataSourcesAvailable, multiYear, revenueMetadata]);

  const handleDataSourceChange = (event: SelectChangeEvent<DataSource | null>) => {
    setCurrentDataSource(event.target.value as DataSource);
  };

  return {
    currentDataSource,
    dataSourcesAvailable,
    handleDataSourceChange,
  };
};
