const OVERVIEW_DATASETS = ['company_search', 'visualization'] as const;

const FINANCIALS_DATASETS = ['visualization_time_series', 'master_time_series'] as const;

const MA_DATASETS = ['master_mna', 'master_mna_buyer'] as const;

export const CUSTOM_DATASETS = [...OVERVIEW_DATASETS, ...FINANCIALS_DATASETS, ...MA_DATASETS] as const;

type Dataset = typeof CUSTOM_DATASETS[number];

export const TABS_SLUGS = {
  OVERVIEW: 'overview',
  FINANCIAL_DETAILS: 'financial-details',
  M_AND_A: 'mergers-and-acquisitions-details',
} as const;

type TabsSlugsKeys = keyof typeof TABS_SLUGS;

export type TabSlug = typeof TABS_SLUGS[TabsSlugsKeys];

export type Tab = { slug: string; id: string; label: string; datasets?: Dataset[]; disabled?: boolean };

// The order of the array reflects the order of the tabs in the UI
export const TABS: Tab[] = [
  { slug: TABS_SLUGS.OVERVIEW, id: 'overview', label: 'Overview', datasets: [...OVERVIEW_DATASETS] },
  {
    slug: TABS_SLUGS.FINANCIAL_DETAILS,
    id: 'multi-year-financials',
    label: 'Financials',
    datasets: [...FINANCIALS_DATASETS],
  },
  { slug: TABS_SLUGS.M_AND_A, id: 'm-a', label: 'M & A', datasets: [...MA_DATASETS] },
];

export const DEFAULT_TAB = TABS[0];
