import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const CheckboxCell = ({ row }: GridRenderCellParams) => {
  const selectedCompanies = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);

  return (
    <Checkbox
      checked={selectedCompanies.includes(String(row.bain_id))}
      disableRipple
      disableFocusRipple
    />
  );
};
