import React from 'react';
import { useForm } from 'react-hook-form';
import { difference } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  CreateUpdateSharedElementDialog,
  CREATE_SHARED_ELEMENT_DEFAULT_VALUES,
} from '../Shared/CreateUpdateSharedElementDialog';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { useMutationCreateCompaniesList } from '@/hooks/queries/companies-list/use-mutation-create-companies-list';
import { useMutationValidateBainIds } from '@/hooks/queries/companies-list/use-mutation-validate-bain-ids';
import { MODALS } from '@/constants';
import { useModal } from '@/hooks/use-modal';

const MAX_BAIN_IDS_VALIDATE_BY_ELASTIC_QUERY = 500;

export const CreateCompaniesListDialog = () => {
  const { isOpen, handleClose, data: modalData } = useModal(MODALS.CREATE_COMPANIES_LIST);
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, reset, formState, setError } = useForm({
    defaultValues: CREATE_SHARED_ELEMENT_DEFAULT_VALUES,
    mode: 'onChange',
  });

  const createCompaniesListMutation = useMutationCreateCompaniesList(reset, modalData?.companyProfileSelectedCompanies);
  const validateBainIdsMutation = useMutationValidateBainIds();

  const createCompaniesList = (formData) => {
    createCompaniesListMutation.mutate(formData);
  };

  const validateBainIds = async (formData) => {
    const pinned_company_ids = formData?.pinned_company_ids;
    const chunkSize = MAX_BAIN_IDS_VALIDATE_BY_ELASTIC_QUERY;
    const chunkedPinnedCompanyIds = [];

    for (let i = 0; i < pinned_company_ids.length; i += chunkSize) {
      const chunk = pinned_company_ids.slice(i, i + chunkSize);

      chunkedPinnedCompanyIds.push(chunk);
    }

    const pinnedCompaniesWithMutation = chunkedPinnedCompanyIds.map((companyIds) =>
      validateBainIdsMutation.mutateAsync(companyIds),
    );

    try {
      const allPromisesData = await Promise.all(pinnedCompaniesWithMutation);

      let id_count = 0;
      const bain_id = [];

      allPromisesData.forEach((promiseData) => {
        id_count += promiseData.id_count;
        bain_id.push(...promiseData.bain_id);
      });

      if (id_count === pinned_company_ids?.length) {
        createCompaniesList(formData);

        return;
      }

      const notExistingBainIds = difference(pinned_company_ids, bain_id);

      if (notExistingBainIds.length === 0) {
        createCompaniesList(formData);

        return;
      }

      setError('pinned_company_ids', {
        type: 'focus',
        message: `Not existing BainIds: ${notExistingBainIds.join(', ')}`,
      });
    } catch (error) {
      enqueueSnackbar(
        <NotificationMessage title="Failed to validate Bain Ids. Please try again or contact support." />,
        { variant: 'error' },
      );
    }
  };

  const onSubmit = (formData) => {
    if (formData?.pinned_company_ids) {
      validateBainIds(formData);
    } else {
      createCompaniesList(formData);
    }
  };

  const isLoading = createCompaniesListMutation.isLoading || validateBainIdsMutation.isLoading;

  return (
    <CreateUpdateSharedElementDialog
      title="Create new list"
      subtitle="A static list of companies that you can edit and share."
      buttonLabel="Create"
      register={register}
      formState={formState}
      isOpen={isOpen}
      handleClose={handleClose}
      handleButtonClick={handleSubmit(onSubmit)}
      isLoading={isLoading}
      disabled={isLoading}
      predefinedCompanies={modalData?.predefinedCompanies}
    />
  );
};
