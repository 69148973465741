import React, { useState, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router';
import { useSessionStorage, useLocalStorage } from 'usehooks-ts';

import { CaseCodeFirstStep } from './CaseCodeFirstStep';
import { CaseCodeSecondStep } from './CaseCodeSecondStep';
import { useModal } from '@/hooks/use-modal';
import { useMutationLogCaseCode } from '@/hooks/queries/use-mutation-log-case-code';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { useQuerySavedSearches } from '@/hooks/queries/saved-search/use-query-saved-searches';
import { LOCAL_STORAGE_KEYS, HOME_PAGE_SLUG, MODALS, ROUTES, CASE_CODE_FLOWS, CASE_CODE_FORM_STEPS } from '@/constants';

const filterFieldsValues = (fields, formData) => {
  const allowedFields = fields?.map((field) => field.key);

  return formData
    ? Object.keys(formData)
        .filter((key) => allowedFields?.includes(key))
        .reduce((obj, key) => {
          obj[key] = formData?.[key];

          return obj;
        }, {})
    : {};
};

export const CaseCodeDialog = () => {
  const { push } = useHistory();
  const [flowType, setFlowType] = useState('');
  const [step, setStep] = useState(CASE_CODE_FORM_STEPS.FIRST);
  const queryCompaniesLists = useQueryCompaniesLists();
  const companiesLists = queryCompaniesLists?.data ?? [];
  const isCompaniesListsLoading = queryCompaniesLists?.isFetching;
  const [companiesListsSharedWithUser] = useLocalStorage(LOCAL_STORAGE_KEYS.COMPANIES_LISTS_SHARED_WITH_USER, []);
  const querySavedSearches = useQuerySavedSearches();
  const savedSearches = querySavedSearches?.data ?? [];
  const isSavedSearchesLoading = querySavedSearches?.isFetching;
  const [savedSearchesSharedWithUser] = useLocalStorage(LOCAL_STORAGE_KEYS.SEARCHES_SHARED_WITH_USER, []);
  const { isOpen, data: modalData, handleClose } = useModal(MODALS.CASE_CODE);
  const { mutate: logCaseCode, isLoading: isCaseCodeMutating } = useMutationLogCaseCode();
  const [uiSecrets] = useSessionStorage(LOCAL_STORAGE_KEYS.UI_SECRETS, undefined);

  const isFirstStep = step === CASE_CODE_FORM_STEPS.FIRST;
  const isSecondStep = step === CASE_CODE_FORM_STEPS.SECOND;

  const flowData = CASE_CODE_FLOWS[flowType];
  const stepFields = isFirstStep ? flowData?.[CASE_CODE_FORM_STEPS.FIRST] : flowData?.[CASE_CODE_FORM_STEPS.SECOND];

  const defaultValues = useMemo(
    () => filterFieldsValues(stepFields, modalData?.formData),
    [modalData?.formData, stepFields],
  );

  const isEditMode = modalData?.isEditMode;

  useEffect(() => {
    if (modalData?.flowType) setFlowType(modalData?.flowType);
    if (modalData?.step) setStep(modalData?.step);
  }, [modalData?.flowType, modalData?.step]);

  const redirect = () => {
    const userCompaniesLists = [...companiesLists, ...companiesListsSharedWithUser];
    const userSavedSearches = [...savedSearches, ...savedSearchesSharedWithUser];

    const isRedirectFromHomePage = modalData?.redirectUrl === '' || modalData?.redirectUrl === HOME_PAGE_SLUG;
    const hasCompaniesList = userCompaniesLists ? !!userCompaniesLists?.length : false;
    const hasSavedSearches = userSavedSearches ? !!userSavedSearches?.length : false;
    const redirectUrl =
      isRedirectFromHomePage && !hasCompaniesList && !hasSavedSearches ? ROUTES.CUSTOM_SEARCH : modalData?.redirectUrl;

    push(redirectUrl || HOME_PAGE_SLUG);
  };

  const onSubmit = (formData) => {
    logCaseCode(
      {
        formData: filterFieldsValues(stepFields, formData),
        redirect: isEditMode ? undefined : redirect,
        flowType: CASE_CODE_FLOWS[flowType]?.label,
        step,
      },
      {},
    );
  };

  const handleFlowChange = (event) => {
    setFlowType(event.target.value);
  };

  const handleCloseDialog = () => {
    handleClose();
  };

  const isLoading = !uiSecrets || isCaseCodeMutating || isCompaniesListsLoading || isSavedSearchesLoading;

  const dialogTitle = isFirstStep ? 'Welcome to Find!' : 'Don’t have a case code?';
  const dialogSubtitle = isFirstStep
    ? 'Discover millions of companies, create lists and export what you need!'
    : 'No problem, we’ll just need a few details for statistics only.';

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: 'bg-white p-5 mt-[140px] min-w-[500px] self-start' }}
      onClose={isEditMode ? handleCloseDialog : null}
    >
      {isEditMode ? (
        <CloseIcon
          fontSize="small"
          className="absolute right-3 top-4 cursor-pointer"
          onClick={handleCloseDialog}
        />
      ) : null}

      <DialogTitle classes={{ root: 'p-0 ' }}>
        <div className="text-[#484848] text-lg font-bold">{dialogTitle}</div>
        <div className="text-[#484848] text-sm font-normal pt-4">{dialogSubtitle}</div>
      </DialogTitle>

      {isFirstStep ? (
        <CaseCodeFirstStep
          flowData={flowData}
          isLoading={isLoading}
          isEditMode={isEditMode}
          onSubmit={onSubmit}
          setStep={setStep}
          handleFlowChange={handleFlowChange}
          defaultValues={defaultValues}
        />
      ) : null}

      {isSecondStep ? (
        <CaseCodeSecondStep
          flowData={flowData}
          isLoading={isLoading}
          isEditMode={isEditMode}
          onSubmit={onSubmit}
          setStep={setStep}
          defaultValues={defaultValues}
        />
      ) : null}
    </Dialog>
  );
};
