import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

export const useAllFetchedTableRows = () => {
  const queryClient = useQueryClient();
  const allMidtierData = queryClient.getQueriesData(['table-midtier']);

  const allFetchedTableRows = useMemo(
    () => allMidtierData?.flat(2).filter((item) => typeof item !== 'string'),
    [allMidtierData],
  );

  const getRowByBainId = useCallback(
    (bainId) =>
      allMidtierData?.flat(2).find((item) => {
        if (typeof item === 'string') return false;

        //@ts-ignore
        return String(item?.bain_id) === bainId;
      }),
    [allMidtierData],
  );

  return {
    allFetchedTableRows,
    getRowByBainId,
  };
};
