import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';

import { FIXED_ELASTIC_SEARCH_OUTPUT } from '@/constants';

export const TableAlert = ({ isMoreThanFixedOutput }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (isMoreThanFixedOutput) {
      setIsOpen(true);
    }
  }, [isMoreThanFixedOutput]);

  return (
    <Alert
      role="alert"
      severity="warning"
      style={{ backgroundColor: 'rgb(254, 247, 234)' }}
      className={classnames('absolute bottom-0 right-2 mb-2 px-3 py-0.5', {
        flex: isOpen,
        hidden: !isOpen,
      })}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {`We can only show you ${FIXED_ELASTIC_SEARCH_OUTPUT.toLocaleString(
        'en',
      )} free-text search results. Please narrow your search.`}
    </Alert>
  );
};

TableAlert.propTypes = {
  isMoreThanFixedOutput: PropTypes.bool.isRequired,
};
