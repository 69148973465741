import { rest } from 'msw';

export const MOCKED_SIMILAR_COMPANIES_NEW_JOB_ID = 'new-job-id';
export const MOCKED_SIMILAR_COMPANIES_NEW_JOB_PROGRESS = 0;
export const MOCKED_SIMILAR_COMPANIES_IN_PROGRESS_JOB_ID = 'in-progress-job-id';
export const MOCKED_SIMILAR_COMPANIES_IN_PROGRESS_JOB_PROGRESS = 50;
export const MOCKED_SIMILAR_COMPANIES_FAILED_JOB_ID = 'failed-job-id';
export const MOCKED_SIMILAR_COMPANIES_SUCCESS_JOB_ID = 'success-job-id';
export const MOCKED_SIMILAR_COMPANIES_RESPONSE_QUERY = 'google AND amazon';

export const similarCompaniesController = [
  rest.get(`${process.env.REACT_APP_API_URL}/similar_companies/:id`, (req, res, ctx) => {
    if (req.params.id === MOCKED_SIMILAR_COMPANIES_NEW_JOB_ID) {
      return res(
        ctx.json({
          job_id: MOCKED_SIMILAR_COMPANIES_NEW_JOB_ID,
          status: 'new',
          generated_query: null,
          progress: MOCKED_SIMILAR_COMPANIES_NEW_JOB_PROGRESS,
        }),
      );
    }

    if (req.params.id === MOCKED_SIMILAR_COMPANIES_IN_PROGRESS_JOB_ID) {
      return res(
        ctx.json({
          job_id: MOCKED_SIMILAR_COMPANIES_IN_PROGRESS_JOB_ID,
          status: 'in-progress',
          generated_query: null,
          progress: MOCKED_SIMILAR_COMPANIES_IN_PROGRESS_JOB_PROGRESS,
        }),
      );
    }

    if (req.params.id === MOCKED_SIMILAR_COMPANIES_FAILED_JOB_ID) {
      return res(
        ctx.json({
          job_id: MOCKED_SIMILAR_COMPANIES_FAILED_JOB_ID,
          status: 'failed',
          generated_query: null,
          progress: 0,
        }),
      );
    }

    return res(
      ctx.json({
        job_id: req.params.id,
        status: 'success',
        generated_query: MOCKED_SIMILAR_COMPANIES_RESPONSE_QUERY,
        progress: 100,
      }),
    );
  }),

  rest.post(`${process.env.REACT_APP_API_URL}/similar_companies`, (_, res, ctx) =>
    res(ctx.json({ job_id: MOCKED_SIMILAR_COMPANIES_NEW_JOB_ID })),
  ),
];
