import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';

import { TableGrid } from './index.ui';
import { getColumns } from './columns';
import { useQueryTable } from '@/hooks/queries/use-query-table';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getCountText, getRows } from '@/Utils/table';
import { actions as selectedCompaniesActions } from '@/slices/selected-companies';
import { actions as tableActions } from '@/slices/table';
import { useQueryColumnMapper } from '@/hooks/queries/column-mapper/use-query-column-mapper';
import { FILTERS_ID, FIXED_ELASTIC_SEARCH_OUTPUT } from '@/constants';
import { TableAlert } from '@/Components/CompanyResearch/TableAlert';
import { useTableLoading } from '@/hooks/table/use-table-loading';

const TABLE_OFFSET = 16;

interface IProps {
  isCompaniesList?: boolean;
}

export const ScreenerTable = ({ isCompaniesList = false }: IProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const observerRef = useRef<MutationObserver | null>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const bainIds = useShallowSelector((state) => state.search.bainIds.data);
  const totalBainIds = useShallowSelector((state) => state.search.bainIds.total ?? 0);
  const searchQuery = useShallowSelector((state) => state.search.searchQuery);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const defaultColumns = useShallowSelector((state) => state.table.defaultColumns);
  const query = useQueryTable(isCompaniesList);
  const isLoading = useTableLoading();
  const columnMapperQuery = useQueryColumnMapper();
  const tableRows = useMemo(() => query.tableMidtierQuery.data ?? [], [query.tableMidtierQuery.data]);
  const tableCount = useMemo(() => query.tableMidtierCountQuery.data ?? 0, [query.tableMidtierCountQuery.data]);
  const columnMapper = useMemo(() => columnMapperQuery.data ?? [], [columnMapperQuery.data]);
  const columns = useMemo(
    () =>
      getColumns({
        tableRows,
        columnMapper,
        currency,
        defaultColumns,
      }),
    [columnMapper, currency, defaultColumns, tableRows],
  );
  const rows = useMemo(() => getRows({ rows: tableRows, isLoading }), [tableRows, isLoading]);
  const isMoreThanFixedOutput = bainIds && totalBainIds > FIXED_ELASTIC_SEARCH_OUTPUT;
  const isTableCountLoading = query.tableMidtierCountQuery.isFetching;
  const countText = useMemo(
    () => getCountText({ isElasticSearch: !!searchQuery, bainIds, totalBainIds, tableCount }),
    [bainIds, searchQuery, tableCount, totalBainIds],
  );

  useEffect(() => {
    dispatch(tableActions.setTotalRows(tableCount));
  }, [dispatch, tableCount]);

  useEffect(() => {
    observerRef.current = new MutationObserver(() => {
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect();
        const height = rect.top + TABLE_OFFSET;

        parentRef.current.style.height = `calc(100vh - ${height}px)`;
      }
    });

    return () => {
      observerRef.current?.disconnect();
      document.body.classList.remove('overflow-hidden');
    };
  }, [dispatch]);

  useEffect(() => {
    const filtersNode = document.getElementById(FILTERS_ID);

    document.body.classList.add('overflow-hidden');

    if (filtersNode) {
      observerRef.current?.observe(filtersNode, {
        childList: true,
        characterData: true,
        attributes: true,
        subtree: true,
      });
    }
  }, [location.pathname]);

  const handlePageChange = useCallback(
    (currentPage: number) => {
      dispatch(tableActions.setPageNumber(currentPage));
      query.fetch();
    },
    [dispatch, query],
  );

  const handleRowClick = useCallback(
    ({ row }: GridRowParams, { target }: MuiEvent<React.MouseEvent>) => {
      // @ts-ignore
      if (target.type === 'checkbox' && !isLoading) {
        dispatch(selectedCompaniesActions.toggleSelectedCompany(String(row.bain_id)));
      }
    },
    [dispatch, isLoading],
  );

  const handleSortChange = useCallback(
    (sortModel) => {
      dispatch(tableActions.setSortModel(sortModel));
      query.fetch();
    },
    [dispatch, query],
  );

  return (
    <div
      id="screen-grid"
      ref={parentRef}
      className="relative"
    >
      <TableGrid
        isLoading={isLoading}
        isNumRowsLoading={isTableCountLoading}
        columns={columns}
        rows={rows}
        totalNumRows={tableCount}
        countText={countText}
        handlePageChange={handlePageChange}
        handleRowClick={handleRowClick}
        handleSortChange={handleSortChange}
      />

      {isMoreThanFixedOutput && <TableAlert isMoreThanFixedOutput={isMoreThanFixedOutput} />}
    </div>
  );
};
