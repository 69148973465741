import React from 'react';
import { GridColumns, GridRenderCellParams, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import _ from 'lodash';

import { CompanyCell } from './CompanyCell';
import { CommonCell } from './CommonCell';
import { RownumCell } from './RownumCell';
import { CheckboxHeader } from './CheckboxHeader';
import { CheckboxCell } from './CheckboxCell';
import { CompanyHeader } from './CompanyHeader';
import { CommonHeader } from './CommonHeader';
import { IColumnMapper, IMidtierTable } from '@/types';
import { prepareColumns } from '@/Utils/table';

type TData = {
  tableRows: IMidtierTable[];
  columnMapper: IColumnMapper[];
  currency: string;
  defaultColumns: string[];
};

export const getColumns = ({ tableRows, columnMapper, currency, defaultColumns }: TData) => {
  const groupedColumnMapper = _.keyBy(columnMapper, 'Backend Name');
  let columns = (prepareColumns(columnMapper, [])(tableRows)) as string[];

  // if column mapper is not yet fetched, fall back to defaultColumns
  if (columnMapper.length === 0) {
    columns = defaultColumns;
  }

  const COLUMNS = [
    {
      ...{ ...GRID_CHECKBOX_SELECTION_COL_DEF, headerName: 'Check' },
      renderHeader: () => <CheckboxHeader />,
      renderCell: (params) => <CheckboxCell {...params} />,
    },
    ...columns.map((column) => {
      const header = groupedColumnMapper[column]?.['Display Name'];
      const sortable = !!groupedColumnMapper[column]?.['SORTABLE_COLUMN'];

      if (column === 'rownum') {
        return {
          field: 'id',
          headerName: 'ID',
          width: 200,
          hide: true,
          renderCell: (params: GridRenderCellParams) => <RownumCell {...params} />,
          sortable,
        };
      } else if (column === 'self_firmo_name___') {
        return {
          field: column,
          headerName: header,
          width: 210,
          renderHeader: () => <CompanyHeader headerName={header} />,
          renderCell: (params: GridRenderCellParams) => <CompanyCell {...params} />,
          sortable,
        };
      } else if (column === 'self_financials_revenue___') {
        return {
          field: column,
          headerName: `${header} (${currency})`,
          width: 200,
          renderHeader: () => <CommonHeader headerName={`${header} (${currency})`} />,
          renderCell: (params: GridRenderCellParams) => (
            <CommonCell
              displayName={header}
              filterType={groupedColumnMapper?.[column]?.['NUMERIC_TYPE'] ?? null}
              {...params}
            />
          ),
          sortable,
        };
      } // next hide any columns not in original list
      else if (!defaultColumns.includes(column)) {
        return {
          field: column,
          headerName: header,
          width: 200,
          hide: true,
          renderHeader: () => <CommonHeader headerName={header} />,
          renderCell: (params: GridRenderCellParams) => (
            <CommonCell
              displayName={header}
              filterType={groupedColumnMapper?.[column]?.['NUMERIC_TYPE'] ?? null}
              {...params}
            />
          ),
          sortable,
        };
      } else {
        return {
          field: column,
          headerName: header,
          width: 200,
          renderHeader: () => <CommonHeader headerName={header} />,
          renderCell: (params: GridRenderCellParams) => (
            <CommonCell
              displayName={header}
              filterType={groupedColumnMapper?.[column]?.['NUMERIC_TYPE'] ?? null}
              {...params}
            />
          ),
          sortable,
        };
      }
    }),
  ] as GridColumns;

  return COLUMNS;
};
