import awsService from '@/awsService';
import { IS_DEV } from '@/constants';

export const addPendoScript = async (key: string, caseCode?: string) => {
  if (key && !IS_DEV) {
    const user = await awsService.getUser();
    const script = document.createElement('script');

    script.innerHTML = `
      (function(apiKey){
          (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
            v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
              o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
              y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
              z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
      })('${key}');`;

    document.body.appendChild(script);

    const userEmail = user.username.split('_')[1];

    pendo.initialize({
      visitor: {
        id: userEmail,
      },
      ...(caseCode
        ? {
            account: {
              id: caseCode,
            },
          }
        : {}),
    });
  }
};
