import { IconPageNotFound } from '@/Components/Icons/IconPageNotFound';
import { IconLinkIsNotValid } from '@/Components/Icons/IconLinkIsNotValid';
import { IconAccessDenied } from '@/Components/Icons/IconAccessDenied';
import { IconSharableElementNotFound } from '@/Components/Icons/IconSharableElementNotFound';

export const ROUTES = {
  ROOT: '/',
  COMPANIES_LIST: '/search-list',
  SAVED_SEARCH: '/saved-search',
  SEARCH_LISTS: '/search-lists',
  CUSTOM_SEARCH: '/customScreen',
  COMPANY_PROFILE: '/company-profile',
};

export const HOME_PAGE_SLUG = ROUTES.SEARCH_LISTS;

export const IS_DEV = process.env.NODE_ENV === 'development';

export const SHOULD_MOCK_API_IN_BROWSER = false;
export const MOCK_API_IN_BROWSER = SHOULD_MOCK_API_IN_BROWSER && IS_DEV;

export const HIDE_FILTERS_COUNT = JSON.parse(process.env.REACT_APP_HIDE_FILTERS_COUNT);

export const MODALS = {
  COMPANY_OVERVIEW: 'companyOverview',
  TOO_MANY_COMPANIES: 'tooManyCompanies',
  ADVANCED_SEARCH: 'advancedSearch',
  DELETE_QUERY: 'deleteQuery',
  COMPANY_PROFILE: 'companyProfile',
  SEARCH_COMPANIES_HISTORY: 'companySearchHistory',
  CASE_CODE: 'caseCode',
  EXPORT: 'export',
  CREATE_COMPANIES_LIST: 'createCompaniesList',
  UPDATE_COMPANIES_LIST: 'updateCompaniesList',
  CREATE_SAVED_SEARCH: 'createSavedSearch',
  UPDATE_SAVED_SEARCH: 'updateSavedSearch',
  SIMILAR_COMPANIES_LOADING_DIALOG: 'similarCompaniesLoadingDialog',
  SHARE_LIST_WITH_COLLABORATORS: 'shareListWithCollaborators',
  COMPANIES_LIST_HISTORY: 'companiesListHistory',
};

export const SPECIAL_SEARCH_WORDS = ['NOT', 'AND', 'OR'];
export const SPECIAL_SEARCH_CHARS = ['?', '*', '~', '(', ')'];
export const QUOTES = ["'", '"'];

export const CURRENCIES = {
  USD: { value: 'usd', label: '$' },
  EUR: { value: 'euro', label: '€' },
};

export const HELIX_APPS = {
  FIND: { label: 'Find', link: 'https://prod.helixfind.baintools.com/' },
  LEARN: { label: 'Learn', link: 'https://insights.helix.bain.io/?page=CompanyOverview_v2' },
  SIZE: { label: 'Size', link: 'https://insights.helix.bain.io/?page=MarketSizing' },
  VOICE: { label: 'Software Customer Voice', link: 'https://insights.helix.bain.io/?page=CustomerVoiceToolkit' },
  PATH: { label: 'Auto. Pathfinder', link: 'https://insights.helix.bain.io/?page=AutomationPathfinder' },
};

export const LOCAL_STORAGE_KEYS = {
  QUERIES_HISTORY: 'queriesHistory',
  REDIRECT_URL: 'redirectUrl',
  CASE_CODE: 'caseCode',
  UI_SECRETS: 'uiSecrets',
  COMPANIES_LISTS_SHARED_WITH_USER: 'sharedWithMeCompaniesLists',
  SEARCHES_SHARED_WITH_USER: 'sharedWithMeSearches',
};

export const HISTORY_KEYS = {
  CUSTOM_HISTORY: 'customHistory',
  SCREENERS_HISTORY: 'screenersHistory',
};

export const FILTER_TYPES = {
  NUMERICAL: 'numerical',
  TREE: 'tree',
  CATEGORICAL: 'categorical',
};

export const FILTER_TYPES_ARRAY = Object.values(FILTER_TYPES);

export const QUERIES_HISTORY_EXPIRATION = 90; // days
export const MAX_SEARCH_HISTORY_QUERIES = 1000;

export const DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_VISIBLE_COLUMNS = [
  'self_firmo_name___',
  'self_firmo_description___',
  'self_firmo_webpage___',
  'self_location_country___',
  'self_sector_industry___',
  'self_firmo_employeeCount___',
  'self_financials_ebitda___',
  'self_financials_netIncome___',
  'self_financials_revenue___',
  'self_financials_revenueCagr_1yr__',
];
export const EXTENDED_DEFAULT_COLUMNS = ['__check__', ...DEFAULT_VISIBLE_COLUMNS];

export const SECTIONS_ORDER = [
  'Default',
  'Location',
  'Industry',
  'Firmographics',
  'Financials',
  'M&A',
  'Fragmentation',
  'Executives',
  'Ownership',
  'Parent',
  'Subsidiaries',
  'Investment Themes',
  'Investment Theme - Sector M&A',
  'Investment Theme - Platform Consolidation',
  'Investment Theme - Potential Carve Out',
  'Investment Theme - Debt Maturity',
  'Investment Theme - Generational Change',
  'Investment Theme - Leadership Change',
  'Investment Theme - Growth Ambition',
  'Investment Theme - Internal M&A',
  'News',
  'Others',
];

export const DEFAULT_ERROR_MESSAGE = "Something's not quite right with your search.";
export const FIXED_ELASTIC_SEARCH_OUTPUT = 10000;
export const FILTERS_ID = 'company-filters';

export const NUMERIC_FILTER_TYPES = {
  YEARS: 'year',
  PERCENTAGE: 'percent',
  EUR: 'EUR',
  USD: 'USD',
  CURRENCY: 'currency',
  OTHER: 'count',
};

export const SEARCH_EVENTS = {
  CHANGE_FILTERS: 'change-filters',
  REMOVE_QUERY: 'remove-query',
  REMOVE_QUERY_SMART_SEARCH: 'remove-query-smart-search',
  HISTORY: 'history',
};

export const HEADER_HEIGHT = 48;

export const ERROR_PAGE_PROPS = {
  LINK_IS_NOT_VALID: {
    title: 'Link is not valid any more',
    description: 'The links are set to expire after a certain amount of time',
    Icon: IconLinkIsNotValid,
  },
  PAGE_NOT_FOUND: {
    title: 'Page not found',
    description: "We can't seem to find the page you were looking for.",
    Icon: IconPageNotFound,
  },
  ACCESS_DENIED: {
    title: 'Access denied',
    description: "You don't have permission to view this page",
    Icon: IconAccessDenied,
  },
  LIST_NOT_FOUND: {
    title: 'List not found',
    description: 'This list may have been deleted or removed',
    Icon: IconSharableElementNotFound,
  },
  SAVED_SEARCH_NOT_FOUND: {
    title: 'Search not found',
    description: 'This search may have been deleted or removed',
    Icon: IconSharableElementNotFound,
  },
  COMPANY_PROFILE_NOT_FOUND: {
    title: 'Company profile not found',
    description: 'The URL contains not valid BainId or the server is unavailable',
    Icon: IconSharableElementNotFound,
  },
};

//CASE CODE

export const CASE_CODE_PROJECT_TYPES = [
  { value: 'peg-due-diligence', label: 'PEG - Due Diligence' },
  { value: 'peg-vendor-due-diligence', label: 'PEG - Vendor Due Diligence' },
  { value: 'peg-fund-strategy', label: 'PEG - Fund Strategy' },
  { value: 'peg-sector-screen', label: 'PEG - Sector Screen' },
  { value: 'peg-retainer', label: 'PEG - Retainer' },
  { value: 'peg-post-acquisition', label: 'PEG - Post Acquisition' },
  { value: 'gp-corp-ma-sector-screen', label: 'GP/Corp M&A - Sector Screen' },
  { value: 'gp-corp-ma-due-diligence', label: 'GP/Corp M&A - Due Diligence' },
  { value: 'gp-corp-ma-other', label: 'GP/Corp M&A - Other' },
  { value: 'client-development-peg', label: 'Client Development - PEG' },
  { value: 'client-development-GP', label: 'Client Development - GP' },
  { value: 'ip', label: 'IP' },
  { value: 'other', label: 'Other Case Type' },
];

const CASE_CODE_INPUT_FIELDS = {
  CASE_CODE: { key: 'caseCode', label: 'Case code - this is just for tracking', dataTestid: 'case-code-input' },
  PARTNER_EMAIL: { key: 'partnerEmail', label: 'Partner email' },
  PROJECT_TYPE: { key: 'projectType', label: 'Project type', items: CASE_CODE_PROJECT_TYPES },
  PROJECT_NAME: { key: 'projectName', label: 'Project name' },
  OTHER: { key: 'otherInfo', label: 'Describe your use case' },
  OTHER_EMAIL: { key: 'otherEmail', label: 'Add your email', info: 'You will be contacted for product research' },
};

export const CASE_CODE_FORM_STEPS = {
  FIRST: 'first',
  SECOND: 'second',
};

export const CASE_CODE_FLOWS = {
  CASE_WORK: {
    value: 'CASE_WORK',
    label: 'Case work',
    [CASE_CODE_FORM_STEPS.FIRST]: [{ required: true, ...CASE_CODE_INPUT_FIELDS.CASE_CODE }],
    [CASE_CODE_FORM_STEPS.SECOND]: [
      { required: true, ...CASE_CODE_INPUT_FIELDS.PARTNER_EMAIL },
      { required: true, ...CASE_CODE_INPUT_FIELDS.PROJECT_TYPE },
      { required: true, ...CASE_CODE_INPUT_FIELDS.PROJECT_NAME },
    ],
  },
  IP_GENERATION: {
    value: 'IP_GENERATION',
    label: 'IP Generation',
    [CASE_CODE_FORM_STEPS.FIRST]: [
      { required: true, ...CASE_CODE_INPUT_FIELDS.CASE_CODE },
      { required: false, ...CASE_CODE_INPUT_FIELDS.PROJECT_NAME },
    ],
    [CASE_CODE_FORM_STEPS.SECOND]: [
      { required: true, ...CASE_CODE_INPUT_FIELDS.PARTNER_EMAIL },
      { required: true, ...CASE_CODE_INPUT_FIELDS.PROJECT_NAME },
    ],
  },
  CD_WORK: {
    value: 'CD_WORK',
    label: 'CD work',
    [CASE_CODE_FORM_STEPS.FIRST]: [
      { required: true, ...CASE_CODE_INPUT_FIELDS.CASE_CODE },
      { required: false, ...CASE_CODE_INPUT_FIELDS.PROJECT_NAME },
    ],
    [CASE_CODE_FORM_STEPS.SECOND]: [
      { required: true, ...CASE_CODE_INPUT_FIELDS.PARTNER_EMAIL },
      { required: true, ...CASE_CODE_INPUT_FIELDS.PROJECT_NAME },
    ],
  },
  OTHER: {
    value: 'OTHER',
    label: 'Other',
    [CASE_CODE_FORM_STEPS.FIRST]: [
      { required: true, ...CASE_CODE_INPUT_FIELDS.OTHER },
      {
        required: true,
        ...CASE_CODE_INPUT_FIELDS.OTHER_EMAIL,
      },
    ],
  },
};

export const CASE_CODE_FLOW_TYPES = [
  CASE_CODE_FLOWS.CASE_WORK,
  CASE_CODE_FLOWS.IP_GENERATION,
  CASE_CODE_FLOWS.CD_WORK,
  CASE_CODE_FLOWS.OTHER,
];
