import React, { useMemo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ExportTextNode } from './ExportTextNode';
import { getCurrentFiltersToDisplay, normalizeFilters } from '@/Utils/filters';
import { useQueryColumnMapper } from '@/hooks/queries/column-mapper/use-query-column-mapper';
import { ExportEnum } from '@/types/history';

const EXPORT_LABELS = {
  [ExportEnum.DATA]: 'Data export',
  [ExportEnum.OVERVIEW]: 'Overview export',
  [ExportEnum.COMPARISON]: 'Comparison export',
};

export const ExportRecord = ({ query, onSelect }) => {
  const [expanded, setExpanded] = useState(false);
  const columnMapperQuery = useQueryColumnMapper();
  const filtersToDisplay = useMemo(() => {
    const columnMapper = columnMapperQuery.data ?? [];
    const normalizedFilters = { ...query.filters, otherFilters: normalizeFilters(query.filters.otherFilters) };

    return getCurrentFiltersToDisplay(normalizeFilters(normalizedFilters), columnMapper);
  }, [columnMapperQuery.data, query.filters]);

  const handleExpandClick = () => {
    setExpanded((prevState) => !prevState);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    onSelect?.();
  };

  return (
    <Accordion
      className="shadow-[0_4px_4px_rgba(0, 0, 0, 0.25)]"
      expanded={expanded}
      onChange={handleExpandClick}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className="flex grow">
          <Chip
            className="rounded"
            color="success"
            variant="outlined"
            label={EXPORT_LABELS[query.exportType]}
          />
          <div className="ml-2 flex items-center">
            <ExportTextNode query={query} />
          </div>
        </Box>
        <Link
          className="mr-4 w-22"
          component="button"
          onClick={handleSearch}
        >
          Apply criteria
        </Link>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="mb-4">{query.isLucene ? query.searchText : `"${query.searchText}"`}</Typography>

        {filtersToDisplay?.map(({ label, value }) => (
          <Typography key={label}>{`${label}: ${value}`}</Typography>
        ))}

        {query?.selectedCompanies ? (
          <Typography
            className="mb-4 max-h-48 overflow-y-auto"
            style={{ whiteSpace: 'break-spaces' }}
          >
            BainIDs: {query.selectedCompanies}
          </Typography>
        ) : null}
        <Typography></Typography>
      </AccordionDetails>
    </Accordion>
  );
};
