import { useMemo, useState, useCallback } from 'react';
import { isEmpty, isEqual, sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  GridColumnOrderChangeParams,
  GridColumns,
  GridColumnResizeParams,
  GridDensity,
  GridState,
  GridFilterModel,
  GridFilterItem,
} from '@mui/x-data-grid-pro';
import { useShallowSelector } from '../use-shallow-selector';
import { arrayMove } from '@/Utils/array-utils';
import { actions } from '@/slices/table';
import { DEFAULT_PAGE_SIZE } from '@/constants';

export const DEFAULT_DENSITY = 'standard';

interface UseCustomColumnsTransformationProps {
  columns: GridColumns;
}

export const useCustomColumnsTransformation = ({ columns }: UseCustomColumnsTransformationProps) => {
  const dispatch = useDispatch();
  const sortModel = useShallowSelector((state) => state.table.sortModel);
  const pageSize = useShallowSelector((state) => state.table.pageSize);
  const pageNumber = useShallowSelector((state) => state.table.pageNumber);
  const isConfidenceLevelChecked = useShallowSelector((state) => state.table.isConfidenceLevelChecked);
  const defaultColumns = useShallowSelector((state) => state.table.defaultColumns);
  const columnsVisibility = useShallowSelector((state) => state.table.visible);
  const customColumnsOrder = useShallowSelector((state) => state.table.customColumnsOrder);
  const [customColumnsWidth, setCustomColumnsWidth] = useState<Record<string, number>>({});
  const [customDensity, setCustomDensity] = useState<GridDensity>(DEFAULT_DENSITY);
  const [customTableFilters, setCustomTableFilters] = useState<GridFilterItem[]>([]);
  const [muiTableKey, setMuiTableKey] = useState(0);

  const transformedColumns = useMemo(() => {
    let orderedColumns = columns;

    for (const columnFieldName in customColumnsOrder) {
      const oldIndex = orderedColumns.findIndex((column) => column.field === columnFieldName);

      orderedColumns = arrayMove(orderedColumns, oldIndex, customColumnsOrder[columnFieldName]);
    }

    const visibleColumns = orderedColumns.map((column) => ({
      ...column,
      hide: !columnsVisibility.includes(column.field),
    }));

    return isEmpty(customColumnsWidth)
      ? visibleColumns
      : visibleColumns.map((column) => {
          if (column.field in customColumnsWidth) {
            return {
              ...column,
              width: customColumnsWidth[column.field],
            };
          }

          return column;
        });
  }, [columns, customColumnsOrder, columnsVisibility, customColumnsWidth]);

  const handleColumnOrderChange = useCallback(
    (order: GridColumnOrderChangeParams) => {
      const { field, targetIndex } = order;

      dispatch(
        actions.setCustomColumnsOrder({
          ...customColumnsOrder,
          [field]: targetIndex,
        }),
      );
    },
    [customColumnsOrder, dispatch],
  );

  const handleColumnVisibilityChange = useCallback(
    (field: string, isVisible: boolean) => {
      if (columnsVisibility.includes(field) && !isVisible) {
        dispatch(actions.setVisibleColumns(columnsVisibility.filter((columnField) => columnField !== field)));
      }

      if (!columnsVisibility.includes(field) && isVisible) {
        dispatch(actions.setVisibleColumns([...columnsVisibility, field]));
      }

      return columnsVisibility;
    },
    [columnsVisibility, dispatch],
  );

  const handleTableStateChange = useCallback((params: GridState) => {
    const density = params.density.value;

    setCustomDensity((prevDensity) => {
      if (prevDensity !== density) {
        return density;
      }

      return prevDensity;
    });
  }, []);

  const handleFilterModelChange = useCallback((params: GridFilterModel) => {
    const { items } = params;

    setCustomTableFilters(items);
  }, []);

  const handleColumnWidthChange = useCallback((params: GridColumnResizeParams) => {
    const { colDef, width } = params;

    setCustomColumnsWidth((prevState) => {
      return {
        ...prevState,
        [colDef.field]: width,
      };
    });
  }, []);

  const handleResetCustomSettings = useCallback(() => {
    dispatch(actions.setCustomColumnsOrder({}));
    setCustomColumnsWidth({});
    setCustomDensity(DEFAULT_DENSITY);
    setCustomTableFilters([]);
    setMuiTableKey((prevKey) => prevKey + 1);

    dispatch(actions.resetTable());
  }, [dispatch]);

  const isTableModified =
    ![customColumnsOrder, customColumnsWidth, sortModel].every(isEmpty) ||
    !isEqual(sortBy(['__check__'].concat(defaultColumns)), sortBy(columnsVisibility)) ||
    customDensity !== DEFAULT_DENSITY ||
    isConfidenceLevelChecked ||
    !!customTableFilters.length ||
    pageSize !== DEFAULT_PAGE_SIZE ||
    pageNumber !== 0;

  return {
    transformedColumns,
    customDensity,
    isTableModified,
    muiTableKey,
    customTableFilters,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnWidthChange: handleColumnWidthChange,
    onResetColumnSettings: handleResetCustomSettings,
    onFilterModelChange: handleFilterModelChange,
    onStateChange: handleTableStateChange,
  };
};
