/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import { Auth } from '@aws-amplify/auth';
import Amplify, { Hub } from '@aws-amplify/core';

class awsConfig {
  constructor(isAuthenticated = false) {
    this.isAuthenticated = false;
    this.user = undefined;

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('User Sign In Attempt');
          break;
        case 'cognitoHostedUI':
          this.getUser().then((userData) => this.setUser(userData));
          break;
        case 'signOut':
          this.setUser(null);
          break;
        case 'signIn_failure':
          console.log('User SignIn Failure');
          break;
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          break;
      }
    });

    this.getUser().then((userData) => this.setUser(userData));
  }

  async userLoggedIn() {
    return await Auth.currentAuthenticatedUser()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  // redirect here
  getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => this.setAuthenticationStatus(false));
  }

  initializeAws = () => {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: 'eu-west-1',
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_DOMAIN,
          scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
          redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
          redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
          responseType: 'code',
        },
      },
      API: {
        endpoints: [
          {
            name: 'CDPAPI',
            endpoint: process.env.REACT_APP_API_URL, //"https://qvqjdkttdl.execute-api.eu-west-1.amazonaws.com/master-dev",
            region: 'eu-west-1',
          },
        ],
      },
    });
  };

  authenticate = async () => {
    let user = this.user;

    try {
      let provider = {
        provider: process.env.REACT_APP_FEDERATED_IDP,
      };

      if (!user) {
        user = await Auth.federatedSignIn(provider);
      }

      this.setUser(user);

      return user;
    } catch (error) {
      console.log('error signing in', error);
      this.setAuthenticationStatus(false);

      return user;
    }
  };

  setUser = (user) => {
    this.user = user;
  };

  getAuthenticationStatus = () => {
    return this.isAuthenticated;
  };

  setAuthenticationStatus = (status) => {
    this.isAuthenticated = status;
  };

  signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      this.setAuthenticationStatus(false);
      this.setUser(undefined);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };
}
// Need to remove true when eveything changed for routing
export default new awsConfig(true);
