import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export const CaseCodeSubmitButton = ({ isLoading, isEditMode, onSubmit }) => {
  const label = isEditMode ? 'Change' : 'Log in';

  return (
    <Button
      variant="contained"
      className="bg-cred-50 self-end mt-5 h-9 min-w-[90px]"
      data-testid="login-button"
      disabled={isLoading}
      onClick={onSubmit}
    >
      {isLoading ? (
        <CircularProgress
          className="text-white"
          size={20}
        />
      ) : (
        label
      )}
    </Button>
  );
};
