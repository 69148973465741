import React from 'react';

import { ExportEnum, HistoryQuery } from '@/types/history';

import './index.scss';

interface IProps {
  query: HistoryQuery;
}

export const ExportTextNode = ({ query }: IProps) => {
  if (query.exportType === ExportEnum.DATA) {
    return <span>Companies - {query.companiesCount ?? 0}</span>;
  }

  if (query.exportType === ExportEnum.OVERVIEW) {
    return <span>{query?.companyProfiles?.[0]}</span>;
  }

  if (query.exportType === ExportEnum.COMPARISON) {
    const isTargetComparisonCompany = !!query.targetComparisonCompany;
    const comparisonCompanies = isTargetComparisonCompany
      ? query.companyProfiles?.filter((companyName) => companyName !== query.targetComparisonCompany)
      : query.companyProfiles;

    return (
      <div>
        {isTargetComparisonCompany ? <span className="export-companies">{query.targetComparisonCompany}</span> : null}
        {comparisonCompanies?.map((item, index, array) => {
          const isLastItem = index === array.length - 1;

          return <span key={item}>{isLastItem ? item : `${item}, `}</span>;
        })}
      </div>
    );
  }

  return null;
};
