import React, { FC } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import classnames from 'classnames';
import ExportComponent from '@/Components/ExportComponent';
import { ScreenerTable } from '@/Components/Shared/ScreenerTable';
import Search from '@/Components/CompanyResearch/Search/Search';

import { ErrorPage } from '@/Components/Shared/ErrorPage';
import { FILTERS_ID } from '@/constants';

const StyledGrid = styled(Grid)(() => ({
  '&.MuiGrid-root': {
    width: '100% !important',
    padding: '0px',
  },
  '&.MuiGrid-grid-xs-8': {
    flexGrow: '0',
    maxWidth: '82%',
    flexBasis: '82%',
  },
  '&.MuiGrid-grid-xs-4': {
    flexGrow: '0',
    maxWidth: '18%',
    flexBasis: '18%',
  },
}));

interface IProps {
  isError?: boolean;
  errorPage?: {
    title: string;
    description: string;
    Icon: () => JSX.Element;
  };
  bainIds: string[];
  hideSearch?: boolean;
  isCompaniesList?: boolean;
  controls: JSX.Element;
}

const SearchPage: FC<IProps> = ({ isError = false, errorPage, bainIds, controls, hideSearch, isCompaniesList }) => {
  return isError && errorPage ? (
    <ErrorPage {...errorPage} />
  ) : (
    <>
      <Box
        mx={3}
        my={2}
      >
        <Box className="flex justify-between">
          {controls}
          <Box
            color="#666666"
            flex="none"
          >
            <ExportComponent
              bainIds={bainIds}
              isCompaniesList={isCompaniesList}
            />
          </Box>
        </Box>
        <Box
          id={FILTERS_ID}
          sx={{ display: 'flex', label: 'Title here' }}
          mt={hideSearch ? 0 : 2}
        >
          <StyledGrid
            container
            direction="row"
            spacing={1}
            className="grid-container"
          >
            <StyledGrid
              item
              xs={8}
              zeroMinWidth
            >
              <div
                className={classnames('flex flex-wrap flex-col mt-2', {
                  'invisible h-0': hideSearch,
                })}
              >
                <div className="flex items-start">
                  <Search isCompaniesList={isCompaniesList} />
                </div>
              </div>
            </StyledGrid>
          </StyledGrid>
        </Box>

        <div className="mt-4">
          <ScreenerTable isCompaniesList={isCompaniesList} />
        </div>
      </Box>
    </>
  );
};

export default SearchPage;
