import API from '@aws-amplify/api-rest';
import { prepareCaseCodeData } from '@/Utils/case-code-utils';

export const downloadCompanyOverview = async (target_id, competitor_ids, currency, email, caseCodeFormData) => {
  const body = {
    target_id,
    competitor_ids,
    currency,
    email,
    case_data: prepareCaseCodeData(caseCodeFormData),
  };

  return API.post('CDPAPI', '/company_overview_slides', {
    body,
  });
};
