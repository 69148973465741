import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions as selectedCompaniesActions } from '@/slices/selected-companies';
import { FIXED_ELASTIC_SEARCH_OUTPUT } from '@/constants';

export const CheckboxHeader = () => {
  const dispatch = useDispatch();
  const selectedCompanies = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);
  const { total, data: bainIds } = useShallowSelector((state) => state.search.bainIds);

  const isAllowedToCheck = total !== null || bainIds.length > 0;

  const handleClick = () => {
    if (!isAllowedToCheck) return;

    const totalData = total ?? bainIds.length;

    if (selectedCompanies.length === totalData || selectedCompanies.length === FIXED_ELASTIC_SEARCH_OUTPUT) {
      dispatch(selectedCompaniesActions.setSelectedCompanies([]));

      return;
    }

    dispatch(selectedCompaniesActions.setSelectedCompanies(bainIds));
  };

  const isHalfChecked = useMemo(() => {
    const totalData = total ?? bainIds.length;

    if (selectedCompanies.length > 0 && selectedCompanies.length < totalData) {
      return true;
    }
  }, [bainIds.length, selectedCompanies.length, total]);

  const isChecked = useMemo(() => {
    const totalData = total ?? bainIds.length;

    return selectedCompanies.length > 0 && selectedCompanies.length === totalData;
  }, [bainIds.length, selectedCompanies.length, total]);

  return (
    <Tooltip title={isAllowedToCheck ? null : 'Please search to enable select all'}>
      <Checkbox
        checked={isChecked}
        indeterminate={isHalfChecked}
        disableRipple
        disableFocusRipple
        classes={{
          indeterminate: 'text-[#1976d2]',
        }}
        onClick={handleClick}
      />
    </Tooltip>
  );
};
