import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchBainIds } from './thunks/fetch-bain-ids';
import { Nullish } from '@/types';
import { parseQueryToAstTree, removeNodeFromAstTreeAndFix } from '@/Utils/pegjs/astTreeUtils';
import { AstTree, Position } from '@/types/ast-tree-models';
import { HISTORY_KEYS } from '@/constants';

export enum QueryType {
  KEYWORD = 'keyword',
  MATCH = 'match',
  SMART = 'smart',
}

export const initialState = {
  bainIds: {
    isLoading: false,
    isError: false,
    errorStatus: null as Nullish<number>,
    errorMessage: null as Nullish<string>,
    data: [] as string[],
    total: null as Nullish<number>,
  },
  searchText: '',
  advancedSearchText: '',
  searchQueryTree: {} as Nullish<AstTree>,
  searchQuery: '',
  hasLuceneGrammarError: false,
  queryType: QueryType.KEYWORD,
  historyKey: HISTORY_KEYS.CUSTOM_HISTORY,
  isUniqueCompanySearch: false,
  willBeProceed: false,
};

type TError = { value: boolean; status: Nullish<number>; message: Nullish<string> };

export const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.bainIds.isLoading = payload;
    },
    setIsError: (state, { payload }: PayloadAction<TError>) => {
      state.bainIds.isError = payload.value;
      state.bainIds.errorStatus = payload.status;
      state.bainIds.errorMessage = payload.message;

      if (payload.message) {
        state.bainIds.isLoading = false;
      }
    },
    setBainIds: (state, { payload }: PayloadAction<string[]>) => {
      state.bainIds.data = payload;
    },
    setTotalBainIds: (state, { payload }: PayloadAction<number>) => {
      state.bainIds.total = payload;
    },
    setSearchText: (state, { payload }: PayloadAction<string>) => {
      state.searchText = payload;
    },
    setAdvancedSearchText: (state, { payload }: PayloadAction<string>) => {
      state.advancedSearchText = payload;
    },
    setQueryType: (state, { payload }: PayloadAction<QueryType>) => {
      state.queryType = payload;
    },
    setSearchQuery: (state, { payload }: PayloadAction<string>) => {
      state.searchQuery = payload; // payload is already normalized query
      state.searchQueryTree = [QueryType.KEYWORD, QueryType.SMART].includes(state.queryType)
        ? parseQueryToAstTree(state.searchQuery)
        : null;
    },
    removeSearchQuery: (state, { payload }: PayloadAction<Position>) => {
      const { query, tree } = removeNodeFromAstTreeAndFix(state.searchQueryTree as AstTree, payload);

      state.searchQuery = query;
      state.searchQueryTree = tree;

      if (!query) {
        state.bainIds.data = [];
        state.bainIds.total = null;
      }
    },
    setHasLuceneGrammarError: (state, { payload }: PayloadAction<boolean>) => {
      state.hasLuceneGrammarError = payload;
      state.bainIds.isLoading = false;
    },
    setHistoryKey: (state, { payload }: PayloadAction<string>) => {
      state.historyKey = payload;
    },
    resetSearch: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...initialState,
      historyKey: payload ?? state.historyKey,
    }),
    setIsUniqueCompanySearch: (state, { payload }: PayloadAction<boolean>) => {
      state.isUniqueCompanySearch = payload;
    },
    setWillBeProceed: (state, { payload }: PayloadAction<boolean>) => {
      state.willBeProceed = payload;
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
  fetchBainIds,
};

export type TState = typeof initialState;
