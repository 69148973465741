import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { without, isEmpty } from 'lodash';

import { HIDE_FILTERS_COUNT } from '../../../../../../constants';
import { useShallowSelector } from '../../../../../../hooks/use-shallow-selector';
import { actions } from '../../../../../../slices/filters';
import { getFilterValueLabel } from '@/Utils/filters';

const prepareFilterValue = ({ checked, filterData, value }) =>
  checked ? [...filterData, value] : without(filterData, value);

export const FilterCheckboxList = ({ itemId, values }) => {
  const dispatch = useDispatch();
  const filterData = useShallowSelector((state) => state.filters.otherFilters[itemId] ?? []);

  const handleChange = (value) => (event, checked) => {
    dispatch(
      actions.setFilter({
        id: itemId,
        data: prepareFilterValue({
          checked,
          filterData,
          value: value,
        }),
      }),
    );
  };

  useEffect(() => {
    if (isEmpty(filterData)) {
      dispatch(actions.removeFilter(itemId));
    }
  }, [dispatch, filterData, itemId]);

  return (
    <div>
      {values.map(({ value, active, total }) => {
        const filterValue = value;
        const isChecked = filterData.includes(filterValue);

        return (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                color="info"
                sx={{
                  color: '#DDD',
                }}
                checked={isChecked}
                disableRipple
                disableFocusRipple
              />
            }
            onChange={handleChange(value)}
            label={
              HIDE_FILTERS_COUNT
                ? getFilterValueLabel(value)
                : `${getFilterValueLabel(value)} (${total.toLocaleString('en')})`
            }
            classes={{
              root: 'flex',
              label: 'text-[#484848]',
            }}
            value={active}
          />
        );
      })}
    </div>
  );
};

FilterCheckboxList.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, null]).isRequired,
      active: PropTypes.oneOf([0, 1]).isRequired,
      total: PropTypes.number.isRequired,
      _backend: PropTypes.string.isRequired,
    }),
  ),
  itemId: PropTypes.string.isRequired,
};
